import { connect } from "react-redux";
import { actionCreators as searchActionCreators } from "../../store/Search";
import { actionCreators as modalActionCreators } from "../../store/Modal";
import PrivacyContainer from "./PrivacyContainer";

const mapStateToProps = () => ({});

const mapActionCreators = {
  ...searchActionCreators,
  ...modalActionCreators,
};

const withRedux = connect(
  mapStateToProps,
  mapActionCreators,
);

export default withRedux(PrivacyContainer);
