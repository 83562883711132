import React from "react";
import { Helmet } from "react-helmet";
import cn from "classnames";
import { PriceList } from "../../components";
import s from "./PriceListContainer.module.sass";
import PriceListModal from "../../components/PriceList/PriceListModal";

class PriceListContainer extends React.Component {
  state = {
    visible: false,
    shouldRender: true,
  };

  componentDidMount() {
    this.props.displaySearchList(false);

    if (this.props.authenticated && this.props.fullAccess) {
      this.setState({ shouldRender: false });

      if (!this.props.isHome) {
        this.props.history.push("/");
      }
    }
  }

  showModal = () => {
    this.setState({
      visible: true,
    });
  };

  handleClose = () => {
    this.setState({
      visible: false,
    });
  };

  render() {
    return this.state.shouldRender ? (
      <div className={cn(s.wrapper, { [s.homepage]: this.props.isHome })} id="pricelist-container">
        <PriceList showModal={this.showModal} signupUser={this.props.signupUser} authenticated={this.props.authenticated} />
        <PriceListModal showModal={this.showModal} handleClose={this.handleClose} visible={this.state.visible} />
        {!this.props.isHome && (
          <Helmet>
            <title>SökaFöretag - Priser</title>
          </Helmet>
        )}
      </div>
    ) : (<></>);
  }
}

export default PriceListContainer;
