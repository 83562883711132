import React from "react";
import { Link } from "react-router-dom";
import Helmet from "react-helmet";
import { Footer } from "../../components";
import s from "./NotFound.module.sass";

const NotFound = ({ openModal }) => (
  <div className={s.wrapper}>
    <div className={s.content}>
      <h1>Sidan kunde inte hittas</h1>
      <h4>
          Gå tillbaka till
        {" "}
        <Link to="/" className={s.link}>startsidan</Link>
      </h4>
    </div>
    <Footer openModal={openModal} />
    <Helmet>
      <title>SökaFöretag - Sidan kunde inte hittas</title>
    </Helmet>
  </div>
);

export default NotFound;
