import React, { PureComponent } from "react";
import {
  BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ReferenceLine, ResponsiveContainer,
} from "recharts";

export default class HorizontalBarChart extends PureComponent {
  state = {
    opacity: {
      average: 0.7,
      branch: 0.7,
    },
    margin: {
      top: 20,
      right: 20,
      left: 20,
      bottom: 20,
    },
    ticks: [...Array(25).keys()].map((k) => -12 + k),
  };

  formatTicks = (tickItem) => (tickItem > 0 ? `+${tickItem}` : tickItem);

  formatData = (data) => data.map((dataPoint) => Object.entries(dataPoint).reduce((acc, [key, value]) => {
    if (value < 2 && value > -2) {
      acc[key] = Math.round(value * 10) / 10;
    } else {
      acc[key] = Math.round(value);
    }
    return acc;
  }, {}), []);

  formatTooltipLabel = () => {
    const { data } = this.props;
    return data && data[0] && data[0].date;
  }

  handleMouseEnter = (o) => {
    const { dataKey } = o;
    const { opacity } = this.state;

    this.setState({
      opacity: { ...opacity, [dataKey]: 1 },
    });
  };

  handleMouseLeave = (o) => {
    const { dataKey } = o;
    const { opacity } = this.state;

    this.setState({
      opacity: { ...opacity, [dataKey]: 0.7 },
    });
  };

  render() {
    const {
      data, name, printMode, s,
    } = this.props;
    const { margin, opacity, ticks } = this.state;

    return (
      <ResponsiveContainer className={s["chart__container"]} width="99%">
        <BarChart layout="vertical" data={this.formatData(data)} margin={margin}>
          <CartesianGrid strokeDasharray="3 3" vertical={false} />
          <XAxis type="number" tickCount={25} ticks={ticks} tickMargin={20} padding={{ left: 40, right: 40 }} height={60} tickFormatter={this.formatTicks} stroke="grey" allowDecimals={false} />
          <YAxis type="category" dataKey="date" padding={{ top: 0, bottom: 0 }} hide />
          <Tooltip cursor={{ fill: "transparent" }} labelFormatter={this.formatTooltipLabel} isAnimationActive={false} />
          <Legend iconSize={30} iconType="square" onMouseEnter={this.handleMouseEnter} onMouseLeave={this.handleMouseLeave} />
          <ReferenceLine x={0} stroke="grey" />
          <Bar dataKey="average" fill="#23a843" name={name} barSize={40} fillOpacity={opacity.average} isAnimationActive={!printMode} />
          <Bar dataKey="branch" fill="#095587" name="Branschsnitt" barSize={40} fillOpacity={opacity.branch} isAnimationActive={!printMode} />
        </BarChart>
      </ResponsiveContainer>
    );
  }
}
