const validate = ({ companyInfo = {} }) => {
  const errors = { companyInfo: {} };
  const notRequiredFields = ["phone", "invoiceCOAddress", "invoiceEmail", "invoiceAttention"];

  Object.entries(companyInfo)
    .filter(([key]) => !notRequiredFields.includes(key))
    .map(([key, value]) => {
      if (!value) errors.companyInfo[key] = "Detta fält måste fyllas i";
    });

  return errors;
};

const warn = (values) => {
  const warnings = {};

  return warnings;
};

export { validate, warn };
