import React from "react";
import CompanyInfo from "./CompanyInfo";
import submitForm from "./submitForm";
import { Button } from "../../../shared";
import s from "../CompanyDataModal.module.sass";

const Form = ({ handleSubmit, ...rest }) => (
  <form onSubmit={handleSubmit(submitForm)} className={s.formContainer}>
    <CompanyInfo {...rest} />
    <Button type="submit" primary>Spara</Button>
  </form>
);

export default Form;
