import { connect } from "react-redux";
import { actionCreators as authActionCreators } from "../../store/Auth";
import { actionCreators as searchActionCreators } from "../../store/Search";
import PriceListContainer from "./PriceListContainer";

const mapStateToProps = (state) => ({
  authenticated: state.auth.authenticated,
  fullAccess: state.user.fullAccess,
});

const mapActionCreators = {
  ...authActionCreators,
  ...searchActionCreators,
};

const withRedux = connect(
  mapStateToProps,
  mapActionCreators,
);

export default withRedux(PriceListContainer);
