import React from "react";
import LoadingOverlay from "react-loading-overlay";
import { MdClose } from "react-icons/md";
import { Button } from "../../shared";
import s from "./DeleteListModal.module.sass";

const DeleteListModal = ({
  listId, isLoading, closeModal, deleteList,
}) => {
  const onDeleteList = () => {
    deleteList(listId);
  };

  const Header = () => (
    <div className={s.header}>
      <span className={s["header__title"]}>Radera?</span>
      <MdClose onClick={closeModal} className={s["header__icon"]} />
    </div>
  );

  const WarningText = () => (
    <div className={s.header}>
      <p>Vill du verkligen radera den här listan?</p>
    </div>
  );

  const DeleteButtons = () => (
    <div className={s["header__button--wrapper"]}>
      <Button additionalClass={s["header__button"]} onClick={closeModal} primary shadow>
        Avbryt
      </Button>
      <Button additionalClass={s["header__button"]} onClick={onDeleteList} danger shadow>
        Radera
      </Button>
    </div>
  );

  return (
    <LoadingOverlay active={isLoading} className={s["overlay-wrapper"]} spinner>
      <div className={s.container}>
        <Header />
        <WarningText />
        <DeleteButtons />
      </div>
    </LoadingOverlay>
  );
};

export default DeleteListModal;
