import React from "react";
import { List } from "../../..";
import { Button, message } from "../../../shared";
import permalink from "../../../../helpers/permalink";
import s from "./Details.module.sass";
import { alertMessages, juridiskFormSingular } from "../../../../helpers/constants";

const createStartDate = (startDatum) => {
  if (typeof startDatum !== "undefined" || startDatum !== null) {
    const date = new Date(startDatum)
      .toLocaleString("sv-se", { year: "numeric", month: "2-digit", day: "2-digit" })
      .replace(/(\d+)\/(\d+)\/(\d+)/, "$3-$1-$2");
    return date;
  }
  return "";
};

const translateJuridiskForm = (juridiskForm, juridiskFormKod) => juridiskFormSingular[juridiskFormKod] || juridiskForm;

const parseOrgnNr = (orgnr, juridiskFormKod) => {
  if (juridiskFormKod === "10") {
    const parts = orgnr.split("-");
    return `${parts[0]}-XXXX`;
  }

  return orgnr;
};

const isValidOrgNr = (orgnr) => /^\d{6}-\d{4}$/.test(orgnr);
const isMissing = (name) => name.includes("[SAKNAS]");
const parseParentName = (name, country) => (name ? (
  <>
    <span>{name.replace("[SAKNAS]", "")}</span>
    {country && <span className={s["details__parent"]}>{`(${country})`}</span>}
  </>
) : null);

const Details = ({
  orgnr,
  antalArbetsställen,
  toppmoderNamn,
  toppmoderOrgnr,
  toppmoderLand,
  moderbolagNamn,
  moderbolagOrgnr,
  moderbolagLand,
  namn,
  bransch,
  branschKod,
  aktivt,
  juridiskForm,
  juridiskFormKod,
  startDatum,
  antalDotterbolag,
  storleksklassAnställda,
  storleksklassOmsättning,
  reklamspärr,
  koncernOmsättning,
  tillväxtfaktor,
  bVstatusText,
  isMobile,
  requestSubsidiariesSearch = () => {},
  requestSectorSearch = () => {},
}) => {
  const data = [
    ...(isMobile
      ? [
        {
          label: "Namn",
          value: <div className={s.name}>{namn}</div>,
        },
      ]
      : []
    ),
    { label: "Organisationsnummer", value: parseOrgnNr(orgnr, juridiskFormKod) },
    { label: "Arbetsställen", value: antalArbetsställen },
    ...(branschKod !== "00000"
      ? [
        {
          label: "Bransch",
          value: (
            <Button asLink onClick={() => requestSectorSearch({ branschKod })} additionalClass={s["button-clickable"]}>
              {bransch}
            </Button>
          ),
        }] : []),
    ...(tillväxtfaktor === null
      ? [
        { label: "Antal Anställda", value: storleksklassAnställda },
        { label: "Omsättning", value: storleksklassOmsättning },
      ]
      : []),
    ...((toppmoderNamn && parseParentName(toppmoderNamn))
      ? [
        {
          label: "Koncernmoderbolag",
          value: isMissing(toppmoderNamn) ? (
            <button
              type="button"
              onClick={() => message.info(alertMessages.NO_COMPANY_DATA)}
              className={s["div-clickable"]}
            >
              {parseParentName(toppmoderNamn, toppmoderLand)}
            </button>
          ) : isValidOrgNr(toppmoderOrgnr) ? (
            <Button
              href={`/company/${toppmoderOrgnr}/${permalink(toppmoderNamn)}`}
              additionalClass={s["button-clickable"]}
              asLink
            >
              {parseParentName(toppmoderNamn, toppmoderLand)}
            </Button>
          ) : (
            parseParentName(toppmoderNamn, toppmoderLand)
          ),
        },
      ]
      : []),
    ...(koncernOmsättning
      ? [
        {
          label: "Koncernomsättning",
          value: koncernOmsättning,
        },
      ]
      : []
    ),
    ...((moderbolagNamn && parseParentName(moderbolagNamn))
      ? [
        {
          label: "Moderbolag",
          value: isMissing(moderbolagNamn) ? (
            <button
              type="button"
              onClick={() => message.info(alertMessages.NO_COMPANY_DATA)}
              className={s["div-clickable"]}
            >
              {parseParentName(moderbolagNamn, moderbolagLand)}
            </button>
          ) : isValidOrgNr(moderbolagOrgnr) ? (
            <Button
              href={`/company/${moderbolagOrgnr}/${permalink(moderbolagNamn)}`}
              additionalClass={s["button-clickable"]}
              asLink
            >
              {parseParentName(moderbolagNamn, moderbolagLand)}
            </Button>
          ) : (
            parseParentName(moderbolagNamn, moderbolagLand)
          ),
        },
      ] : []),
    ...(antalDotterbolag
      ? [
        {
          label: "Antal dotterbolag",
          value: (
            <Button
              asLink
              onClick={() => requestSubsidiariesSearch({ orgnr })}
              additionalClass={s["button-clickable"]}
            >
              {antalDotterbolag}
            </Button>
          ),
        },
      ]
      : []),
    {
      label: "Bolagsform",
      value: juridiskForm ? (
        <>
          {translateJuridiskForm(juridiskForm, juridiskFormKod)}
          {" "}
          sedan
          <span style={{ whiteSpace: "nowrap", marginLeft: "4px" }}>
            {createStartDate(startDatum)}
          </span>
        </>
      ) : "",
    },
    { label: "Status", value: aktivt ? (aktivt === "J" ? "Aktivt" : "Inte aktivt") : "" },
    ...(bVstatusText
      ? [
        {
          label: "Status Bolagsverket",
          value: bVstatusText,
        },
      ]
      : []
    ),
    ...(reklamspärr ? [
      {
        label: "Reklamspärr",
        value: (<span className={s.alert}>Bolaget är reklamspärrat</span>),
      },
    ] : []),
  ];

  return (
    <div className={s["details__container"]}>
      <List
        data={data}
        valueStyles={s["details__value"]}
        columnStyles={s["details__column"]}
        additionalClassnames={s["natural-height"]}
        flex
      />
    </div>
  );
};

export default Details;
