import React from "react";
import { MdArrowForward as Arrow } from "react-icons/md";
import cn from "classnames";
import s from "./ArrowIndicator.module.sass";

const ArrowIndicator = ({ position = "up" }) => {
  const classnames = cn(s.container, [s[position]]);

  return <Arrow className={classnames} />;
};

export default ArrowIndicator;
