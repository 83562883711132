import React from "react";
import { Button } from "../../../shared";
import s from "./ListTutorial.module.sass";

const ListTutorial = ({ signupUser, signinUser }) => (
  <div className={s.wrapper}>
    <img
      src="/images/mina-listor.png"
      alt="mina-listor"
      className={`${s["feature-item"]} ${s["feature-image"]}`}
    />
    <div className={s["feature-item"]}>
      <h2 className={s["feature-item__text-header"]}>Mina listor</h2>
      <p className={s["feature-item__description"]}>
        Skapa enkelt listor med de mest intressanta företagen du vill
        kontakta.
      </p>
      <p className={s["feature-item__description"]}>
        Registrera dig kostnadsfritt för åtkomst till funktionen.
      </p>
      <div className={s.buttons}>
        <Button onClick={signupUser} success shadow big>Skapa konto</Button>
        <Button onClick={signinUser} primary shadow big>Logga in</Button>
      </div>
    </div>
  </div>
);

export default ListTutorial;
