import React from "react";
import { withRouter } from "react-router-dom";
import ContentLoader from "react-content-loader";
import { Dropdown } from "antd";
import { FaEllipsisV } from "react-icons/fa";
import { List, message } from "../../../shared";
import { ListActions } from "..";
import MenuComponent from "./Menu";
import permalink from "../../../../helpers/permalink";
import { alertMessages } from "../../../../helpers/constants";
import "antd/lib/dropdown/style/index.css";
import s from "./SingleList.module.sass";


const SingleList = ({
  list,
  name = "(Name/ID is missing)",
  isLoading,
  openModal,
  history,
  requestCompany,
  selectCompany,
  selectedCompanyId,
  isMobile,
  className,
  onBack,
  onSelect,
  ListActionChildren,
  noDataMessage,
  disableLoading,
  additionalTitleClassname,
  additionalListActionsClassnames,
}) => {
  const onCompanySelect = ({ orgnr, cfarnr, miseId }) => {
    selectCompany(miseId);
    requestCompany(orgnr, cfarnr);
  };

  const dataDTO = list.träfflista.map(({
    namn, antalAnställda, postort, miseId, orgnr, cfarnr, utländskt, land,
  }) => ({
    label: namn,
    details: !utländskt ? (
      <>
        {`Antal anställda: ${antalAnställda}`}
        {postort ? ` | ${postort}` : ""}
      </>
    ) : (
      `(${land})`
    ),
    selected: miseId === selectedCompanyId,
    ...onSelect && { onClick: onSelect },
    ...(isMobile
      ? { href: `/company/${orgnr}/${permalink(namn)}`, ...!onSelect && { hrefProps: { fromSingleList: true } } }
      : { onClick: () => onCompanySelect({ orgnr, cfarnr, miseId }) }
    ),
    ...utländskt && {
      onClick: () => message.info(alertMessages.FOREIGN_COMPANY, 3),
      href: undefined,
    },
    foreign: utländskt,
  }));

  if (isLoading && !disableLoading) {
    return (
      <ContentLoader
        height={500}
        width={360}
        speed={2}
        primaryColor="#f3f3f3"
        secondaryColor="#ecebeb"
        baseUrl={window.location.pathname}
        data-testid="content-loader"
      >
        <circle cx="330" cy="30" r="10" />
        <circle cx="305" cy="30" r="10" />
        <circle cx="30" cy="30" r="10" />
        <rect x="20" y="70" rx="0" ry="0" width="300" height="30" />
        <rect x="20" y="130" rx="0" ry="0" width="280" height="25" />
        <rect x="20" y="170" rx="0" ry="0" width="280" height="25" />
        <rect x="20" y="210" rx="0" ry="0" width="280" height="25" />
        <rect x="20" y="250" rx="0" ry="0" width="280" height="25" />
      </ContentLoader>
    );
  }

  return (
    <div className={className}>
      <ListActions
        onBack={onBack || history.goBack}
        title={name}
        additionalTitleClassname={additionalTitleClassname}
        additionalClassnames={additionalListActionsClassnames}
      >
        <div className={s["list-actions__children"]}>
          {ListActionChildren ? (
            <ListActionChildren />
          ) : (
            <Dropdown overlay={MenuComponent(openModal)} trigger={["click"]} placement="bottomRight">
              <FaEllipsisV />
            </Dropdown>
          )}
        </div>
      </ListActions>
      <List
        data={dataDTO}
        columnStyles={s["list-column"]}
        additionalClassnames={s["natural-height"]}
        listItemFallback={{ label: noDataMessage || "Listan är tom" }}
        withArrow
        data-testid="list-container"
      />
    </div>
  );
};

export default withRouter(SingleList);
