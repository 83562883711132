import React from "react";
import { modalNames } from "../../../../../../helpers/constants";
import s from "./DetailsPrefilled.module.sass";

const DetailsPrefilled = ({
  show, companyInfo, userInvoiceDetails, openModal,
}) => {
  if (show) {
    return (
      <div>
        <ul className={s["details-list"]}>
          {companyInfo.map((label, i) => <li key={i}>{label}</li>)}
          <li className={s["change-details-container"]}>
            <button type="button" className={s["change-details-button"]} onClick={() => openModal(modalNames.COMPANY_INFO)}>Ändra faktureringsadress</button>
          </li>
        </ul>
      </div>
    );
  }
  return null;
};

export default DetailsPrefilled;
