import React from "react";
import LoadingOverlay from "react-loading-overlay";
import { MdClose } from "react-icons/md";
import Form from "./modules/Form";
import s from "./CompanyDataModal.module.sass";

const CompanyDataModal = ({ closeModal, isLoading, ...rest }) => {
  const Header = () => (
    <div className={s.header}>
      <span className={s["header__title"]}>Faktureringsuppgifter</span>
      <MdClose onClick={closeModal} className={s["header__icon"]} />
    </div>
  );

  return (
    <LoadingOverlay active={isLoading} className={s["overlay-wrapper"]} spinner>
      <div className={s.container}>
        <Header />
        <div className={s.content}>
          <Form {...rest} />
        </div>
      </div>
    </LoadingOverlay>
  );
};

export default CompanyDataModal;
