import React, { Component } from "react";
import Modal from "react-modal";
import { Terms, Footer } from "../../components";
import { CookiesModal } from "../../components/Modals";
import { modalNames } from "../../helpers/constants";
import s from "./TermsContainer.module.sass";

class TermsContainer extends Component {
  componentDidMount() {
    this.props.displaySearchList(false);
    window.scrollTo({ top: 0, behavior: "smooth" });
  }

  render() {
    const {
      modalIsOpen, openModal, closeModal, modalName,
    } = this.props;

    return (
      <div className={s.container}>
        <div className={s.wrapper}>
          <Terms />
        </div>

        <Footer openModal={openModal} />
        <Modal
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
          closeTimeoutMS={150}
          className={s["modal-wrapper"]}
          overlayClassName={s["modal-overlay"]}
        >
          {modalName === modalNames.COOKIES && <CookiesModal />}
        </Modal>
      </div>
    );
  }
}

export default TermsContainer;
