import { connect } from "react-redux";
import { reduxForm, formValueSelector } from "redux-form";
import { compose } from "recompose";
import { actionCreators as modalActionCreators } from "../../store/Modal";
import { actionCreators as userActionCreators } from "../../store/User";
import { actionCreators as paymentActionCreators } from "../../store/Payment";
import RefillPage from "./RefillPage";

const invoiceFieldsValidator = (state) => {
  const fields = [
    state.user.orgno,
    state.user.invoiceCompanyName,
    state.user.invoiceAddress,
    state.user.invoiceZip,
    state.user.invoiceCity,
  ];
  return fields.every(Boolean);
};

const mapStateToProps = (state) => {
  const selector = formValueSelector("potRefillForm");
  const amountType = selector(state, "amountType");
  const amountValue = selector(state, "amountValue") || 300;
  const hasCustomAmountValue = amountType === "useCustomAmount";

  return {
    potBalance: state.user.potBalance,
    hasCustomAmountValue,
    amountValue,
    amountType,
    diamondsUserId: (state.user.clientInfo["https://sökaföretag.se/app_metadata"] || {}).diamonds_user_id,
    isLoading: state.user.isLoading || state.payment.isLoading,
    isFetchingNewBalance: state.user.isFetchingNewBalance,
    clientEmail: state.user.invoiceEmail || state.user.clientInfo.email,
    clientZipcode: state.user.invoiceZip,
    canSubmit: invoiceFieldsValidator(state),
    payment: state.payment,
    companyName: state.user.invoiceCompanyName,
    companyInfo: [
      state.user.orgno,
      state.user.invoiceAddress,
      state.user.invoiceZip,
      state.user.invoiceCity,
      state.user.phone,
      state.user.invoiceEmail,
      state.user.invoiceAttention,
      state.user.invoiceCOAddress,
    ],
    fullAccess: state.user.fullAccess,
  };
};

const mapActionCreators = {
  ...modalActionCreators,
  ...userActionCreators,
  ...paymentActionCreators,
};

export default compose(
  connect(
    mapStateToProps,
    mapActionCreators,
  ),
  reduxForm({
    form: "potRefillForm",
    forceUnregisterOnUnmount: false,
    destroyOnUnmount: false,
    initialValues: {
      amountType: 300,
    },
  }),
)(RefillPage);
