import React from "react";
import { MdBusiness } from "react-icons/md";
import cn from "classnames";
import { List } from "../..";
import s from "./Results.module.sass";
import permalink from "../../../helpers/permalink";

const Results = ({
  results = [],
  show,
  header,
  selectedCompanyId,
  selectedCompanyMiseId,
  noShadow,
  noBorder,
  noIcon,
  paddedBorder,
  relative,
  showMore = true,
  compact = false,
  pathname,
  requestCompany,
  className,
}) => {
  let options = results.map(({
    orgnr, arbstLöpnr, miseId, cfarnr, namn, postort, fältträff, antalAnställda,
  }) => ({
    label: namn,
    icon: !noIcon ? <MdBusiness className={s.companyIcon} /> : null,
    details: (
      <>
        {`Antal anställda: ${antalAnställda} | `}
        {postort}
        {fältträff !== "Namn" ? ` | Fältträff: ${fältträff}` : ""}
      </>
    ),
    href: `/company/${orgnr}/${permalink(namn)}/${cfarnr || ""}`,
    onClick: () => {
      const shouldFetch = miseId !== selectedCompanyMiseId;
      const alreadyOnRoute = pathname === `/company/${orgnr}/${permalink(namn)}/${cfarnr || ""}`;
      if (shouldFetch && alreadyOnRoute) {
        requestCompany(orgnr, cfarnr);
      }
    },
    selected: (arbstLöpnr !== -1) ? arbstLöpnr === selectedCompanyId : miseId === selectedCompanyMiseId,
  }));

  if (compact) {
    options = options.slice(0, 5);
  }

  if (showMore && results.length > 0) {
    options.push({
      label: "Visa fler...",
      href: "/search",
    });
  }

  const resultsClassNames = cn(
    s["results-wrapper"],
    { [s.noShadow]: noShadow },
    { [s.noBorder]: noBorder },
    { [s.relative]: relative },
    className,
  );

  if (!show) return <div />;

  return (
    <div className={resultsClassNames}>
      {header && <header className={s["results-header"]}>{header}</header>}
      <List data={options} columnStyles={s["results-list"]} paddedBorder={paddedBorder} withArrow searchResults />
    </div>
  );
};

export default Results;
