import React from "react";
import { Link } from "react-router-dom";
import { MdCallToAction, MdAttachMoney, MdWork } from "react-icons/md";
import cn from "classnames";
import { OutsideAlerter } from "../../../shared";
import SidebarAccount from "./SidebarAccount";
import s from "./Menu.module.sass";

const Menu = ({
  open,
  toggleMenu,
  clientInfo,
  signoutUser,
  signinUser,
  signupUser,
  potBalance,
  authenticated,
  fullAccessValidTo,
  fullAccess,
}) => (
  <OutsideAlerter active={open} className={cn(s["off-canvas-menu"], { [s["off-canvas-menu--is-open"]]: open })} onClick={toggleMenu}>
    <ul className={s.navigation}>
      <li>
        <SidebarAccount
          clientInfo={clientInfo}
          signinUser={signinUser}
          signupUser={signupUser}
          signoutUser={signoutUser}
          potBalance={potBalance}
          authenticated={authenticated}
          toggleMenu={toggleMenu}
          fullAccessValidTo={fullAccessValidTo}
          fullAccess={fullAccess}
          className={s["navigation__link"]}
        />
      </li>
      <li>
        <Link to="/mylists" onClick={toggleMenu} className={s["navigation__link"]}>
          <MdCallToAction className={s["navigation__icon"]} />
          {" "}
          Mina listor
        </Link>
      </li>
      <li>
        <Link to="/sectors" onClick={toggleMenu} className={s["navigation__link"]}>
          <MdWork className={s["navigation__icon"]} />
          {" "}
          Branscher
        </Link>
      </li>
      <li>
        <Link to="/pricing" onClick={toggleMenu} className={s["navigation__link"]}>
          <MdAttachMoney className={s["navigation__icon"]} />
          {" "}
          Priser
        </Link>
      </li>
    </ul>
  </OutsideAlerter>
);

export default Menu;
