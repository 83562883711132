import React from "react";
import { Helmet } from "react-helmet";
import LoadingOverlay from "react-loading-overlay";
import { ElementsConsumer, CardNumberElement } from "@stripe/react-stripe-js";
import { PotRefill } from "../../components";
import { message } from "../../components/shared";
import PriceListModal from "../../components/PriceList/PriceListModal";
import s from "./RefillPage.module.sass";

class RefillPage extends React.Component {
  state = { visible: false };

  componentDidMount() {
    if (this.props.fullAccess) {
      this.props.history.push("/");
      return;
    }

    this.props.getUser();
    this.props.setEmail(this.props.clientEmail);
    this.props.setZipcode(this.props.clientZipcode);
  }

  componentDidUpdate(prevProps) {
    if (this.props.clientEmail !== prevProps.clientEmail) {
      this.props.setEmail(this.props.clientEmail);
    }

    if (this.props.clientZipcode !== prevProps.clientZipcode) {
      this.props.setZipcode(this.props.clientZipcode);
    }
  }

  showModal = () => {
    this.setState({
      visible: true,
    });
  };

  handleClose = () => {
    this.setState({
      visible: false,
    });
  };

  onFormSubmit = async (event) => {
    event.persist();
    event.preventDefault();

    const { stripe, elements } = this.props;
    const { email, zipcode } = this.props.payment;

    if (!stripe || !elements) {
      return;
    }

    try {
      const clientSecret = await this.props.initiatePaymentIntent();

      if (clientSecret) {
        const paymentSuccess = await this.props.confirmCardPayment({
          card: elements.getElement(CardNumberElement),
          stripe,
          clientSecret,
          email,
          zipcode,
        });

        if (paymentSuccess) {
          this.props.checkForNewPotBalance();
        }
      }
    } catch (err) {
      message.error(err.message || err);
    }
  }

  render() {
    const { isLoading, canSubmit } = this.props;

    return (
      <main className={s.wrapper}>
        <LoadingOverlay active={isLoading} className={s["overlay-wrapper"]} spinner>
          <form onSubmit={this.onFormSubmit} disabled={isLoading || !canSubmit}>
            <PotRefill showModal={this.showModal} {...this.props} />
          </form>
          <PriceListModal showModal={this.showModal} handleClose={this.handleClose} visible={this.state.visible} />
        </LoadingOverlay>
        <Helmet>
          <title>SökaFöretag - Fyll på pott</title>
        </Helmet>
      </main>
    );
  }
}

const InjectedRefillPage = (props) => (
  <ElementsConsumer>
    {({ elements, stripe }) => (
      <RefillPage {...props} elements={elements} stripe={stripe} />
    )}
  </ElementsConsumer>
);

export default InjectedRefillPage;
