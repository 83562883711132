import React from "react";
import { Route, Switch } from "react-router-dom";
import Modal from "react-modal";
import { CoreLayout as Layout } from "../../layouts";
import {
  HomePage,
  CompanyPage,
  CompanySearchList,
  NotFound,
  MyListsPage,
  Callback,
  SecuredRoute,
  RefillPage,
  PriceListContainer,
  TermsContainer,
  SectorPage,
  VerifyPage,
  PrivacyContainer,
  LoginPage,
} from "..";
import "./App.scss";

Modal.setAppElement(document.getElementById("root"));

/* Swap <Route /> for <SecuredRoute /> for authenticated pages */
export default () => (
  <Layout>
    <Switch>
      <Route exact path="/" component={HomePage} />
      <Route exact path="/search" component={CompanySearchList} />
      <Route exact path="/sectors" component={SectorPage} />
      <Route exact path="/potrefill" component={RefillPage} />
      <Route exact path="/pricing" component={PriceListContainer} />
      <Route exact path="/tos" component={TermsContainer} />
      <Route exact path="/privacy" component={PrivacyContainer} />
      <Route exact path="/verify" component={VerifyPage} />
      <Route exact path="/company/:orgnr/:namn?/:cfarnr?" component={CompanyPage} />
      <Route path="/mylists" component={MyListsPage} />
      <Route exact path="/login" component={LoginPage} />
      <Route exact path="/callback" component={Callback} />
      <Route component={NotFound} />
    </Switch>
  </Layout>
);
