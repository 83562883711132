import React from "react";
import MediaQuery from "react-responsive";
import cn from "classnames";
import { Table } from "antd";
import { MdPerson, MdLocalPhone } from "react-icons/md";
import { IoIosAt } from "react-icons/io";
import _isNull from "lodash/isNull";
import { HiddenField, Button } from "../../../shared";
import { modalNames } from "../../../../helpers/constants";
import s from "./Executives.module.sass";

const Executives = ({
  personer = [], authenticated, openModal, unlockedFields, miseId, reklamspärr,
}) => {
  const data = reklamspärr ? [
    {
      iconField: <MdPerson className={s["executives__icon"]} />,
      nameField: (
        <div>
          <div className={s["executives__name"]}>
              Bolaget är reklamspärrat. Beslutsfattare visas ej.
          </div>
        </div>
      ),
      key: 1,
    },
  ]
    : personer && personer.length ? personer.map(({
      befattning, efternamn, förnamn, protectedItems,
    }, i) => {
      const direktnr = protectedItems.find(({ fieldName }) => fieldName === "Direktnr");
      const epost = protectedItems.find(({ fieldName }) => fieldName === "Epost");
      const execName = authenticated ? `${förnamn} ${efternamn}` : "Logga in för att se namn";

      /* Live update of unlocked fields */
      if (unlockedFields[miseId]) {
        for (const u of unlockedFields[miseId]) {
          if (u.unlocked && (u.title === befattning || _isNull(u.title))) {
            if (u.fieldName === "Epost") {
              epost.unlocked = u.unlocked;
              epost.value = u.value;
            }

            if (u.fieldName === "Direktnr") {
              direktnr.unlocked = u.unlocked;
              direktnr.value = u.value;
            }
          }
        }
      }

      const iconField = <MdPerson className={s["executives__icon"]} />;
      const nameField = (
        <div>
          <button
            type="button"
            className={cn(s["executives__name"], { [s["name-hidden"]]: !authenticated })}
            onClick={() => !authenticated && openModal(modalNames.LOGIN_SEE_CONTACTS)}
          >
            {execName}
          </button>
          <div className={s["executives__title"]}>{befattning}</div>
        </div>
      );

      const phoneField = direktnr ? (
        <div className={s["executives__hidden"]}>
          <MediaQuery query="(max-width: 768px)">
            {(matches) => (
              <HiddenField
                name="direktnummer"
                fieldName={direktnr.fieldName}
                unlocked={direktnr.unlocked}
                Icon={MdLocalPhone}
                isMobile={matches}
                data={{ befattning, execName }}
              >
                {matches ? (
                  <>
                    <Button
                      primary
                      big
                      className={cn(s["executives-mobile__button"], {
                        [s.unlocked]: direktnr.unlocked,
                      })}
                      onClick={() => { window.location.href = `tel:${direktnr.value}`; }}
                    >
                      <MdLocalPhone className={s["executives-mobile__icon"]} />
                    </Button>
                    <div className={s["executives__print"]}>{direktnr.value}</div>
                  </>
                ) : (
                  <a
                    href={`tel:${direktnr.value}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className={s["executives__hidden--field"]}
                  >
                    {direktnr.value}
                  </a>
                )}
              </HiddenField>
            )}
          </MediaQuery>
        </div>
      ) : (
        <div />
      );

      const emailField = epost ? (
        <div className={s["executives__hidden"]}>
          <MediaQuery query="(max-width: 768px)">
            {(matches) => (
              <HiddenField
                name="e-postadress"
                fieldName={epost.fieldName}
                unlocked={epost.unlocked}
                Icon={IoIosAt}
                isMobile={matches}
                data={{ befattning, execName }}
              >
                {matches ? (
                  <>
                    <Button
                      primary
                      big
                      className={cn(s["executives-mobile__button"], {
                        [s.unlocked]: epost.unlocked,
                      })}
                      onClick={() => { window.location.href = `mailto:${epost.value}`; }}
                    >
                      <>
                        <IoIosAt className={s["executives-mobile__icon"]} />
                      </>
                    </Button>
                    <div className={s["executives__print"]}>{epost.value}</div>
                  </>
                ) : (
                  <a
                    href={`mailto:${epost.value}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className={s["executives__hidden--field"]}
                  >
                    {epost.value}
                  </a>
                )}
              </HiddenField>
            )}
          </MediaQuery>
        </div>
      ) : (
        <div />
      );

      return {
        iconField,
        nameField,
        phoneField,
        emailField,
        key: i,
      };
    })
      : [
        {
          iconField: <MdPerson className={s["executives__icon"]} />,
          nameField: (
            <div>
              <div className={s["executives__name"]}>
                Inga beslutsfattare hittades
              </div>
            </div>
          ),
          key: 1,
        },
      ];

  return (
    <Table dataSource={data} pagination={false} showHeader={false} rowClassName={s["executives__row"]} className={s["executives__container"]}>
      <Table.Column align="left" className={s["executives__column"]} dataIndex="iconField" key="iconField" width={32} />
      <Table.Column align="left" className={s["executives__column"]} dataIndex="nameField" key="nameField" />
      <Table.Column align="right" className={s["executives__column"]} dataIndex="phoneField" key="phoneField" />
      <Table.Column align="right" className={s["executives__column"]} dataIndex="emailField" key="emailField" />
    </Table>
  );
};

Executives.defaultProps = {
  personer: [],
};

export default Executives;
