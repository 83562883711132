import React, { Component } from "react";
import ReactDOM from "react-dom";
import { Collapse } from "antd";
import MediaQuery from "react-responsive";
import "antd/lib/collapse/style/index.css";
import { Economy, Branch } from "..";
import ChartTitle from "./ChartTitle";
import s from "./ChartsContainer.module.sass";

const { Panel } = Collapse;

export default class ChartsContainer extends Component {
  panelRefs = [];

  state = {
    selectedTab: 0,
    activePanel: ["0"],
    data: [{}],
  };

  onTabChange = (e) => {
    const selectedTab = Number(e.target.dataset.index);

    if (!Number.isNaN(selectedTab)) {
      this.setState({ selectedTab });
    }
  };

  onPanelChange = (keys) => {
    const activePanel = [keys.pop()].filter(Boolean);
    this.setState({ activePanel });

    if (activePanel[0]) {
      this.panelRefs[activePanel[0]].scrollIntoView({ behavior: "smooth" });
    }
  };

  extractData = (data) => {
    this.setState({ data });
  };

  componentDidUpdate(prevProps) {
    if(prevProps.company.miseId !== this.props.company.miseId) {
      this.setState({
        selectedTab: 0,
        activePanel: ["0"],
      })
    }
  }

  render() {
    const { company, s: styles } = this.props;
    const { selectedTab, activePanel, data } = this.state;
    const { label, value } = data[selectedTab];

    return (
      <div className={s["charts__container"]}>
        <MediaQuery query="(max-width: 768px)">
          {(matches) => {
            if (matches) {
              return (
                <>
                  <Economy s={styles} {...company} selectedTab={selectedTab} onTabChange={this.onTabChange} extractData={this.extractData} hidden withGrowth />
                  <Collapse
                    expandIconPosition="right"
                    destroyInactivePanel
                    activeKey={activePanel}
                    onChange={this.onPanelChange}
                    className={s["collapse__container"]}
                  >
                    {data.map(({ label, value, trending }, i) => (
                      <Panel
                        header={label}
                        extra={value}
                        key={i}
                        className={s["collapse__header"]}
                        ref={(node) => {
                          this.panelRefs[i] = ReactDOM.findDOMNode(node);
                        }}
                      >
                        <ChartTitle label={label} value={value} trending={trending} s={s} />
                        <Branch s={styles} {...company} selectedTab={i} withGrowth />
                      </Panel>
                    ))}
                  </Collapse>
                </>
              );
            }
            return (
              <>
                <ChartTitle label={label} value={value} s={s} />
                <Economy s={styles} {...company} selectedTab={selectedTab} onTabChange={this.onTabChange} extractData={this.extractData} />
                <Branch s={styles} {...company} selectedTab={selectedTab} />
              </>
            );
          }}
        </MediaQuery>
      </div>
    );
  }
}
