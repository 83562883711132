import React from "react";
import { Field } from "redux-form";
import { AmountRadio } from "..";
import s from "./AmountSelection.module.sass";

// const valueLimitNormalizer = (value, previousValue) =>
//   value.length == 0 ? null : value < 99999 && value >= 0 ? value : previousValue;

const AmountSelection = ({ hasCustomAmountValue, change }) => (
  <div className={s.container}>
    <div className={s["amount-card"]}>
      <Field
        name="amountType"
        component={AmountRadio}
        parse={Number}
        id="amountType1"
        type="radio"
        value={300}
        label="+300 poster"
      />
      <Field
        name="amountType"
        component={AmountRadio}
        parse={Number}
        id="amountType2"
        type="radio"
        value={500}
        label="+500 poster"
      />
      {/* <Field name="amountType" component={AmountRadio} id="amountType3" type="radio" value="useCustomAmount" label="Välj eget antal poster" onChange={() => change('amountValue', 0)} /> */}
      {/* {hasCustomAmountValue && <Field name="amountValue" component={AmountText} type="number" value="customAmount" label="ANGE ANTAL ÖNSKADE POSTER" maxLength="5" minValue="0" normalize={valueLimitNormalizer} />} */}
    </div>
  </div>
);

export default AmountSelection;
