import React, { Component } from "react";
import { Helmet } from "react-helmet";
import { SearchList } from "../../components";
import decodeURLParams from "../../helpers/decodeURLParams";
import filterOptions from "./filterOptions";
import s from "./CompanySearchList.module.sass";

class CompanySearchList extends Component {
  componentDidMount() {
    if (this.props.searchData.hasSearchData) {
      this.props.displaySearchList(true);
    }

    const { query } = decodeURLParams(this.props.location.search);
    if (query) {
      this.props.requestCompanySearch(query);
    }
  }

  handleSortChange = (value) => {
    const { from, to, query } = this.props.searchData;
    this.props.requestCompanySearch(query, value, from, to);
  };

  handleInputChange = ({ target }) => {
    this.props.requestCompanySearch(target.value, this.props.searchData.sort, 0, 20);
  };

  handlePageChange = ({ selected }) => {
    const { limit, query, sort } = this.props.searchData;
    const from = selected * limit;
    const to = from + limit;
    this.props.requestCompanySearch(query, sort, from, to);
  };

  render() {
    const { searchData, displaySearchList } = this.props;
    return (
      <main className={s.container}>
        <SearchList
          onFilterChange={this.handleSortChange}
          onPageChange={this.handlePageChange}
          handleInputChange={this.handleInputChange}
          displaySearchList={displaySearchList}
          filterOptions={filterOptions}
          searchData={searchData}
          {...searchData}
        />
        <Helmet>
          <title>SökaFöretag - Sök</title>
        </Helmet>
      </main>
    );
  }
}

export default CompanySearchList;
