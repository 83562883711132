import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { actionCreators as companyActionCreators } from "../../store/Company";
import { actionCreators as modalActionCreators } from "../../store/Modal";
import { actionCreators as searchActionCreators } from "../../store/Search";
import { actionCreators as myListsActionCreators } from "../../store/MyLists";
import CompanyPage from "./CompanyPage";

const mapStateToProps = (state) => ({
  companyData: state.companyData,
  searchData: state.searchData,
  singleListData: state.singleListData,
  authenticated: state.auth.authenticated,
  router: state.router,
  selectedCompanyId: state.singleListData.selectedCompanyId,
});

const mapActionCreators = {
  ...companyActionCreators,
  ...modalActionCreators,
  ...searchActionCreators,
  ...myListsActionCreators,
};

const withRedux = connect(
  mapStateToProps,
  mapActionCreators,
);

const withReduxAndRouter = (C) => withRouter(withRedux(C));

export default withReduxAndRouter(CompanyPage);
