import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect } from "react-router-dom";
import { actionCreators } from "../../store/Auth";

const LoginPage = () => {
  const dispatch = useDispatch();
  const authenticated = useSelector(({ auth }) => auth.authenticated);

  useEffect(() => {
    if (!authenticated) {
      dispatch(actionCreators.signinUser());
    }
  }, [authenticated]);

  return authenticated ? <Redirect to="/" /> : null;
};

export default LoginPage;
