import {
  applyMiddleware, combineReducers, compose, createStore,
} from "redux";
import { connectRouter, routerMiddleware } from "connected-react-router";
import promiseMiddleware from "redux-promise-middleware";
import thunk from "redux-thunk";
import { createLogger } from "redux-logger";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { reducer as formReducer } from "redux-form";
import { authReducer, callbackReducer } from "mise-react-redux-auth0";
import * as CompanySearch from "./Search";
import * as SingleCompany from "./Company";
import * as MyLists from "./MyLists";
import * as Ui from "./Ui";
import * as Modal from "./Modal";
import * as User from "./User";
import * as Payment from "./Payment";
import errorMiddleware from "./middlewares/errorMiddleware";

const persistConfig = {
  key: "root",
  blacklist: ["searchData", "companyData", "singleListData", "modal", "ui", "router", "form", "payment"],
  storage,
};

export default function configureStore(history, initialState) {
  const reducers = {
    searchData: CompanySearch.reducer,
    companyData: SingleCompany.reducer,
    myListsData: MyLists.reducer,
    singleListData: MyLists.singleListReducer,
    ui: Ui.reducer,
    modal: Modal.reducer,
    user: User.reducer,
    payment: Payment.reducer,
    auth: authReducer,
    callback: callbackReducer,
    form: formReducer,
  };

  const middleware = [
    thunk,
    errorMiddleware,
    routerMiddleware(history),
    promiseMiddleware(),
  ];

  // In development, use the browser's Redux dev tools extension if installed
  const enhancers = [];
  const isDevelopment = process.env.NODE_ENV === "development";
  if (isDevelopment && typeof window !== "undefined" && window.__REDUX_DEVTOOLS_EXTENSION__) {
    enhancers.push(window.__REDUX_DEVTOOLS_EXTENSION__());
  }

  if (isDevelopment) {
    middleware.push(createLogger());
  }

  const rootReducer = combineReducers({
    router: connectRouter(history),
    ...reducers,
  });

  const persistedReducer = persistReducer(persistConfig, rootReducer);

  return createStore(
    persistedReducer,
    initialState,
    compose(applyMiddleware(...middleware), ...enhancers),
  );
}

export function persistor(store) {
  return persistStore(store);
}
