import { connect } from "react-redux";
import { actionCreators as modalActionCreators } from "../../store/Modal";
import NotFound from "./NotFound";

const mapStateToProps = () => ({});

const mapActionCreators = {
  ...modalActionCreators,
};

const withRedux = connect(
  mapStateToProps,
  mapActionCreators,
);

export default withRedux(NotFound);
