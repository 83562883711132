import React from "react";
import s from "./PotRefill.module.sass";
import {
  AmountSelection, PotSpecifcation, PotBalance, PriceInfo, InvoiceDetails, PaymentCard,
} from "./modules";
import "antd/lib/alert/style/index.css";

const PotRefill = (props) => (
  <div className={s.container}>
    <h1 className={s["page-header"]}>Se och fyll på pott</h1>
    <h2>Välj belopp att fylla på pott med</h2>

    <div className={s["content-grid"]}>
      <PotBalance {...props} />
      <AmountSelection {...props} />
      <PriceInfo {...props} />
      <InvoiceDetails {...props} />
      <PaymentCard {...props} />
      <PotSpecifcation {...props} />
    </div>
  </div>
);

export default PotRefill;
