import React from "react";
import { MdClose } from "react-icons/md";
import s from "./CookiesModal.module.sass";

const CookiesModal = ({ closeModal }) => (
  <div className={s["overlay-wrapper"]}>
    <div className={s.header}>
      <span className={s["header__title"]}>Om cookies</span>
      <MdClose onClick={closeModal} className={s["header__icon"]} />
    </div>
    <p>Marknadsinformation i Sverige AB använder inte cookies för att samla information om användare.</p>
    <p>
        En cookie är en textfil som sparas på användarens dator och kan raderas mycket enkelt i den webläsare du använder.
    </p>
    <p>Genom besök på denna hemsida samtycker du till användningen av cookies på webbplatsen.</p>
    <p>
        Cookies används för att hålla koll på inloggningar, samt för att vi ska kunna använda Google Analytics.
    </p>
    <p>
        Om du inte accepterar användning av cookies kan du stänga av dessa, observera dock att funktionalitet kan
        försvinna. Cookies stängs av enligt anvisningarna i respektive webbläsare.
    </p>
  </div>
);

export default CookiesModal;
