import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { actionCreators as modalActionCreators } from "../../Modal";
import ModalProvider from "./ModalProvider";

const mapStateToProps = (state) => ({
  modalIsOpen: state.modal.modalIsOpen,
  modalName: state.modal.name,
  modalClassname: state.modal.data.className,
  authenticated: state.auth.authenticated,
});

const mapActionCreators = {
  ...modalActionCreators,
};

const withRedux = connect(
  mapStateToProps,
  mapActionCreators,
);

const withReduxAndRouter = (C) => withRouter(withRedux(C));

export default withReduxAndRouter(ModalProvider);
