import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter, Route } from "react-router-dom";
import { Auth0, actionCreators } from "../../store/Auth";

const SecuredRoute = (props) => {
  const {
    component: Component,
    path,
    signinUser,
    signout,
    scopes,
    history,
    isAuthenticated,
    hasScopes,
    ...rest
  } = props;

  const renderProps = (ErrorIcon = <div />) => {
    if (!isAuthenticated()) return signinUser();
    if (!hasScopes(scopes)) {
      return (
        <div className="result-page">
          <ErrorIcon />
          <p className="result-message result-message--error">
            Ditt konto har inte tillgång till denna funktion. Om du precis
            fått nya rättigheter tilldelade till ditt konto, pröva att logga
            ut och sedan in igen.
          </p>
          <button className="btn btn--secondary" onClick={signout}>
            Logga ut
          </button>
          {" "}
          {}
          <button className="btn btn--primary" onClick={history.goBack}>
            Återgå till föregående sida
          </button>
        </div>
      );
    }
    return <Component {...props} />;
  };

  return (
    <Route
      path={path}
      render={renderProps}
      {...rest}
    />
  );
};

export default withRouter(connect(
  null,
  (dispatch) => ({
    ...bindActionCreators(actionCreators, dispatch),
    dispatch,
    ...Auth0,
  }),
)(SecuredRoute));
