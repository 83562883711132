import React from "react";
import { GoogleApiWrapper, Map, Marker } from "google-maps-react";
import cn from "classnames";
import carSVG from "../../../../assets/icons/directions_car.svg";
import s from "./Map.module.sass";
import openGoogleMapsDirections from "../../../../helpers/openGoogleMapsDirections";

class MapContainer extends React.Component {
  constructor(props) {
    super(props);

    this.geocoder = new props.google.maps.Geocoder();
    this.state = {
      coord: {
        lat: 59.334,
        lng: 18.063,
      },
      error: {},
    };
  }

  async componentDidUpdate(prevProps) {
    if (this.props.miseId !== prevProps.miseId) {
      const { lat, lng, error } = await this.findCoordinates(this.props);
      if (!error) {
        this.setState({ coord: { lat, lng }, error: {} });
      } else {
        this.setState({ error });
      }
    }
  }

  initMap = ({ google }, map) => {
    this.addNavigationControl(map, google);
  };

  findCoordinates = ({ besöksAdress, besöksPostnr, besöksPostort }) => new Promise((resolve) => {
    if (!besöksAdress) return resolve({ error: { companyHasNoAddress: true } });

    const address = `${besöksAdress}, ${besöksPostnr}, ${besöksPostort}`;

    this.geocoder.geocode({ address }, (response, status) => {
      if (status === "OK") {
        const { location } = response[0].geometry;
        return resolve({
          lat: location.lat(),
          lng: location.lng(),
        });
      }
      return resolve({ error: { problemWithGeocode: true } });
    });
  });

  addNavigationControl = (map, google) => {
    const controlUI = document.createElement("div");
    const navigationControlDiv = document.createElement("div");
    const styles = {
      backgroundColor: "#fff",
      border: "2px solid #fff",
      borderRadius: "3px",
      boxShadow: "0 2px 6px rgba(0,0,0,.3)",
      cursor: "pointer",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      margin: "10px",
      height: "40px",
      width: "40px",
      textAlign: "center",
    };

    for (const [key, value] of Object.entries(styles)) {
      controlUI.style[key] = value;
    }

    controlUI.title = "Get driving directions";
    navigationControlDiv.appendChild(controlUI);

    const controlImg = document.createElement("img");
    controlImg.src = carSVG;
    controlUI.appendChild(controlImg);
    controlUI.addEventListener("click", () => {
      const { besöksAdress, besöksPostnr, besöksPostort } = this.props;
      const to = `${besöksAdress}, ${besöksPostnr}, ${besöksPostort}`;
      openGoogleMapsDirections({ to });
    });
    controlUI.addEventListener("mouseenter", () => {
      controlUI.style.opacity = "0.7";
    });
    controlUI.addEventListener("mouseleave", () => {
      controlUI.style.opacity = "1";
    });

    navigationControlDiv.index = 1;
    map.controls[google.maps.ControlPosition.TOP_LEFT].push(navigationControlDiv);
  }

  render() {
    const { google, namn } = this.props;
    const {
      coord: { lat, lng },
      error: { companyHasNoAddress },
    } = this.state;

    return (
      <div className={cn(s.wrapper, { [s.noAddress]: companyHasNoAddress })}>
        {companyHasNoAddress && <div className={s.overlayText}>Ingen adressinformation tillgänglig</div>}
        <Map
          google={google}
          zoom={14}
          initialCenter={{ lat, lng }}
          center={{ lat, lng }}
          onReady={this.initMap}
          mapTypeControl={false}
          streetViewControl={false}
        >
          {!companyHasNoAddress && <Marker name={namn} title={namn} position={{ lat, lng }} />}
        </Map>
      </div>
    );
  }
}

export default React.memo(GoogleApiWrapper({ apiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY })(MapContainer));
