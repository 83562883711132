import React from "react";
import cn from "classnames";
import { MdSearch } from "react-icons/md";
import PulseLoader from "react-spinners/PulseLoader";
import s from "./Search.module.sass";

const pulseStyleOverride = {
  name: "spinner-styles",
  map: undefined,
  next: undefined,
  styles: `
    display: flex;
    position: absolute;
    right: 10px;
    z-index: 2;
  `,
};

const Search = ({
  name = "search",
  placeholder = "Search for...",
  value,
  onChange,
  onFocus,
  hidden,
  wrapperClassName,
  iconClassName,
  bgClassName,
  className,
  isLoading,
  isSorting,
  loaderColor = "#ffffff",
  ...rest
}) => ((hidden) ? null : (
  <div className={cn(s["search__container"], wrapperClassName)}>
    <MdSearch className={cn(s["search__icon"], iconClassName)} />
    <input
      name={name}
      type="search"
      placeholder={placeholder}
      className={cn(s["search__input"], className)}
      value={value}
      onChange={onChange}
      onFocus={onFocus}
      disabled={isSorting}
      {...rest}
    />
    <PulseLoader
      sizeUnit="px"
      size={8}
      color={loaderColor}
      css={pulseStyleOverride}
      loading={isLoading}
    />
    <span className={cn(s["search__bg"], bgClassName)} />
  </div>
));

export default Search;
