import React from "react";
import { Modal, Table, Button } from "antd";
import "antd/lib/modal/style/index.css";
import "antd/lib/table/style/index.css";
import "antd/lib/button/style/index.css";

const dataSource = [
  {
    key: "1",
    type: "E-postadress till infolåda",
    price: "1",
  },
  {
    key: "2",
    type: "E-postadress (beslutsfattare)",
    price: "2",
  },
  {
    key: "3",
    type: "Direkttelefonnummer (beslutsfattare)",
    price: "1",
  },
  {
    key: "4",
    type: "Namn (beslutsfattare)",
    price: "Gratis som inloggad",
  },
];

const columns = [
  {
    title: "Datatyp",
    dataIndex: "type",
    key: "type",
  },
  {
    title: "Kostnad",
    dataIndex: "price",
    key: "price",
  },
];

const PriceListModal = ({ visible, handleClose }) => (
  <div>
    <Modal
      title="Datakostnader"
      visible={visible}
      onOk={handleClose}
      onCancel={handleClose}
      style={{ padding: 0 }}
      footer={[
        <Button key="1" type="primary" onClick={handleClose}>
          OK
        </Button>,
      ]}
    >
      <Table dataSource={dataSource} columns={columns} pagination={false} />
    </Modal>
  </div>
);

export default PriceListModal;
