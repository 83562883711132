import React, { Component } from "react";
import PropTypes from "prop-types";

/**
 * Component that alerts if you click outside of it
 * @param {Component | Node} children
 * @param {Function} onClick
 * @param {String} className
 * @param {Boolean} active
 */

export default class OutsideAlerter extends Component {
  componentDidMount() {
    document.addEventListener("mousedown", this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  setWrapperRef = (node) => {
    this.wrapperRef = node;
  };

  handleClickOutside = (event) => {
    if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
      if (this.props.active && this.props.onClick) {
        this.props.onClick();
      }
    }
  };

  render() {
    return (
      <div className={this.props.className} ref={this.setWrapperRef}>
        {this.props.children}
      </div>
    );
  }
}

OutsideAlerter.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.element]).isRequired,
  onClick: PropTypes.func.isRequired,
  className: PropTypes.string,
};
