import React from "react";
import MediaQuery from "react-responsive";
import { MdAdd, MdRemove } from "react-icons/md";
import { FaTimes } from "react-icons/fa";
import cn from "classnames";
import s from "./Company.module.sass";
import {
  Grid, Sidebar, Content, Card,
} from "../../layouts";
import {
  Header,
  Map,
  Contact,
  Credit,
  Executives,
  Details,
  Growth,
  CompanyFooter,
  ChartsContainer,
  GrowthExpanded,
  BranchList,
  Featured,
} from "./modules";
import { SearchResults } from "../SearchList/modules";
import { ListActions } from "../MyLists/modules";
import { Tabs } from "..";
import { modalNames } from "../../helpers/constants";

const Company = ({
  company = {},
  searchProps,
  openModal,
  fromSingleList,
  history,
  singleListData,
  addSingleListItem,
  deleteSingleListItem,
  requestCompany,
  requestSubsidiariesSearch,
  requestSectorSearch,
  displayCustomSearchList,
  authenticated,
  selectedCompanyId,
  selectCompany,
  unlockedFields,
  fromSector,
  globalBranschKod,
  pathname,
}) => {
  const { listId } = singleListData.data;
  const companyInList = !!singleListData.data.träfflista.find(({ miseId }) => company.miseId === miseId);
  const { customSearchData } = searchProps.searchData;
  const branchListProps = {
    displayCustomSearchList,
    requestSubsidiariesSearch,
    requestSectorSearch,
    requestCompany,
    selectCompany,
    selectedCompanyId,
    history,
    fromSector,
    globalBranschKod,
  };
  const buttonProps = {
    ...(fromSingleList
      ? companyInList
        ? {
          text: "Ta bort från lista",
          danger: true,
          Icon: MdRemove,
          onClick: () => deleteSingleListItem(listId, company.miseId),
        }
        : {
          text: "Lägg till i lista",
          onClick: () => addSingleListItem(listId, company.miseId, company.namn),
          success: true,
          Icon: MdAdd,
        }
      : {
        text: "Lägg till företag i lista",
        onClick: () => openModal(modalNames.SELECT_LIST),
        success: true,
        Icon: MdAdd,
      }),
  };

  return (
    <>
      <MediaQuery query="(max-width: 768px)">
        {(matches) => (matches ? (
        /* MOBILE VIEW */
          <div className={cn(s["mobile-container"], { [s["fullscreen__overlay"]]: fromSingleList })}>
            {fromSingleList && (
            <ListActions
              onBack={history.goBack}
              title={company.namn}
              Icon={FaTimes}
              additionalClassnames={s.listActions}
            >
              <div />
            </ListActions>
            )}
            <Header buttonProps={buttonProps} s={s} {...company} />
            <BranchList customSearchData={customSearchData} {...company} {...branchListProps} isMobile={matches} />
            <Tabs
              options={[
                {
                  tabName: "Bolagsdetaljer",
                  tabContent: (
                    <>
                      <Card header="Bolagsdetaljer" className={s["card__details"]} noPadding>
                        <Details
                          s={s}
                          {...company}
                          requestCompany={requestCompany}
                          requestSubsidiariesSearch={requestSubsidiariesSearch}
                          requestSectorSearch={requestSectorSearch}
                          isMobile
                        />
                      </Card>
                      <Card header="Plats" className={s["card__map"]} noPadding>
                        <Contact s={s} {...company} unlockedFields={unlockedFields} />
                      </Card>
                      <Card header="Importera" className={s["card__featured"]} noPadding>
                        <Featured s={s} {...company} />
                      </Card>
                      <Card header="Tillväxt" className={s["card__contact"]} noPadding>
                        <Growth s={s} {...company} isMobile={matches} />
                      </Card>
                    </>
                  ),
                },
                ...(!company.reklamspärr ? [
                  {
                    tabName: "Beslutsfattare",
                    tabContent: <Executives
                      s={s}
                      authenticated={authenticated}
                      openModal={openModal}
                      unlockedFields={unlockedFields}
                      containerClassName={s["card__executives--container"]}
                      {...company}
                    />,
                  },
                ] : []),
                { tabName: "Ekonomi & jämförelser", tabContent: <ChartsContainer s={s} company={company} /> },
              ]}
            />
            {/* PRINT VIEW  */}
            {!company.reklamspärr && (
              <Card header="Beslutsfattare" className={s["card__executives--print"]} noPadding>
                <Executives
                  s={s}
                  authenticated={authenticated}
                  openModal={openModal}
                  unlockedFields={unlockedFields}
                  {...company}
                />
              </Card>
            )}
            {company.tillväxtfaktor !== null && (
              <Card header="Tillväxtfaktor" noPadding className={s["card__growth--print"]}>
                <GrowthExpanded s={s} {...company} printMode />
              </Card>
            )}
          </div>
        ) : (
        /* DESKTOP VIEW */
          <>
            <Grid>
              <Sidebar>
                <SearchResults
                  pathname={pathname}
                  requestCompany={requestCompany}
                  {...searchProps}
                  {...searchProps.searchData}
                  {...company}
                />
                <BranchList customSearchData={customSearchData} {...company} {...branchListProps} />
              </Sidebar>
              <Content>
                <Card className={s["card__header"]} noPadding headerStart>
                  <Header
                    s={s}
                    hideBack={!matches}
                    buttonProps={{
                      text: "Lägg till i lista",
                      onClick: () => openModal("SELECT_LIST"),
                      success: true,
                      Icon: MdAdd,
                    }}
                    {...company}
                  />
                </Card>
                <Card
                  header="Plats"
                  className={s["card__map"]}
                  containerClassName={s["card__map--container"]}
                  noPadding
                  headerStart
                >
                  <Map s={s} {...company} />
                  <Contact s={s} {...company} unlockedFields={unlockedFields} />
                </Card>
                <Card header="Importera" className={s["card__featured"]} noPadding headerStart>
                  <Featured s={s} {...company} />
                </Card>
                <Card header="Tillväxt" className={s["card__contact"]} noPadding headerStart>
                  <Growth s={s} {...company} isMobile={matches} />
                </Card>
                <Card header="Bolagsdetaljer" className={s["card__details"]} noPadding headerStart>
                  <Details
                    s={s}
                    {...company}
                    requestCompany={requestCompany}
                    requestSubsidiariesSearch={requestSubsidiariesSearch}
                    requestSectorSearch={requestSectorSearch}
                  />
                </Card>
                {!company.reklamspärr
                  && (
                  <Card header="Beslutsfattare" className={s["card__executives"]} containerClassName={s["card__executives--container"]} noPadding>
                    <Executives
                      s={s}
                      authenticated={authenticated}
                      openModal={openModal}
                      unlockedFields={unlockedFields}
                      {...company}
                    />
                  </Card>
                  )}
                <Card header="Ekonomisk basdata &amp; Branschjämförelser" className={s["card__economy"]} noPadding>
                  <ChartsContainer s={s} company={company} />
                </Card>
                {company.tillväxtfaktor !== null ? (
                  <Card header="Tillväxtfaktor" noPadding className={s["card__growth"]}>
                    <GrowthExpanded s={s} {...company} />
                  </Card>
                ) : (
                  <div />
                )}
                <Card header="Kreditupplysning" className={s["card_credit"]}>
                  <Credit s={s} {...company} />
                </Card>
                <CompanyFooter />
              </Content>
            </Grid>
          </>
        ))}
      </MediaQuery>
    </>
  );
};

export default Company;
