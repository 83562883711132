import React from "react";
import { Modal, Button } from "antd";
import { Terms } from "../../..";
import "antd/es/modal/style/index.css";
import s from "./TOSModal.module.sass";

class TOSModal extends React.Component {
  state = {
    loading: false,
    visible: false,
  }

  showModal = () => {
    this.setState({
      visible: true,
    });
  }

  handleOk = () => {
    this.setState({ visible: false });
  }

  handleCancel = () => {
    this.setState({ visible: false });
  }

  render() {
    const { visible, loading } = this.state;
    return (
      <div className={s["tos-modal__text"]}>
        Jag accepterar MISE:s
        {" "}
        <button type="button" className={s["tos-modal__onclick"]} onClick={this.showModal}>köpvilkor</button>
        {" "}
        i samband med att jag köper en utökad pott.
        <Modal
          visible={visible}
          title={<strong>Vilkor</strong>}
          onOk={this.handleOk}
          onCancel={this.handleCancel}
          footer={[
            <Button key="submit" className="btn btn--primary" loading={loading} onClick={this.handleOk}>
                Tillbaka
            </Button>,
          ]}
        >
          <Terms />
        </Modal>
      </div>
    );
  }
}

export default TOSModal;
