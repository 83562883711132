import axios from "./api/axios";
import history from "./api/history";
import { actionCreators as modalActionCreators } from "./Modal";
import message from "../components/shared/Message/message";
import { alertMessages } from "../helpers/constants";

const FETCH_MYLIST = "FETCH_MYLIST";
const FETCH_MYLIST_PENDING = "FETCH_MYLIST_PENDING";
const FETCH_MYLIST_REJECTED = "FETCH_MYLIST_REJECTED";
const FETCH_MYLIST_FULFILLED = "FETCH_MYLIST_FULFILLED";

const CREATE_LIST = "CREATE_LIST";
const CREATE_LIST_PENDING = "CREATE_LIST_PENDING";
const CREATE_LIST_REJECTED = "CREATE_LIST_REJECTED";
const CREATE_LIST_FULFILLED = "CREATE_LIST_FULFILLED";

const RENAME_LIST = "RENAME_LIST";
const RENAME_LIST_PENDING = "RENAME_LIST_PENDING";
const RENAME_LIST_REJECTED = "RENAME_LIST_REJECTED";
const RENAME_LIST_FULFILLED = "RENAME_LIST_FULFILLED";

const DELETE_LIST = "DELETE_LIST";
const DELETE_LIST_PENDING = "DELETE_LIST_PENDING";
const DELETE_LIST_REJECTED = "DELETE_LIST_REJECTED";
const DELETE_LIST_FULFILLED = "DELETE_LIST_FULFILLED";

const FETCH_SINGLE_LIST = "FETCH_SINGLE_LIST";
const FETCH_SINGLE_LIST_PENDING = "FETCH_SINGLE_LIST_PENDING";
const FETCH_SINGLE_LIST_REJECTED = "FETCH_SINGLE_LIST_REJECTED";
const FETCH_SINGLE_LIST_FULFILLED = "FETCH_SINGLE_LIST_FULFILLED";

const ADD_SINGLE_LIST_ITEM = "ADD_SINGLE_LIST_ITEM";
const ADD_SINGLE_LIST_ITEM_PENDING = "ADD_SINGLE_LIST_ITEM_PENDING";
const ADD_SINGLE_LIST_ITEM_REJECTED = "ADD_SINGLE_LIST_ITEM_REJECTED";
const ADD_SINGLE_LIST_ITEM_FULFILLED = "ADD_SINGLE_LIST_ITEM_FULFILLED";

const DELETE_SINGLE_LIST_ITEM = "DELETE_SINGLE_LIST_ITEM";
const DELETE_SINGLE_LIST_ITEM_PENDING = "DELETE_SINGLE_LIST_ITEM_PENDING";
const DELETE_SINGLE_LIST_ITEM_REJECTED = "DELETE_SINGLE_LIST_ITEM_REJECTED";
const DELETE_SINGLE_LIST_ITEM_FULFILLED = "DELETE_SINGLE_LIST_ITEM_FULFILLED";

const SELECT_COMPANY = "SELECT_COMPANY";
const CLEAR_SELECTED_COMPANY = "CLEAR_SELECTED_COMPANY";
const CLEAR_MYLIST_DATA = "CLEAR_MYLIST_DATA";

const initialState = { data: [], isLoading: false };

export const actionCreators = {
  requestMyLists: () => async (dispatch, getState) => {
    const { diamonds_user_id } = getState().user.clientInfo["https://sökaföretag.se/app_metadata"] || {};
    const url = `/lists?diamondsUserId=${diamonds_user_id}`;

    dispatch({
      type: FETCH_MYLIST,
      payload: {
        promise: axios.get(url),
      },
    });
  },
  createList: (name, { closeModal = true } = {}) => async (dispatch, getState) => {
    const { diamonds_user_id } = getState().user.clientInfo["https://sökaföretag.se/app_metadata"] || {};
    const url = "/lists";
    const body = { name, diamondsUserId: diamonds_user_id };

    const { response } = await dispatch({
      type: CREATE_LIST,
      payload: {
        promise: axios.post(url, body),
      },
    });

    if (response && response.status !== 200) {
      message.error(response.data || alertMessages.NO_MESSAGE_FALLBACK, 3);
    } else {
      message.success(alertMessages.LIST_CREATED, 3);
      dispatch(actionCreators.requestMyLists());

      if (closeModal) {
        dispatch(modalActionCreators.closeModal());
      }
    }
  },
  renameList: (listId, name) => async (dispatch, getState) => {
    const url = `/lists/${listId}`;
    const body = { name };

    const { response } = await dispatch({
      type: RENAME_LIST,
      payload: {
        promise: axios.put(url, body),
      },
    });

    if (response && response.status !== 200) {
      message.error(response.data || alertMessages.NO_MESSAGE_FALLBACK, 3);
    } else {
      message.success(alertMessages.LIST_RENAMED, 3);

      dispatch(modalActionCreators.closeModal());
      dispatch(actionCreators.requestMyLists());
    }
  },
  deleteList: (listId) => async (dispatch, getState) => {
    const url = `/lists/${listId}`;

    const { response } = await dispatch({
      type: DELETE_LIST,
      payload: {
        promise: axios.delete(url),
      },
    });

    if (response && response.status !== 200) {
      message.error(response.data || alertMessages.NO_MESSAGE_FALLBACK, 3);
    } else {
      message.success(alertMessages.LIST_DELETED, 3);

      dispatch(modalActionCreators.closeModal());
      dispatch(actionCreators.requestMyLists());
      dispatch(actionCreators.clearSelectedCompany());
      history.push("/mylists");
    }
  },
  clearMyListData: () => ({ type: CLEAR_MYLIST_DATA }),
  requestSingleList: (listId, from = 0, to = 20, infinite = false) => async (dispatch, getState) => {
    const url = `/lists/${listId}?from=${from}&to=${to}`;
    dispatch({
      type: FETCH_SINGLE_LIST,
      payload: {
        promise: axios.get(url),
        data: { from, to, infinite },
      },
    });
  },
  addSingleListItem: (listId, miseId, name) => async (dispatch, getState) => {
    const url = `/list/${listId}`;
    const body = { id: miseId, name };

    const { response } = await dispatch({
      type: ADD_SINGLE_LIST_ITEM,
      payload: {
        promise: axios.post(url, body),
      },
    });

    if (response && response.status !== 200) {
      message.error(response.data || alertMessages.NO_MESSAGE_FALLBACK, 3);
    } else {
      message.success(alertMessages.COMPANY_ADDED, 3);

      dispatch(modalActionCreators.closeModal());
      dispatch(actionCreators.requestSingleList(listId));
      dispatch(actionCreators.requestMyLists());
    }
  },
  deleteSingleListItem: (listId, miseId) => async (dispatch, getState) => {
    const url = `/lists/${listId}/item/${miseId}`;

    const { response } = await dispatch({
      type: DELETE_SINGLE_LIST_ITEM,
      payload: {
        promise: axios.delete(url),
      },
    });

    if (response && response.status !== 200) {
      message.error(response.data || alertMessages.NO_MESSAGE_FALLBACK, 3);
    } else {
      message.success(alertMessages.COMPANY_REMOVED, 3);

      dispatch(modalActionCreators.closeModal());
      dispatch(actionCreators.requestSingleList(listId));
      dispatch(actionCreators.requestMyLists());
    }
  },
  selectCompany: (miseId) => async (dispatch, getState) => {
    dispatch({
      type: SELECT_COMPANY,
      payload: miseId,
    });
  },
  clearSelectedCompany: () => async (dispatch, getState) => {
    dispatch({ type: CLEAR_SELECTED_COMPANY });
  },
};

export const reducer = (state = initialState, action) => {
  if (action.type === FETCH_MYLIST_PENDING) {
    return { ...state, isLoading: true };
  }

  if (action.type === FETCH_MYLIST_REJECTED) {
    return {
      ...state,
      isLoading: false,
      errorMessage: action.payload.message,
      errorCode: action.payload.response.status,
    };
  }

  if (action.type === FETCH_MYLIST_FULFILLED) {
    return {
      ...state,
      data: action.payload.data || [],
      isLoading: false,
    };
  }


  if ([CREATE_LIST_PENDING, RENAME_LIST_PENDING, DELETE_LIST_PENDING].includes(action.type)) {
    return { ...state, isLoading: true };
  }

  if ([CREATE_LIST_REJECTED, RENAME_LIST_REJECTED, DELETE_LIST_REJECTED].includes(action.type)) {
    return {
      ...state,
      isLoading: false,
      errorMessage: action.payload.message,
      errorCode: action.payload.response.status,
    };
  }

  if ([CREATE_LIST_FULFILLED, RENAME_LIST_FULFILLED, DELETE_LIST_FULFILLED].includes(action.type)) {
    return { ...state, isLoading: false };
  }

  if (action.type === CLEAR_MYLIST_DATA) {
    return initialState;
  }

  return state;
};

const initialSingleState = {
  data: { träfflista: [] },
  träfflista: [],
  selectedCompanyId: "",
  isLoading: false,
  page: 0,
  from: 1,
  to: 20,
  limit: 20,
  itemCount: 0,
  searchId: 1,
};

export const singleListReducer = (state = initialSingleState, action) => {
  if (action.type === FETCH_SINGLE_LIST_PENDING) {
    return {
      ...state,
      isLoading: true,
      from: action.payload.from,
      to: action.payload.to,
      infinite: action.payload.infinite,
    };
  }

  if (action.type === FETCH_SINGLE_LIST_REJECTED) {
    return {
      ...state,
      isLoading: false,
      errorMessage: action.payload.message,
      errorCode: action.payload.response.status,
    };
  }

  if (action.type === FETCH_SINGLE_LIST_FULFILLED) {
    if (!action.payload.data.träfflista) return state;

    return {
      ...state,
      isLoading: false,
      from: action.payload.data.intervallFrån,
      to: action.payload.data.intervallTill >= 20 ? action.payload.data.intervallTill : 20,
      itemCount: action.payload.data.antalTräff,
      page: state.infinite ? state.from === 0 ? 1 : state.page + 1 : state.page,
      träfflista: state.infinite && state.from !== 0
        ? [...state.träfflista, ...action.payload.data.träfflista]
        : action.payload.data.träfflista,
      data: action.payload.data,
    };
  }

  if (action.type === ADD_SINGLE_LIST_ITEM_PENDING) {
    return {
      ...state,
      isLoading: true,
    };
  }

  if (action.type === ADD_SINGLE_LIST_ITEM_REJECTED) {
    return {
      ...state,
      isLoading: false,
      errorMessage: action.payload.message,
      errorCode: action.payload.response.status,
    };
  }

  if (action.type === ADD_SINGLE_LIST_ITEM_FULFILLED) {
    return {
      ...state,
      isLoading: false,
    };
  }

  if (action.type === DELETE_SINGLE_LIST_ITEM_PENDING) {
    return {
      ...state,
      isLoading: true,
    };
  }

  if (action.type === DELETE_SINGLE_LIST_ITEM_REJECTED) {
    return {
      ...state,
      isLoading: false,
      errorMessage: action.payload.message,
      errorCode: action.payload.response.status,
    };
  }

  if (action.type === DELETE_SINGLE_LIST_ITEM_FULFILLED) {
    return {
      ...state,
      isLoading: false,
      data: {
        ...state.data,
        träfflista: state.data.träfflista.filter((company) => company.miseId !== action.payload.id),
      },
    };
  }

  if (action.type === SELECT_COMPANY) {
    return {
      ...state,
      selectedCompanyId: action.payload,
    };
  }

  if (action.type === CLEAR_SELECTED_COMPANY) {
    return {
      ...state,
      selectedCompanyId: initialSingleState.selectedCompanyId,
    };
  }

  return state;
};
