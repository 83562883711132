import React from "react";
import { Field, FormSection } from "redux-form";
import horizontalFormInput from "./horizontalFormInput";
import s from "../CompanyDataModal.module.sass";

const CompanyInfo = () => (
  <>
    <FormSection name="companyInfo">
      <Field
        component={horizontalFormInput}
        className={s["form-control"]}
        name="invoiceCompanyName"
        label="Företagsnamn"
        placeholder="Företagsnamn..."
      />
      <strong className={s.subtitle}>Faktureringsuppgifter</strong>
      <Field
        component={horizontalFormInput}
        className={s["form-control"]}
        name="invoiceAddress"
        label="Faktureringsadress"
        placeholder="Faktureringsadress..."
      />
      <Field
        component={horizontalFormInput}
        className={s["form-control"]}
        name="invoiceCOAddress"
        label="C/O adress"
        placeholder="C/O adress..."
      />
      <Field
        component={horizontalFormInput}
        className={s["form-control"]}
        name="invoiceZip"
        label="Postnummer..."
        placeholder="Postnummer"
      />
      <Field
        component={horizontalFormInput}
        className={s["form-control"]}
        name="invoiceCity"
        label="Ort"
        placeholder="Ort..."
      />
      <Field
        component={horizontalFormInput}
        className={s["form-control"]}
        name="invoiceAttention"
        label="Faktura attention"
        placeholder="Faktura attention..."
      />
      <Field
        component={horizontalFormInput}
        className={s["form-control"]}
        name="invoiceEmail"
        label="E-post faktura"
        placeholder="E-post faktura..."
      />
      <Field
        component={horizontalFormInput}
        className={s["form-control"]}
        name="phone"
        label="Telefon"
        placeholder="Telefon..."
      />
    </FormSection>
  </>
);

export default CompanyInfo;
