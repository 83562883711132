import React from "react";
import s from "./DetailsMissing.module.sass";
import Button from "../../../../../shared/Button";
import { modalNames } from "../../../../../../helpers/constants";

const DetailsMissing = ({ show, openModal }) => {
  if (show) {
    return (
      <>
        <span className={s["missing-title"]}>
          Saknas
        </span>
        <Button type="button" primary onClick={() => openModal(modalNames.COMPANY_INFO)}>Lägg till faktureringsuppgifter</Button>
      </>
    );
  }
  return null;
};

export default DetailsMissing;
