import { reduxForm } from "redux-form";
import { connect } from "react-redux";
import { compose } from "recompose";
import { actionCreators as modalActionCreators } from "../../../store/Modal";
import { validate, warn } from "./modules/validateForm";
import CompanyDataModal from "./CompanyDataModal";

const mapStateToProps = (state) => ({
  initialValues: {
    companyInfo: {
      orgno: state.user.orgno,
      invoiceCompanyName: state.user.invoiceCompanyName,
      invoiceAddress: state.user.invoiceAddress,
      invoiceZip: state.user.invoiceZip,
      invoiceCity: state.user.invoiceCity,
      phone: state.user.phone || (state.user.clientInfo["https://sökaföretag.se/app_metadata"] || {}).phone,
      invoiceEmail: state.user.invoiceEmail,
      invoiceAttention: state.user.invoiceAttention,
      invoiceCOAddress: state.user.invoiceCOAddress,
    },
  },
  isLoading: state.user.isLoading,
});

const mapActionCreators = {
  ...modalActionCreators,
};

const withRedux = connect(
  mapStateToProps,
  mapActionCreators,
);

const withReduxForm = reduxForm({
  form: "updateCompanyData",
  destroyOnUnmount: false,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
  validate,
  warn,
});

export default compose(
  withRedux,
  withReduxForm,
)(CompanyDataModal);
