import React from "react";
import { MdKeyboardArrowRight } from "react-icons/md";

const ListValue = ({
  value, classNames, withArrow, HoverIcon, s,
}) => (
  <span className={classNames}>
    {value}
    {HoverIcon && <HoverIcon className={s["hover-icon"]} />}
    {withArrow && <MdKeyboardArrowRight />}
  </span>
);

export default ListValue;
