import React, { useCallback } from "react";
import Modal from "react-modal";
import cn from "classnames";
import { modalNames } from "../../../helpers/constants";
import {
  LoginModal,
  RefillPotModal,
  SelectListModal,
  ConfirmPurchaseModal,
  CookiesModal,
  CompanyDataModal,
  CreateListModal,
  RenameListModal,
  DeleteListModal,
  AddDescriptionModal,
} from "../../../components";
import s from "./ModalProvider.module.sass";

const ModalProvider = ({
  children,
  modalIsOpen,
  modalName,
  modalClassname,
  closeModal,
  authenticated,
  isTestingEnvironment,
}) => {
  const renderModal = useCallback(() => {
    switch (modalName) {
      case modalNames.LOGIN_SEE_CONTACTS:
        return <LoginModal type={modalNames.LOGIN_SEE_CONTACTS} />;
      case modalNames.REFILL_POT:
        return <RefillPotModal />;
      case modalNames.SELECT_LIST:
        return authenticated ? <SelectListModal /> : <LoginModal type={modalNames.LOGIN_ADD_TO_LIST} />;
      case modalNames.CONFIRM_PURCHASE:
        return <ConfirmPurchaseModal />;
      case modalNames.COMPANY_INFO:
        return <CompanyDataModal />;
      case modalNames.COOKIES:
        return <CookiesModal />;
      case modalNames.CREATE_LIST:
        return <CreateListModal />;
      case modalNames.DELETE_LIST:
        return <DeleteListModal />;
      case modalNames.RENAME_LIST:
        return <RenameListModal />;
      case modalNames.ADD_DESCRIPTION:
        return <AddDescriptionModal />;
      default:
        return null;
    }
  }, [modalName, authenticated]);

  return (
    <>
      {children}

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        ariaHideApp={!isTestingEnvironment}
        closeTimeoutMS={150}
        className={cn(s["modal-wrapper"], { [s[modalClassname]]: modalClassname })}
        overlayClassName={s["modal-overlay"]}
      >
        {renderModal()}
      </Modal>
    </>
  );
};

export default ModalProvider;
