import React from "react";
import cn from "classnames";
import { FaCheck } from "react-icons/fa";
import s from "./PriceList.module.sass";
import { Button, Ribbon } from "../shared";

class PriceList extends React.Component {
  render() {
    const { showModal, signupUser, authenticated} = this.props;
    return (
      <>
      <div className={s.wrapper}>
        <div className={s.inner}>
          <h1 className={s["page-title"]}>Få tillgång till kontaktuppgifter till beslutsfattare på Sveriges 1,2 miljoner företag</h1>
          <div className={s.pricing}>
            <div className={s["pricing__box"]}>
              <Ribbon text="BÖRJA GRATIS" position="top-right" />
              <div className={s["pricing-header"]}>
                <span className={s["pricing-header__title"]}>Gratiskonto</span>
                <div className={s["pricing-header__price"]}>Fritt</div>
                { !authenticated ?
                  <Button primary big onClick={signupUser}>
                    Skapa konto
                  </Button>
                  :
                  <></>
                  }
              </div>
              <div className={s["pricing-body"]}>
                <ul className={s.benefits}>
                  <li className={s.benefits__item}>
                    <FaCheck className={s.checkMark} />
                    <span className={s.benefits__text}><strong>Gratis tillgång till namn på beslutsfattare</strong></span>
                  </li>
                  <li className={s.benefits__item}>
                    <FaCheck className={s.checkMark} />
                    <span className={s.benefits__text}><strong>Lås upp 5 gratis uppgifter per dag</strong></span>
                  </li>
                  <li className={cn(s.benefits__item, s.freeTier)}>
                    <hr className={s.separator} />
                  </li>
                  <li className={s.benefits__item}>
                    <span className={s.benefits__text}><strong>Information att låsa upp:</strong></span>
                  </li>
                  <li className={s.benefits__item}>
                    <FaCheck className={s.checkMark} />
                    <span className={s.benefits__text}>E-postadress till de högsta beslutsfattarna</span>
                  </li>
                  <li className={s.benefits__item}>
                    <FaCheck className={s.checkMark} />
                    <span className={s.benefits__text}>Direktnummer till de högsta beslutsfattarna</span>
                  </li>
                  <li className={s.benefits__item}>
                    <FaCheck className={s.checkMark} />
                    <span className={s.benefits__text}>Infolåda till svenska företag</span>
                  </li>
                </ul>
              </div>
            </div>
  
            <div className={s["pricing__box"]}>
              <div className={s["pricing-header"]}>
                <span className={s["pricing-header__title"]}>300 potten</span>
                <div className={s["pricing-header__price"]}>
                  <span>195 kr</span>
                  <small className={s["pricing-header__details"]}> / 300 uppgifter</small>
                </div>
                { !authenticated ?
                  <Button primary big onClick={signupUser}>
                    Skapa konto
                  </Button>
                  :
                  <></>
                  }
              </div>
              <div className={s["pricing-body"]}>
                <ul className={s.benefits}>
                  <li className={s.benefits__item}>
                    <FaCheck className={s.checkMark} />
                    <span className={s.benefits__text}><strong>Gratis tillgång till namn på beslutsfattare</strong></span>
                  </li>
                  <li className={s.benefits__item}>
                    <FaCheck className={s.checkMark} />
                    <span className={s.benefits__text}><strong>Betala bara efter användning.</strong></span>
                  </li>
                  <li className={cn(s.benefits__item, s.freeTier)}>
                    <hr className={s.separator} />
                  </li>
                  <li className={s.benefits__item}>
                    <span className={s.benefits__text}><strong>Information att låsa upp:</strong></span>
                  </li>
                  <li className={s.benefits__item}>
                    <FaCheck className={s.checkMark} />
                    <span className={s.benefits__text}>E-postadress till de högsta beslutsfattarna</span>
                  </li>
                  <li className={s.benefits__item}>
                    <FaCheck className={s.checkMark} />
                    <span className={s.benefits__text}>Direktnummer till de högsta beslutsfattarna</span>
                  </li>
                  <li className={s.benefits__item}>
                    <FaCheck className={s.checkMark} />
                    <span className={s.benefits__text}>Infolåda till svenska företag</span>
                  </li>
                </ul>
              </div>
            </div>
  
            <div className={s["pricing__box"]}>
              <div className={s["pricing-header"]}>
                <span className={s["pricing-header__title"]}>500 potten</span>
                <div className={s["pricing-header__price"]}>
                  <span>295 kr</span>
                  <small className={s["pricing-header__details"]}> / 500 uppgifter</small>
                </div>
                { !authenticated ?
                  <Button primary big onClick={signupUser}>
                    Skapa konto
                  </Button>
                  :
                  <></>
                  }
              </div>
              <div className={s["pricing-body"]}>
                <ul className={s.benefits}>
                  <li className={s.benefits__item}>
                    <FaCheck className={s.checkMark} />
                    <span className={s.benefits__text}><strong>Gratis tillgång till namn på beslutsfattare</strong></span>
                  </li>
                  <li className={s.benefits__item}>
                    <FaCheck className={s.checkMark} />
                    <span className={s.benefits__text}><strong>Betala bara efter användning.</strong></span>
                  </li>
                  <li className={cn(s.benefits__item, s.freeTier)}>
                    <hr className={s.separator} />
                  </li>
                  <li className={s.benefits__item}>
                    <span className={s.benefits__text}><strong>Information att låsa upp:</strong></span>
                  </li>
                  <li className={s.benefits__item}>
                    <FaCheck className={s.checkMark} />
                    <span className={s.benefits__text}>E-postadress till de högsta beslutsfattarna</span>
                  </li>
                  <li className={s.benefits__item}>
                    <FaCheck className={s.checkMark} />
                    <span className={s.benefits__text}>Direktnummer till de högsta beslutsfattarna</span>
                  </li>
                  <li className={s.benefits__item}>
                    <FaCheck className={s.checkMark} />
                    <span className={s.benefits__text}>Infolåda till svenska företag</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <span className={s.tax}>Alla priser exkl. moms</span>
          <Button className={s["pricing-info__modal"]} onClick={showModal}>
            Vad räcker potten till?
          </Button>
        </div>
      </div>
      <div className={s["pricing-info__container"]}>
        <div className={s["pricing-info"]}>
          <h2 className={s["pricing-info__contact-header"]}>
            Behöver du större avtal med obegränsade uttag? Kontakta oss!
          </h2>
          <a
            href="http://www.marknadsinformation.se/kontakt"
            target="_blank"
            rel="noopener noreferrer"
            className={s["pricing-info--contact-button"]}
          >
            Kontakta oss för pris
          </a>
        </div>
      </div>
    </>
    );
  }
}

export default PriceList;
