import React from "react";
import cn from "classnames";
import s from "../AddDescriptionModal.module.sass";

const horizontalFormInput = ({
  input,
  className,
  id,
  label,
  type,
  placeholder,
  renderAs,
  rows,
  maxlength,
  helpText,
  disabled,
  meta: { touched, error, warning },
}) => (
  <>
      {renderAs === "textarea" ? (
        <textarea
          {...input}
          placeholder={placeholder}
          className={`${className} ${touched && error ? s["form-control--has-error"] : ""} ${
            warning && touched ? s["form-control--has-warning"] : ""
          } ${!error && !warning && touched ? s["form-control--has-success"] : ""}`}
          id={id}
          name={id}
          rows={rows}
          maxLength={maxlength}
        />
      ) : (
        <input
          {...input}
          placeholder={placeholder}
          type={type}
          className={`${className} ${touched && error ? s["form-control--has-error"] : ""} ${
            warning && touched ? s["form-control--has-warning"] : ""
          } ${!error && !warning && touched ? s["form-control--has-success"] : ""}`}
          id={id}
          name={id}
          disabled={disabled}
        />
      )}

      {touched && error ? (
        <span className={cn(s["help-text"], s["help-text--has-error"])}>{error}</span>
      ) : (
        helpText && <span className={s["help-text"]}>{helpText}</span>
      )}
      <label htmlFor={id} className={s.label}>
        {label}
      </label>
    </>
);

export default horizontalFormInput;
