import React from "react";
import { Route, Switch } from "react-router-dom";
import MediaQuery from "react-responsive";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import { ShowList, Lists, ListTutorial } from "./modules";
import { Grid, Sidebar, Content } from "../../layouts";
import SingleListContainer from "../../containers/SingleListContainer";
import s from "./MyLists.module.sass";

const MyLists = ({
  lists: { data, isLoading },
  openModal,
  deleteSingleListItem,
  addSingleListItem,
  location,
  history,
  companyData,
  unlockedFields,
  singleListData,
  selectedCompanyId,
  authenticated,
  requestSubsidiariesSearch,
  requestSectorSearch,
  signupUser,
  signinUser,
}) => {
  if (!authenticated) return <ListTutorial signupUser={signupUser} signinUser={signinUser} />;

  return (
    <MediaQuery query="(max-width: 768px)">
      {(matches) => (matches ? (
      /* MOBILE VIEW */
        <>
          <Lists listData={data} isLoading={isLoading} openModal={openModal} isMobile={matches} />
          <TransitionGroup>
            <CSSTransition
              key={location.key}
              classNames={{
                enter: s["slide-enter"],
                enterActive: s["slide-enter-active"],
                exit: s["slide-exit"],
                exitActive: s["slide-exit-active"],
              }}
              timeout={{ enter: 200, exit: 200 }}
            >
              <Switch location={location}>
                <Route exact path="/mylists/:name" component={SingleListContainer} />
              </Switch>
            </CSSTransition>
          </TransitionGroup>
        </>
      ) : (
      /* DESKTOP VIEW */
        <Grid>
          <Sidebar style={{ overflow: "hidden" }}>
            <Lists listData={data} isLoading={isLoading} openModal={openModal} />
            <TransitionGroup>
              <CSSTransition
                key={location.key}
                classNames={{
                  enter: s["slide-enter"],
                  enterActive: s["slide-enter-active"],
                  exit: s["slide-exit"],
                  exitActive: s["slide-exit-active"],
                }}
                timeout={{ enter: 200, exit: 200 }}
              >
                <Switch location={location}>
                  <Route exact path="/mylists/:id" component={SingleListContainer} />
                </Switch>
              </CSSTransition>
            </TransitionGroup>
          </Sidebar>
          <Content style={{ height: selectedCompanyId ? "inherit" : "100%" }}>
            <ShowList
              companyData={companyData}
              listData={data}
              singleListData={singleListData}
              unlockedFields={unlockedFields}
              selectedCompanyId={selectedCompanyId}
              deleteSingleListItem={deleteSingleListItem}
              addSingleListItem={addSingleListItem}
              openModal={openModal}
              authenticated={authenticated}
              history={history}
              requestSectorSearch={requestSectorSearch}
              requestSubsidiariesSearch={requestSubsidiariesSearch}
            />
          </Content>
        </Grid>
      ))}
    </MediaQuery>
  );
};

export default MyLists;
