import React, { Component } from "react";
import { Footer, Privacy } from "../../components";
import s from "./PrivacyContainer.module.sass";

class PrivacyContainer extends Component {
  componentDidMount() {
    this.props.displaySearchList(false);
    window.scrollTo({ top: 0, behavior: "smooth" });
  }

  render() {
    const { openModal } = this.props;

    return (
      <div className={s.container}>
        <div className={s.wrapper}>
          <Privacy />
        </div>

        <Footer openModal={openModal} />
      </div>
    );
  }
}

export default PrivacyContainer;
