import React, { Component } from "react";
import LoadingOverlay from "react-loading-overlay";
import { Helmet } from "react-helmet";
import { MyLists } from "../../components";
import s from "./MyListsPage.module.sass";

class MyListsPage extends Component {
  componentDidMount() {
    if (this.props.authenticated) {
      this.props.requestMyLists();
    }
    this.props.clearSelectedCompany();
    this.props.displaySearchList(false);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.route === "/mylists" && this.props.route !== prevProps.route) {
      this.props.resetCompanyData();
      this.props.clearSelectedCompany();
    }
  }

  render() {
    const {
      myListsData,
      singleListData,
      requestSingleList,
      companyData,
      selectedCompanyId,
      deleteSingleListItem,
      addSingleListItem,
      openModal,
      authenticated,
      history,
    } = this.props;

    return (
      <article className={s.wrapper}>
        <LoadingOverlay active={companyData.isLoading} className={s["overlay-wrapper"]} spinner>
          <MyLists
            lists={myListsData}
            companyData={companyData}
            singleListData={singleListData}
            unlockedFields={companyData.unlockedFields.data}
            requestSingleList={requestSingleList}
            deleteSingleListItem={deleteSingleListItem}
            addSingleListItem={addSingleListItem}
            openModal={openModal}
            selectedCompanyId={selectedCompanyId}
            authenticated={authenticated}
            history={history}
            {...this.props}
          />
        </LoadingOverlay>
        <Helmet>
          <title>SökaFöretag - Mina listor</title>
        </Helmet>
      </article>
    );
  }
}

export default MyListsPage;
