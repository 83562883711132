import { connect } from "react-redux";
import MyListsPage from "./MyListsPage";
import { actionCreators as myListsActionCreators } from "../../store/MyLists";
import { actionCreators as uiActionCreators } from "../../store/Ui";
import { actionCreators as searchActionCreators } from "../../store/Search";
import { actionCreators as modalActionCreators } from "../../store/Modal";
import { actionCreators as companyActionCreators } from "../../store/Company";
import { actionCreators as authActionCreators } from "../../store/Auth";
import permalink from "../../helpers/permalink";

const mapStateToProps = (state) => ({
  myListsData: {
    ...state.myListsData,
    data: state.myListsData.data.map((item) => ({
      ...item,
      label: item.name,
      href: `/mylists/${permalink(item.name)}`,
      hrefProps: { id: item.id },
      details: `${item.noOfCompanies} företag`,
    })),
  },
  singleListData: state.singleListData,
  companyData: state.companyData,
  ui: state.ui,
  selectedCompanyId: state.singleListData.selectedCompanyId,
  route: state.router.location.pathname,
  authenticated: state.auth.authenticated,
});

const mapActionCreators = {
  ...myListsActionCreators,
  ...uiActionCreators,
  ...searchActionCreators,
  ...modalActionCreators,
  ...companyActionCreators,
  ...authActionCreators,
};

const withRedux = connect(
  mapStateToProps,
  mapActionCreators,
);

export default withRedux(MyListsPage);
