import { formValueSelector } from "redux-form";
import axios from "./api/axios";
import message from "../components/shared/Message";
import { alertMessages, products } from "../helpers/constants";

const SET_LOADING = "SET_LOADING";
const SET_EMAIL = "SET_EMAIL";
const SET_ZIPCODE = "SET_ZIPCODE";

const initialState = {
  isLoading: false,
  email: "",
  zipcode: "",
};

export const actionCreators = {
  initiatePaymentIntent: () => async (dispatch, getState) => {
    const state = getState();
    const { diamonds_user_id } = state.user.clientInfo["https://sökaföretag.se/app_metadata"] || {};
    const selector = formValueSelector("potRefillForm");
    const amount = selector(state, "amountType");
    const product = products[amount];

    const url = `/payment/intent?product=${product}&currency=sek&diamondsUserId=${diamonds_user_id}&purchaseCount=${amount}`;

    dispatch(actionCreators.setLoading(true));

    try {
      const { data, status } = await axios.get(url);

      if (status !== 200) {
        message.error(data || alertMessages.NO_MESSAGE_FALLBACK, 3);
        return null;
      }

      return data.clientSecret;
    } catch (err) {
      message.error(err.response.data || alertMessages.NO_MESSAGE_FALLBACK, 3);
      return null;
    } finally {
      dispatch(actionCreators.setLoading(false));
    }
  },
  confirmCardPayment: ({
    stripe, card, clientSecret, email, zipcode,
  }) => async (dispatch, getState) => {
    let success;
    const { firstname, lastname } = getState().user;

    dispatch(actionCreators.setLoading(true));

    const result = await stripe.confirmCardPayment(clientSecret, {
      payment_method: {
        card,
        billing_details: {
          name: `${firstname} ${lastname}`,
          email,
          address: {
            postal_code: zipcode,
          },
        },
      },
      receipt_email: email,
      save_payment_method: false,
    });

    if (result.error) {
      message.error(result.error.message, 5);
      success = false;
    } else if (result.paymentIntent.status === "succeeded") {
      message.success(alertMessages.PAYMENT_SUCCESS, 10);
      success = true;
    }
    dispatch(actionCreators.setLoading(false));
    return success;
  },
  setLoading: (flag) => ({ type: SET_LOADING, payload: flag }),
  setEmail: (email) => ({ type: SET_EMAIL, payload: email }),
  setZipcode: (zipcode) => ({ type: SET_ZIPCODE, payload: zipcode }),
};

export const reducer = (state = initialState, action) => {
  if (action.type === SET_LOADING) {
    return {
      ...state,
      isLoading: action.payload,
    };
  }

  if (action.type === SET_EMAIL) {
    return {
      ...state,
      email: action.payload,
    };
  }

  if (action.type === SET_ZIPCODE) {
    return {
      ...state,
      zipcode: action.payload,
    };
  }

  return state;
};
