import React from "react";
import { MdAccountCircle } from "react-icons/md";
import cn from "classnames";
import { Collapse } from "antd";
import { Button } from "../../../shared";
import s from "./SidebarAccount.module.sass";

const { Panel } = Collapse;

const SidebarAccount = ({
  className,
  clientInfo,
  signupUser,
  signinUser,
  signoutUser,
  potBalance,
  authenticated,
  toggleMenu,
  fullAccessValidTo,
  fullAccess,
}) => {
  const { picture, given_name, family_name } = clientInfo;
  let validToDate = "";
  let expired = false;

  if (authenticated && fullAccess) {
    validToDate = new Intl.DateTimeFormat("se-SE", { dateStyle: "long" }).format(new Date(fullAccessValidTo));
    expired = new Date(fullAccessValidTo).getTime() < new Date().getTime();
  }

  return (
    <Collapse defaultActiveKey={["1"]} className={cn(s.container, className)}>
      <Panel
        key="1"
        showArrow={false}
        header={(
          <>
            {authenticated ? (
              <img src={picture} alt="avatar" className={s["account-profile__image"]} />
            ) : (
              <MdAccountCircle className={s["navigation__icon"]} />
            )}
            Mitt konto
          </>
        )}
      >
        {authenticated ? (
          <div>
            <div className={s["account-header"]}>
              <div className={s["account-header__profile"]}>
                <span className={s["account-profile__name"]}>
                  {given_name}
                  {" "}
                  {family_name}
                </span>
                {authenticated && fullAccess ? (
                  <div className={s["account-profile__full-access"]}>
                    <span className={s["account-profile__full-access--label"]}>Full åtkomst gäller t.o.m</span>
                    <span className={cn(s["account__valid-to"], { [s.expired]: expired })}>{validToDate}</span>
                  </div>
                ) : (
                  <div className={s["account-profile__balance"]}>
                    <span>
                    Kontobalans:
                      {potBalance}
                    </span>
                  </div>
                )}
                <div className={s["account-profile__actions"]}>
                  <Button white href="/potrefill" onClick={toggleMenu} additionalClass={s["account-profile__button"]}>
                    Fyll på
                  </Button>
                  <Button onClick={signoutUser} danger additionalClass={s["account-profile__button"]}>
                    Logga ut
                  </Button>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <>
            <Button success onClick={signupUser}>
              Skapa konto
            </Button>
            <Button additionalClass={s.white} onClick={signinUser}>
              Logga in
            </Button>
          </>
        )}
      </Panel>
    </Collapse>
  );
};

export default SidebarAccount;
