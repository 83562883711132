import React from "react";
import LoadingOverlay from "react-loading-overlay";
import { MdClose, MdAddCircleOutline } from "react-icons/md";
import { Button, List } from "../../shared";
import s from "./SelectListModal.module.sass";

const SelectListModal = ({
  myLists,
  companyId,
  companyName,
  addSingleListItem,
  createList,
  isLoading,
  closeModal,
  createNewListIsOpen,
  toggleCreateNewList,
}) => {
  const ref = React.createRef();
  const onCreateList = () => {
    const name = ref.current && ref.current.value;
    createList(name, { closeModal: false });
    toggleCreateNewList();
  };
  const onEnter = ({ key }) => {
    if (key === "Enter") onCreateList();
  };

  const Header = () => (
    <div className={s.header}>
      <span className={s["header__title"]}>Spara företag i lista</span>
      <MdClose onClick={closeModal} className={s["header__icon"]} />
    </div>
  );

  const CreateList = () => (
    <div className={s.header}>
      <span>MINA LISTOR</span>
      <Button additionalClass={s["header__button--create"]} onClick={toggleCreateNewList} primary shadow>
        Skapa ny lista
      </Button>
    </div>
  );

  const InsertName = () => (
    <div className={s.header}>
      <div className={s["input-bar"]}>
        <input
          type="text"
          className={s["input-bar__input"]}
          placeholder="Namn på lista"
          onKeyPress={onEnter}
          ref={ref}
          autoFocus
        />
        <Button additionalClass={s["header__button--create"]} onClick={onCreateList} success shadow>
          Skapa
        </Button>
      </div>
    </div>
  );

  const data = myLists.map(({ id, name }) => ({
    label: name,
    onClick: () => addSingleListItem(id, companyId, companyName),
    HoverIcon: MdAddCircleOutline,
  }));

  return (
    <LoadingOverlay active={isLoading} className={s["overlay-wrapper"]} spinner>
      <div className={s.container}>
        <Header />
        {createNewListIsOpen ? <InsertName /> : <CreateList />}
        <List
          data={data}
          withPointer
          listItemFallback={{ label: "Här var det tomt!" }}
          additionalClassnames={s["scrollable-list"]}
        />
      </div>
    </LoadingOverlay>
  );
};

export default SelectListModal;
