import React from "react";
import LoadingOverlay from "react-loading-overlay";
import { MdClose } from "react-icons/md";
import { Button } from "../../shared";
import s from "./ConfirmPurchaseModal.module.sass";

const ConfirmPurchaseModal = ({
  miseId, fieldName, befattning, execName, isLoading, closeModal, unlockField,
}) => {
  const onConfirmedPurchase = () => {
    unlockField(miseId, fieldName, befattning);
  };

  const Header = () => (
    <div className={s.header}>
      <span className={s["header__title"]}>
        Visa
        {" "}
        {fieldName}
      </span>
      <MdClose onClick={closeModal} className={s["header__icon"]} />
    </div>
  );

  const Content = () => (
    <div className={s.content}>
      <p>
        Fält:
        {" "}
        <strong>{fieldName}</strong>
        {" "}
      </p>
      <p>
        Beslutsfattare:
        {" "}
        {execName}
        {" "}
        {befattning ? `[${befattning}]` : ""}
        {" "}
      </p>
    </div>
  );

  const Buttons = () => (
    <div className={s["header__button--wrapper"]}>
      <Button additionalClass={s["header__button"]} onClick={closeModal} primary shadow>
        Avbryt
      </Button>
      <Button additionalClass={s["header__button"]} onClick={onConfirmedPurchase} success shadow>
        Lås upp
      </Button>
    </div>
  );

  return (
    <LoadingOverlay active={isLoading} className={s["overlay-wrapper"]} spinner>
      <div className={s.container}>
        <Header />
        <Content />
        <Buttons />
      </div>
    </LoadingOverlay>
  );
};

export default ConfirmPurchaseModal;
