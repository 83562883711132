import React from "react";
import { Button } from "../../../shared";
import s from "./Credit.module.sass";

const SERVER_NAME = "upplysningar.syna.se";
const PARTNER_ID = "7700119";

const Credit = ({ orgnr }) => {
  const buttonLink = () => {
    window.open(`https://${SERVER_NAME}/Kreditupplysning/Kop/${orgnr}?partnerID=${PARTNER_ID}&selectedProduct=`, "_blank");
  };
  return (
    <div className={s["credit__container"]}>
      <div className={s["credit__info"]}>
        <div className={s["syna--header"]}>Kreditupplysning från Syna</div>
        <div className={s["syna--text"]}>Med hjälp av Synas kreditupplysningar kan ni göra säkrare affärer.</div>
      </div>
      <div className={s["credit__button"]}>
        <Button primary shadow additionalClass={s["syna--button"]} onClick={buttonLink}>Köp kreditupplysning</Button>
      </div>
    </div>
  );
};


export default Credit;
