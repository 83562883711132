import { connect } from "react-redux";
import { Callback } from "mise-react-redux-auth0";
import { Auth0, actionCreators } from "../../store/Auth";

const handleAuthenticationCallback = (props) => (err, result) => {
  if (err || !result) {

  } else if (result.returnUrl) {
    props.history.push(result.returnUrl);
  } else {
    props.history.push("/");
  }
};

const mapStateToProps = (state) => {
  const { error } = state.auth;
  return {
    error,
    Auth: Auth0,
    handleAuthenticationCallback,
  };
};

const mapActionCreators = {
  ...actionCreators,
};

const withRedux = connect(
  mapStateToProps,
  mapActionCreators,
);

export default withRedux(Callback);
