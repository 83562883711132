import React, { Component } from "react";
import Helmet from "react-helmet";
import { ReactComponent as EmailIcon } from "../../assets/icons/email-icon.svg";
import { Button, message } from "../../components/shared";
import { alertMessages } from "../../helpers/constants";
import s from "./VerifyPage.module.sass";

class VerifyPage extends Component {
  componentDidMount() {
    if (this.props.emailVerified) {
      this.props.history.push("/");
    } else {
      this.props.refreshSession();
    }
  }

  async componentDidUpdate(prevProps) {
    if (this.props.emailVerified && !prevProps.emailVerified) {
      await message.success(alertMessages.EMAIL_VERIFIED, 3);
      this.props.history.push("/");
    }
  }

  render() {
    const {
      email, refreshSession, emailVerified, isLoading,
    } = this.props;

    return (
      <div className={s.wrapper}>
        <div className={s.contentWrapper}>
          <EmailIcon className={s.icon} />
          <h1 className={s.title}>Verifiera konto</h1>
          <p className={s.description}>
            Nästan klart, öppna din inkorg (
            {email}
            ) för att verifiera ditt konto.
          </p>
          <Button
            onClick={refreshSession}
            additionalClass={s.button}
            disabled={emailVerified || isLoading}
            success
          >
            {emailVerified ? "Konto verifierad!" : "Redan verifierad? Klicka här"}
          </Button>
        </div>
        <Helmet>
          <title>SökaFöretag - Verifiera konto</title>
        </Helmet>
      </div>
    );
  }
}

export default VerifyPage;
