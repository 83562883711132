import React from "react";
import { MdMenu, MdClose } from "react-icons/md";
import s from "./ToggleButton.module.sass";

const ToggleButton = ({ toggleMenu, open }) => (
  <button onClick={toggleMenu} className={s["toggle-button"]}>
    {open ? <MdClose /> : <MdMenu />}
  </button>
);

export default ToggleButton;
