import React from "react";
import MediaQuery from "react-responsive";
import { Button } from "../../..";
import AccountDropdown from "../AccountDropdown";
import NavDropdown from "../NavDropdown";
import { MdList, MdWork, MdAttachMoney } from "react-icons/md";

const NavMenu = ({
  s, authenticated, signinUser, signupUser, signoutUser, clientInfo, potBalance, history, fullAccess, fullAccessValidTo
}) => (
  <div className={s["nav-menu"]}>
    {!authenticated ? (
      <>
        <MediaQuery query={`(max-width: 1439px)`}>
          <NavDropdown pricing />
        </MediaQuery>
        <MediaQuery query={`(min-width: 1440px)`}>
          <Button href="/pricing" primary Icon={MdAttachMoney} additionalClass={s["my-lists-button"]}>Priser</Button>
          <Button href="/sectors" primary Icon={MdWork} additionalClass={s["my-lists-button"]}>Branscher</Button>
          <Button href="/mylists" primary Icon={MdList} additionalClass={s["my-lists-button"]}>Mina listor</Button>
        </MediaQuery>
        <Button success onClick={signupUser}>Skapa konto</Button>
        <Button additionalClass={s.white} onClick={signinUser}>Logga in</Button>
      </>
    ) : (
      <>
        <MediaQuery query={`(max-width: 1439px)`}>
          <NavDropdown />
        </MediaQuery>
        <MediaQuery query={`(min-width: 1440px)`}>
          <Button href="/sectors" primary Icon={MdWork} additionalClass={s["my-lists-button"]}>Branscher</Button>
          <Button href="/mylists" primary Icon={MdList} additionalClass={s["my-lists-button"]}>Mina listor</Button>
        </MediaQuery>
        <AccountDropdown authenticated={authenticated} signoutUser={signoutUser} clientInfo={clientInfo} potBalance={potBalance} history={history} fullAccess={fullAccess} fullAccessValidTo={fullAccessValidTo} />
      </>
    )}
  </div>
);

export default NavMenu;
