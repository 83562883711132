import AuthConstructor, { getAuthActions } from "mise-react-redux-auth0";
import history from "./api/history";

export const Auth0 = new AuthConstructor({
  domain: process.env.REACT_APP_AUTH0_DOMAIN,
  clientID: process.env.REACT_APP_AUTH0_CLIENTID,
  audience: process.env.REACT_APP_AUTH0_APIAUDIENCE,
  redirectUri: process.env.REACT_APP_AUTH0_CALLBACKURL,
  returnTo: process.env.REACT_APP_SIGNOUT_RETURNURL,
  scope: "openid profile email update:password",
  responseType: "token id_token",
});

const enhancedActionCreators = getAuthActions(Auth0);

export const actionCreators = {
  ...enhancedActionCreators,
  signinUser: () => (dispatch, getState) => {
    const { query } = getState().searchData;
    if (query) {
      history.replace(`${history.location.pathname}?query=${query}`);
    }

    dispatch(enhancedActionCreators.signinUser());
  },
};
