import { connect } from "react-redux";
import { actionCreators as modalActionCreators } from "../../../store/Modal";
import RefillPotModal from "./RefillPotModal";

const mapStateToProps = (state) => ({
});

const mapActionCreators = {
  ...modalActionCreators,
};

const withRedux = connect(
  mapStateToProps,
  mapActionCreators,
);

export default withRedux(RefillPotModal);
