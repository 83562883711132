const transformDateUntil = ({
  synaPeriodTill, synaPeriodTill2, synaPeriodTill3, synaPeriodTill4, synaPeriodTill5,
}) => {
  let month = 12;
  let year = new Date().getFullYear() - 2;

  if (synaPeriodTill) {
    const [synaYear, synaMonth] = synaPeriodTill.split("-");
    month = synaMonth;
    year = parseInt(synaYear, 10);
  }

  let year2 = year - 1;
  let year3 = year - 2;
  let year4 = year - 3;
  let year5 = year - 4;

  if (synaPeriodTill2) {
    const [synaYear2] = synaPeriodTill2.split("-");
    year2 = parseInt(synaYear2, 10);
  }

  if (synaPeriodTill3) {
    const [synaYear3] = synaPeriodTill3.split("-");
    year3 = parseInt(synaYear3, 10);
  }

  if (synaPeriodTill4) {
    const [synaYear4] = synaPeriodTill4.split("-");
    year4 = parseInt(synaYear4, 10);
  }

  if (synaPeriodTill5) {
    const [synaYear5] = synaPeriodTill5.split("-");
    year5 = parseInt(synaYear5, 10);
  }

  return [
    `${year}-${month}`,
    `${year2}-${month}`,
    `${year3}-${month}`,
    `${year4}-${month}`,
    `${year5}-${month}`,
  ];
};

export default transformDateUntil;
