import React from "react";
import s from "./ListHeader.module.sass";

const ListHeader = ({ children }) => (
  <div className={s["list-header"]}>
    {children}
  </div>
);

export default ListHeader;
