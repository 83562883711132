import React from "react";
import PropTypes from "prop-types";
import cn from "classnames";
import {
  Tabs, Tab, TabList, TabPanel,
} from "react-tabs";
import SwipeableViews from "react-swipeable-views";
import "react-tabs/style/react-tabs.css";
import s from "./Tabs.module.sass";

class TabsComponent extends React.Component {
  state = { tabIndex: 0 };

  componentDidMount() {
    if (this.props.defaultIndex) {
      this.setState({ tabIndex: this.props.defaultIndex });
    }
  }

  onTabChange = (tabIndex) => {
    this.setState({ tabIndex });
  }

  render() {
    const {
      options, defaultIndex, listStyle, className, ...rest
    } = this.props;
    const { tabIndex } = this.state;

    return (
      <Tabs selectedTabClassName="tabItem-selected" className={className} selectedIndex={tabIndex} onSelect={this.onTabChange} {...rest}>
        <TabList className={s["tab__list"]} style={listStyle}>
          {options.map(({ tabName }, idx) => {
            const isSelected = idx === tabIndex;
            return (
              <Tab className={cn(s["tab__item"], { [s["tab__item--selected"]]: isSelected })} key={idx}>
                {tabName}
              </Tab>
            );
          })}
        </TabList>

        <SwipeableViews index={tabIndex} onChangeIndex={this.onTabChange}>
          {options.map(({ tabContent }, idx) => (
            <TabPanel key={idx}>{tabContent}</TabPanel>
          ))}
        </SwipeableViews>
      </Tabs>
    );
  }
}

TabsComponent.propTypes = {
  defaultIndex: PropTypes.number,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      tabName: PropTypes.string.isRequired,
      tabContent: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.node, PropTypes.func]).isRequired,
    }),
  ).isRequired,
};

export default TabsComponent;
