import React from "react";
import Paginate from "react-paginate";
import { MdArrowForward, MdArrowBack } from "react-icons/md";
import s from "./Pagination.module.sass";

const Pagination = ({
  itemCount, limit, onPageChange, pageRange = 5, hidden,
}) => {
  const pageCount = Math.ceil(itemCount / limit);
  return (hidden) ? null : (
    <div>
      <Paginate
        previousLabel={<MdArrowBack />}
        nextLabel={<MdArrowForward />}
        breakLabel="..."
        pageCount={pageCount}
        marginPagesDisplayed={2}
        pageRangeDisplayed={pageRange}
        onPageChange={onPageChange}
        containerClassName={s["pagination-wrapper"]}
        subContainerClassName="pages pagination-wrapper"
        breakClassName={s["pagination-wrapper--break"]}
        activeClassName={s["pagination-wrapper--active"]}
      />
    </div>
  );
};

export default Pagination;
