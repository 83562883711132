import React from "react";
import { Menu } from "antd";
import { modalNames } from "../../../../helpers/constants";
import "antd/lib/menu/style/index.css";
import "antd/lib/modal/style/index.css";

const MenuComponent = (openModal) => (
  <Menu>
    <Menu.Item key="1" onClick={() => openModal(modalNames.RENAME_LIST)}>
      Byt namn
    </Menu.Item>
    <Menu.Divider />
    <Menu.Item key="2" onClick={() => openModal(modalNames.DELETE_LIST)}>
      Radera
    </Menu.Item>
  </Menu>
);

export default MenuComponent;
