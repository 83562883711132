export const modalNames = {
  LOGIN_SEE_CONTACTS: "LOGIN_SEE_CONTACTS",
  LOGIN_ADD_TO_LIST: "LOGIN_ADD_TO_LIST",
  REFILL_POT: "REFILL_POT",
  SELECT_LIST: "SELECT_LIST",
  CREATE_LIST: "CREATE_LIST",
  DELETE_LIST: "DELETE_LIST",
  RENAME_LIST: "RENAME_LIST",
  COMPANY_INFO: "COMPANY_INFO",
  CONFIRM_PURCHASE: "CONFIRM_PURCHASE",
  COOKIES: "COOKIES",
  ADD_DESCRIPTION: "ADD_DESCRIPTION",
};

export const messageTypes = {
  INFO: "info",
  SUCCESS: "success",
  ERROR: "error",
  WARMING: "warning",
};

export const alertMessages = {
  LIST_CREATED: "Listan har skapats.",
  LIST_RENAMED: "Listan har bytt namn.",
  LIST_DELETED: "Listan har tagits bort.",
  COMPANY_ADDED: "Företaget har lagts till i listan.",
  COMPANY_REMOVED: "Företaget har tagits bort från listan.",
  FIELD_UNLOCKED: "Fältet har låsts upp.",
  SESSION_EXPIRED: "Inloggningssessionen har gått ut. Försöker logga in igen...",
  NO_MESSAGE_FALLBACK: "Något gick fel. Vänligen pröva igen.",
  COMPANY_INFO_UPDATED: "Företagsinformationen uppdaterades.",
  POT_REFILL_SUCCESS: "Lyckat pottköp. Din pott har nu fyllts på.",
  POT_REFILL_FAIL: "Fel vid pottköp.",
  EMAIL_VERIFIED: "E-post verifierad!",
  PAYMENT_SUCCESS: "Betalning mottagen. Tack! Din pott kommer strax fyllas på.",
  FOREIGN_COMPANY: "Information saknas för utländska företag",
  NO_COMPANY_DATA: "SökaFöretag saknar tyvärr information om det här bolaget, kanske för att det har upphört.",
};

export const products = {
  300: "ThreeHundred_Ticks",
  500: "FiveHundred_Ticks",
};

export const juridiskFormSingular = {
  10: "Enskild näringsidkare",
  21: "Enkelt bolag",
  22: "Partrederi",
  23: "Värdepappersfond",
  31: "Handelsbolag, kommanditbolag",
  32: "Gruvbolag",
  41: "Bankaktiebolag",
  42: "Försäkringsaktiebolag",
  43: "Europabolag",
  49: "Aktiebolag",
  51: "Ekonomisk förening",
  53: "Bostadsrättsförening",
  54: "Kooperativ hyresrättsförening",
  55: "Europakollektiv",
  61: "Ideell förening",
  62: "Samfällighet",
  63: "Registrerad trossamfund",
  71: "Familjestiftelse",
  72: "Övriga stiftelser och fonder",
  81: "Statlig enhet",
  82: "Kommun",
  83: "Kommunalförbund",
  84: "Landsting",
  85: "Almänn försäkringkassa",
  86: "Enhet inom svenska kyrkan",
  87: "Off. Korporation o anstalt",
  88: "Hypoteksförening",
  89: "Regional statlig myndighet",
  91: "Oskiftad dödsbo",
  92: "Ömsesidigt försäkringsbolag",
  93: "Sparbank",
  94: "Understödsförening",
  95: "Arbetslöshetskassa",
  96: "Utlänsk juridisk person",
  98: "Övr sv jur pers, enl särk lag",
  99: "Juridisk form ej utredd",
};
