import { connect } from "react-redux";
import { actionCreators as modalActionCreators } from "../../../store/Modal";
import { actionCreators as companyActionCreators } from "../../../store/Company";
import HiddenField from "./HiddenField";

const mapStateToProps = (state, rest) => ({
  authenticated: state.auth.authenticated,
  miseId: state.companyData.data.miseId,
  potBalance: state.user.potBalance,
  activeButtonId: state.companyData.activeButtonId,
  ...rest,
});

const mapActionCreators = {
  ...modalActionCreators,
  ...companyActionCreators,
};

const withRedux = connect(
  mapStateToProps,
  mapActionCreators,
);

export default withRedux(HiddenField);
