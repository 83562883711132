import { connect } from "react-redux";
import { actionCreators as authActionCreators } from "../../../store/Auth";
import { actionCreators as modalActionCreators } from "../../../store/Modal";
import LoginModal from "./LoginModal";

const mapStateToProps = (state) => ({
});

const mapActionCreators = {
  ...authActionCreators,
  ...modalActionCreators,
};

const withRedux = connect(
  mapStateToProps,
  mapActionCreators,
);

export default withRedux(LoginModal);
