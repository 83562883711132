import React from "react";
import InfiniteScroll from "react-infinite-scroller";
import { Results, Filter, Search } from "../../../Search";
import filterOptions from "../../../../containers/CompanySearchList/filterOptions";
import s from "./SearchResults.module.sass";

const SearchResults = ({
  isLoading,
  sort,
  data,
  arbstLöpnr,
  miseId,
  hasSearchData,
  itemCount,
  to,
  query,
  displaySearchList,
  handleSortChange,
  handleInputChange,
  handlePageChange,
  searchListIsVisible,
  requestCompany,
  searchPage,
  pathname,
}) => (
  <>
    <div className={`${s["search-results__container"]}`}>
      {searchPage && (
        <Search
          name="search"
          placeholder="Sök företag, org.nr etc."
          value={query}
          onChange={handleInputChange}
          onFocus={() => {}}
          className={s["search-bar__input"]}
          wrapperClassName={s["search-bar"]}
          iconClassName={s["search-bar__icon"]}
          bgClassName={s["search-bar__bg"]}
          isLoading={isLoading}
          loaderColor="black"
          autoComplete="off"
        />
      )}
      <Filter
        value={sort}
        onChange={handleSortChange}
        options={filterOptions}
        hasSearchData={hasSearchData}
        additionalClassname={`${!searchPage ? s.filter : null}`}
        compact={searchPage}
        isLoading={isLoading}
      />
    </div>
    <div style={{ overflowY: "auto" }}>
      {searchPage ? (
        <Results
          results={data}
          loading={isLoading}
          displaySearchList={displaySearchList}
          selectedCompanyId={arbstLöpnr}
          selectedCompanyMiseId={miseId}
          show={searchListIsVisible}
          pathname={pathname}
          requestCompany={requestCompany}
          showMore={false}
          noIcon
          paddedBorder
        />
      ) : (
        <InfiniteScroll
          pageStart={0}
          initialLoad={false}
          useWindow={false}
          loadMore={handlePageChange}
          hasMore={hasSearchData && !isLoading && itemCount > to}
        >
          <Results
            results={data}
            loading={isLoading}
            displaySearchList={displaySearchList}
            selectedCompanyId={arbstLöpnr}
            selectedCompanyMiseId={miseId}
            show={searchListIsVisible}
            pathname={pathname}
            requestCompany={requestCompany}
            showMore={false}
            noIcon
            noShadow
            paddedBorder
          />
        </InfiniteScroll>
      )}
    </div>
  </>
);

export default SearchResults;
