import React from "react";
import s from "./Privacy.module.sass";

const Privacy = () => (
  <>
    <h1 className={s.header}>Personuppgiftspolicy</h1>
    <p>
      <strong>1. Om informationen i sökaföretag.se</strong>
    </p>
    <p>
      Marknadsinformation i Sverige AB innehar och förädlar register med alla företag och organisationer i Sverige. Grundinformation, såsom bransch, storlek och region, om företagen inhämtas från svenska myndigheter och kreditupplysningsföretaget Syna. Komplettering av grundinformationen sker genom egna intervjuer, där vi samlar in och uppdaterar namn på beslutsfattare.
    </p>
    <p>
      Företagsinformationen säljs, och publiceras, endast till seriösa aktörer som vänder sig med riktade budskap till relevanta målgrupper. Det är kostnadsfritt att stå med i registret. För publiceringsspärr av uppgifter, se nedan.
    </p>
    <br />
    <p>
      <strong>2. Utgivningsbevis och personuppgiftsansvar</strong>
    </p>
    <p>
      Marknadsinformation i Sverige AB är personuppgiftsansvarigt för de kontaktuppgifter vilka insamlas i egen regi. Utgivningsbevis för publicering av dessa kontaktuppgifter har utfärdats av Myndigheten för press, radio och TV. Ansvarig utgivare samt Dataskyddsombud hos Marknadsinformation i Sverige AB är Niklas Linder.
    </p>
    <br />
    <p>
      <strong>3. Hantering av kontaktuppgifter</strong>
    </p>
    <p>
      Företagsuppgifterna förvaras skyddade mot intrång av obehörig part på dedikerade servrar i serverhall med högsta EU-säkerhetsklassificering. Kontaktuppgifter kopplade till företagen, uppdateras minst en gång per år. Varje enskild kontaktuppgift, vilken samlas in genom telefonintervju, loggas med tidsstämpel inför uppföljning. Namn på VD kontrolleras maskinellt månadsvis.
    </p>
    <p>
      Leverans vid målgruppsurval sker via säkra leveranssystem innehållande tvåstegsautentisering.
    </p>
    <br />
    <p>
      <strong>4. Kontakt inför utdrag och ändring i företagsregister</strong>
    </p>
    <p>
      En enskild beslutsfattare vars namn finns i vårt register, alternativt representant från företaget, kan när som helst kontakta oss och begära att få en eller flera uppgifter ändrade eller spärrade för publicering. Beslutsfattaren eller representanten kan också begära utdrag av samtliga uppgifter vilka härrör till företaget.
    </p>
    <p>
      Vid förfrågan om utdrag i företagsregister skall avsändaren på ett tydligt sätt kunna kopplas till det aktuella företaget för att Marknadsinformation i Sverige AB ska kunna delge uppgifterna.
    </p>
    <p>
      Även företagets grunddata kan spärras för publicering. Detta görs vanligen hos Statistiska Centralbyrån (SCB), vilket då innebär spärr hos samtliga leverantörer.
    </p>
    <p>
      Kontaktuppgifter inför utdrag, ändring och övriga frågor gällande företagsregister:
    </p>
    <p className={s.group}>
      <span>Marknadsinformation i Sverige AB</span>
      <span>Sundbybergs Torg 1 H</span>
      <span>172 67 Sundbyberg</span>
    </p>
    <p className={s.group}>
      <span>08-514 905 90</span>
      <a className={s.link} href="mailto:dataskyddsombud@marknadsinformation.se">dataskyddsombud@marknadsinformation.se</a>
    </p>
  </>
);

export default Privacy;
