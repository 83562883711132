import React from "react";
import { ToggleButton, Menu } from "../ExpandableMenu";

const MobileSidebar = (props) => (
  <>
    <ToggleButton {...props} />
    <Menu {...props} />
  </>
);

export default MobileSidebar;
