import React from "react";
import cn from "classnames";
import { Button } from "../../../shared";
import { TOSModal } from "..";
import s from "./PotSpecification.module.sass";

const getPrice = (amountType) => {
  if (amountType === 300) return 195;
  if (amountType === 500) return 295;
  return 0;
};
const getTax = (price = 0) => (!price ? 0 : (price * 0.25).toFixed(2));
const getTotal = (price, tax) => (Number(price) + Number(tax));
const getNewPostAmount = (amountType, potBalance) => Number(potBalance) + Number(amountType);

const PotSpecification = ({
  submitting,
  amountType,
  isLoading,
  potBalance = 0,
  canSubmit,
}) => (
  <div className={s["pot-specification"]}>
    <div className={s["pot-specification__new-pot"]}>
      <h3 className={s["new-pot__header"]}>Specifikation</h3>
      <div className={s["new-pot__summary"]}>
        <div>
          +
          {amountType}
          {" "}
          poster
        </div>
        <div className={s["summary__rtl"]}>
          {getPrice(amountType)}
          {" "}
          kr
        </div>
        <div className={s["summary__border"]} />
        <div>Nytt saldo</div>
        <div className={cn(s["summary__rtl"], s["summary__separator"])}>
          {getNewPostAmount(amountType, potBalance)}
          {" "}
          poster
        </div>
        <div>Att betala exkl. moms</div>
        <div className={s["summary__rtl"]}>
          {getPrice(amountType)}
          {" "}
          kr
        </div>
        <div>Moms</div>
        <div className={s["summary__rtl"]}>
          {getTax(getPrice(amountType))}
          {" "}
          kr
        </div>
        <div>Att betala</div>
        <div className={s["summary__rtl"]}>
          {getTotal(getPrice(amountType), getTax(getPrice(amountType)))}
          {" "}
          kr
        </div>
      </div>
    </div>
    <Button success big type="submit" disabled={submitting || isLoading || !canSubmit}>
      Fyll på pott
    </Button>
    <TOSModal />
  </div>
);

export default PotSpecification;
