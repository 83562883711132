import React from "react";
import PropTypes from "prop-types";
import { FaArrowCircleLeft } from "react-icons/fa";
import { withRouter } from "react-router-dom";
import s from "./Header.module.sass";
import { Button } from "../../..";

const Header = ({
  namn, history, hideBack, buttonProps: { text, ...rest },
}) => (
  <div className={s["header__container"]}>
    {hideBack ? <div /> : <FaArrowCircleLeft className={s["header__arrow"]} onClick={history.goBack} />}
    <div className={s["header__title"]}>{namn}</div>
    <Button shadow additionalClass={s["header__button--fixed"]} {...rest}>
      {text}
    </Button>
  </div>
);

Header.propTypes = {
  namn: PropTypes.string,
  history: PropTypes.object,
  hideBack: PropTypes.bool,
  buttonProps: PropTypes.shape({
    onClick: PropTypes.func.isRequired,
    text: PropTypes.string,
    Icon: PropTypes.oneOfType([PropTypes.element, PropTypes.func]),
    success: PropTypes.bool,
    danger: PropTypes.bool,
  }),
};

export default withRouter(Header);
