import React from "react";
import { Menu, Dropdown } from "antd";
import { Button } from "../../..";
import s from "./NavDropdown.module.sass";
import { MdMoreVert } from "react-icons/md";
import { Link } from 'react-router-dom';

const NavDropdown = ({ pricing }) => {

  const menu = (
    <Menu>
      {pricing ? (
        <Menu.Item>
          <Link to="/pricing">Priser</Link>
        </Menu.Item>
      ) : (
        <></>
      )}
      <Menu.Item>
        <Link to="/sectors">Branscher</Link>
      </Menu.Item>
      <Menu.Item>
        <Link to="/mylists">Mina listor</Link>
      </Menu.Item>
    </Menu>
  );
  return (
    <Dropdown overlay={menu}>
      <div className={s['account-dropdown-trigger__holder']}>
        <MdMoreVert className={s['account-dropdown-trigger__icon']} /> Mer
      </div>
    </Dropdown>
  );
};
export default NavDropdown;
