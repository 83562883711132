import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { actionCreators as authActionCreators } from "../../Auth";
import { actionCreators as userActionCreators } from "../../User";
import AuthProvider from "./AuthProvider";

const mapStateToProps = (state, props) => ({
  authenticated: state.auth.authenticated,
  redirectToVerify:
    !state.user.clientInfo.email_verified
    && props.location.pathname !== "/verify",
});

const mapActionCreators = {
  ...authActionCreators,
  ...userActionCreators,
};

const withRedux = connect(mapStateToProps, mapActionCreators);

const withReduxAndRouter = (C) => withRouter(withRedux(C));

export default withReduxAndRouter(AuthProvider);
