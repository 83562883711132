import React from "react";
import { Menu, Dropdown } from "antd";
import cn from "classnames";
import { Button } from "../../..";
import s from "./AccountDropdown.module.sass";

const AccountDropdown = ({
  authenticated, clientInfo, signoutUser, potBalance, fullAccess, fullAccessValidTo,
}) => {
  const { picture, nickname, email } = clientInfo;
  const { family_name, given_name } = clientInfo["https://sökaföretag.se/user_metadata"] || {};
  const name = family_name ? `${given_name} ${family_name}` : nickname;
  let validToDate = "";
  let expired = false;

  if (authenticated && fullAccess) {
    validToDate = new Intl.DateTimeFormat("se-SE", { dateStyle: "long" }).format(new Date(fullAccessValidTo));
    expired = new Date(fullAccessValidTo).getTime() < new Date().getTime();
  }

  const menu = (
    <Menu>
      <Menu.Item className={s["dropdown__header"]}>
        <div className={s["account-header"]}>
          <div className={s["account-header__profile"]}>
            <img src={picture} alt="avatar" className={s["account-profile__image"]} />
            <div>
              <span className={s["account-profile__name"]}>{name}</span>
              <span className={s["account-profile__email"]}>{email}</span>
            </div>
          </div>
        </div>
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item className={s["account__balance"]}>
        {authenticated && fullAccess ? (
          <>
            Full åtkomst gäller t.o.m
            {" "}
            <span className={cn(s["account__valid-to"], { [s.expired]: expired })}>{validToDate}</span>
          </>
        ) : (
          <>
            <span className={s["account__balance--amount-container"]}>
              Kontobalans:
              {" "}
              <span className={s["account__balance--amount"]}>{potBalance}</span>
            </span>
            <Button primary href="/potrefill">
              Fyll på
            </Button>
          </>
        )}
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item onClick={signoutUser}>
        <span>Logga ut</span>
      </Menu.Item>
    </Menu>
  );
  return (
    <Dropdown overlay={menu} trigger={["click"]}>
      <div className={s["account-dropdown-trigger__holder"]}>
        <img src={picture} alt="avatar" className={s["account-dropdown__account-image"]} />
        <div className={s["account-dropdown-trigger__arrow-holder"]}>
          <div className={s["account-dropdown-trigger__circle"]} />
        </div>
      </div>
    </Dropdown>
  );
};

export default AccountDropdown;
