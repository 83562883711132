import React from "react";
import { HorizontalBarChart } from "../../..";
import s from "./GrowthExpanded.module.sass";

const GrowthExpanded = ({
  branschTillväxtfaktor = 0, tillväxtfaktor = 0, synaPeriodTill, namn, printMode,
}) => (
  <div className={s.container} id="growth-chart">
    <HorizontalBarChart
      data={[{ average: tillväxtfaktor, branch: branschTillväxtfaktor, date: synaPeriodTill }]}
      name={namn}
      s={s}
      printMode={printMode}
    />
  </div>
);

export default GrowthExpanded;
