import React from "react";
import { MdClose } from "react-icons/md";
import { Button } from "../../shared";
import s from "./RefillPotModal.module.sass";

const RefillPotModal = ({ closeModal }) => {
  const Header = () => (
    <div className={s.header}>
      <span className={s["header__title"]}>Din pott är tom!</span>
      <MdClose onClick={closeModal} className={s["header__icon"]} />
    </div>
  );

  const Content = () => (
    <div className={s.header}>
      <span className={s.paragraph}>
        Du kommer att få
        {" "}
        <strong>5 gratis klick</strong>
        {" "}
        varje dag, och kommer att kunna låsa upp fält under endast 24 timmar.
      </span>
      <span className={s.paragraph}>För att kunna permanent låsa upp fält, köp fler klick:</span>
    </div>
  );

  const ActionButtons = () => (
    <div className={s["header__button--wrapper"]}>
      <Button additionalClass={s["header__buton--basic"]} onClick={closeModal}>
        Avbryt
      </Button>
      <Button href="/potrefill" additionalClass={s["header__button"]} onClick={closeModal} primary shadow>
        Fyll på
      </Button>
    </div>
  );

  return (
    <div className={s.container}>
      <Header />
      <Content />
      <ActionButtons />
    </div>
  );
};

export default RefillPotModal;
