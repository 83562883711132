import React from "react";
import ReactDOM from "react-dom";
import { Provider as ReduxProvider } from "react-redux";
import { ConnectedRouter } from "connected-react-router";
import { PersistGate } from "redux-persist/integration/react";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import smoothscroll from "smoothscroll-polyfill";
import { message } from "antd";
import configureStore, { persistor } from "./store/configureStore";
import history from "./store/api/history";
import { AuthProvider, ModalProvider } from "./store/providers";
import App from "./containers/App/App";

smoothscroll.polyfill();
message.config({ maxCount: 3 });

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);
export const store = configureStore(history, window.initialReduxState);
const rootElement = document.getElementById("root");

ReactDOM.render(
  <ReduxProvider store={store}>
    <PersistGate persistor={persistor(store)}>
      <ConnectedRouter history={history}>
        <Elements stripe={stripePromise}>
          <AuthProvider>
            <ModalProvider>
              <App />
            </ModalProvider>
          </AuthProvider>
        </Elements>
      </ConnectedRouter>
    </PersistGate>
  </ReduxProvider>,
  rootElement,
);
