import React from "react";
import { FaAngleDown } from "react-icons/fa";
import s from "./Growth.module.sass";

const Growth = ({ branschTillväxtfaktor = 0, tillväxtfaktor = 0, isMobile }) => {
  const isHigher = tillväxtfaktor > branschTillväxtfaktor;
  const scrollToGrowth = () => {
    const growthContainer = document.getElementById("growth-chart");
    if (growthContainer) growthContainer.scrollIntoView({ behavior: "smooth" });
  };

  if (tillväxtfaktor === null) {
    return (
      <div className={s["growth__container"]}>
        <div className={s["growth__no-data"]}>Tillväxtfaktor beräknas endast för aktiebolag med minst fem anställda, där tillräckliga data finns.</div>
      </div>
    );
  }

  return (
    <div className={s["growth__container"]}>
      <div className={s["growth__difference"]}>
        {`Tillväxten är ${isHigher ? "högre" : "lägre"} än`}
        <br />
        branschens genomsnittliga tillväxt.
      </div>

      {!isMobile ? (
        <button type="button" className={s["growth__more"]} onClick={scrollToGrowth}>
          <span>Se mer</span>
          <FaAngleDown className={s["growth--arrow"]} />
        </button>
      ) : null}
    </div>
  );
};

export default Growth;
