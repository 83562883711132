import { connect } from "react-redux";
import { actionCreators as myListsActionCreators } from "../../../store/MyLists";
import { actionCreators as modalActionCreators } from "../../../store/Modal";
import { actionCreators as uiActionCreators } from "../../../store/Ui";
import SelectListModal from "./SelectListModal";

const mapStateToProps = (state) => ({
  myLists: state.myListsData.data,
  companyId: state.companyData.data.miseId,
  companyName: state.companyData.data.namn,
  isLoading: state.myListsData.isLoading || state.singleListData.isLoading,
  createNewListIsOpen: state.ui.createNewListIsOpen,
});

const mapActionCreators = {
  ...myListsActionCreators,
  ...modalActionCreators,
  ...uiActionCreators,
};

const withRedux = connect(
  mapStateToProps,
  mapActionCreators,
);

export default withRedux(SelectListModal);
