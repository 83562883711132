import React from "react";
import cn from "classnames";
import s from "./Header.module.sass";
import { Filter } from "../../..";

const Header = ({
  sort, onChange, options, itemCount = 0, hasSearchData,
}) => (
  <div className={cn(s["header__container"])}>
    <h2>
      Sökresultat (
      {itemCount}
      {" "}
      träffar)
      {" "}
    </h2>
    <Filter
      value={sort}
      onChange={onChange}
      options={options}
      hasSearchData={hasSearchData}
      className="filter"
      truncate
    />
  </div>
);

export default Header;
