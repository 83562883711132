import { connect } from "react-redux";
import { actionCreators as searchActionCreators } from "../../store/Search";
import { actionCreators as modalActionCreators } from "../../store/Modal";
import TermsContainer from "./TermsContainer";

const mapStateToProps = (state) => ({
  modalIsOpen: state.modal.modalIsOpen,
  modalName: state.modal.name,
});

const mapActionCreators = {
  ...searchActionCreators,
  ...modalActionCreators,
};

const withRedux = connect(
  mapStateToProps,
  mapActionCreators,
);

export default withRedux(TermsContainer);
