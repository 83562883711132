import React from "react";
import LoadingOverlay from "react-loading-overlay";
import { MdClose } from "react-icons/md";
import { Button } from "../../shared";
import s from "./CreateListModal.module.sass";

const CreateListModal = ({ isLoading, closeModal, createList }) => {
  const ref = React.createRef();
  const onCreateList = () => {
    const name = ref.current && ref.current.value;
    createList(name);
  };
  const onEnter = ({ key }) => {
    if (key === "Enter") onCreateList();
  };

  const Header = () => (
    <div className={s.header}>
      <span className={s["header__title"]}>Skapa ny lista</span>
      <MdClose onClick={closeModal} className={s["header__icon"]} />
    </div>
  );

  const InsertName = () => (
    <div className={s.header}>
      <div className={s["input-bar"]}>
        <input
          type="text"
          className={s["input-bar__input"]}
          placeholder="Namn på lista"
          onKeyPress={onEnter}
          ref={ref}
          autoFocus
        />
        <Button additionalClass={s["header__button--create"]} onClick={onCreateList} success shadow>
          Skapa
        </Button>
      </div>
    </div>
  );

  return (
    <LoadingOverlay active={isLoading} className={s["overlay-wrapper"]} spinner>
      <div className={s.container}>
        <Header />
        <InsertName />
      </div>
    </LoadingOverlay>
  );
};

export default CreateListModal;
