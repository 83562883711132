import axios from "axios";
import { Auth0 } from "../Auth";
import { actionCreators as userActionCreators } from "../User";

let store;

if (process.env.NODE_ENV === "test") {
  store = require("../../utils/test-utils").store;
} else {
  store = require("../..");
}

const instance = axios.create({ baseURL: "/api" });
instance.CancelToken = axios.CancelToken;
instance.isCancel = axios.isCancel;

instance.interceptors.request.use(
  async (config) => {
    const token = await new Promise((resolve) => {
      try {
        const response = Auth0.getAccessToken();
        resolve(response);
      } catch (e) {
        resolve(false);
      }
    });

    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (err) => Promise.reject(err),
);

instance.interceptors.response.use(
  (config) => config,
  (err) => {
    if (err.response.status === 401) {
      store.dispatch(userActionCreators.didSessionExpire());
    }
    return Promise.reject(err);
  },
);

export default instance;
