import React from "react";
import { MdPinDrop } from "react-icons/md";
import { IoIosAt } from "react-icons/io";
import cn from "classnames";
import { HiddenField, Tabs } from "../../../shared";
import s from "./Contact.module.sass";

const noAddresses = ["UTLÄNDSK ADRESS", "OKÄND ADRESS"];

const postnrFuction = (value) => {
  if (!value) {
    return "";
  }
  return value.split("").map((number, index) => {
    if (index === 2) {
      return `${number} `;
    }
    return number;
  });
};

const Contact = ({
  besöksAdress,
  besöksPostnr,
  besöksPostort,
  adress,
  postnr,
  postort,
  telnr,
  reklamspärr,
  hemsida,
  miseId,
  protectedItems = [],
  unlockedFields,
}) => {
  const epost = protectedItems.find(({ fieldName }) => fieldName === "Epostadress");
  const generateTab = (adress, postnr, postort) => (
    <div className={s["contact__container__tab"]}>
      <div className={s["contact__address"]}>
        <MdPinDrop className={s["contact__icon"]} />
        <div>
          {adress || (!postnr && "Ingen adressinformation tillgänglig")}
          {!noAddresses.includes(adress) && postnr !== "00000" && (
            <div className={s["contact--small"]}>
              {postnrFuction(postnr)}
              {" "}
              {postort}
            </div>
          )}
        </div>
      </div>
    </div>
  );

  /* Live update of unlocked fields */
  if (unlockedFields[miseId]) {
    for (const u of unlockedFields[miseId]) {
      if (u.unlocked && u.fieldName === "Epostadress") {
        epost.unlocked = u.unlocked;
        epost.value = u.value;
      }
    }
  }

  const options = [
    {
      tabName: "BESÖKADRESS",
      tabContent: generateTab(besöksAdress, besöksPostnr, besöksPostort),
    },
    {
      tabName: "POSTADRESS",
      tabContent: generateTab(adress, postnr, postort),
    },
  ];

  return (
    <>
      <Tabs options={options} defaultIndex={0} className={s["contact__tabs--height"]} listStyle={{ position: "sticky" }} />
      <div className={cn(s["contact__container"], { [s.hide]: (!hemsida && !telnr && !epost) })}>
        <div className={s["contact__details"]}>
          {hemsida && !reklamspärr && (
            <div className={s["contact__field--container"]}>
              <div className={s["contact__field--title"]}>WEBB</div>
              <div>
                <a href={`http://${hemsida}`} target="_blank" rel="noopener noreferrer" className={s["contact__link"]}>
                  {hemsida}
                </a>
              </div>
            </div>
          )}
          {telnr && !reklamspärr && (
            <div className={s["contact__field--container"]}>
              <div className={s["contact__field--title"]}>TELEFON</div>
              <div className={s["contact__field--value"]}>
                <a href={`tel:${telnr}`} target="_blank" rel="noopener noreferrer" className={s["contact__link"]}>
                  {telnr}
                </a>
              </div>
            </div>
          )}
          {epost && !reklamspärr && (
            <div className={s["contact__field--container"]}>
              <div className={s["contact__field--title"]}>INFOADRESS</div>
              <div>
                <HiddenField
                  name="e-postadress"
                  fieldName={epost.fieldName}
                  unlocked={epost.unlocked}
                  Icon={IoIosAt}
                >
                  <a href={`mailto:${epost.value}`} target="_blank" rel="noopener noreferrer" className={s["contact__link"]}>
                    {epost.value}
                  </a>
                </HiddenField>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default Contact;
