import React from "react";
import { MdArrowUpward } from "react-icons/md";
import { Button, List } from "../../../shared";
import { ListActions, ListHeader } from "..";
import { modalNames } from "../../../../helpers/constants";
import s from "./Lists.module.sass";

const Lists = ({ listData, openModal, isMobile }) => (
  <div>
    <ListActions style={{ position: isMobile ? "fixed" : "relative" }}>
      <Button onClick={() => openModal(modalNames.CREATE_LIST)} primary shadow additionalClass={s.button}>
        Skapa ny lista
      </Button>
    </ListActions>
    <ListHeader>Mina listor</ListHeader>

    {listData.length > 0 ? (
      <List
        data={listData}
        withArrow
        columnStyles={s["list-column"]}
        additionalClassnames={s["list-container"]}
        data-testid="list-container"
      />
    ) : (
      <div>
        <MdArrowUpward className={s["list-arrow"]} />
        <span className={s["list-empty__placeholder"]}>
          Här var det tomt! Använd knappen för att skapa din första lista.
        </span>
      </div>
    )}
  </div>
);

export default Lists;
