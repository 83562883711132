import React from "react";
import { Menu, Dropdown, Icon } from "antd";
import cn from "classnames";
import { Button } from "../../shared";
import s from "./Filter.module.sass";

const Filter = ({
  value, options, onChange, compact, hasSearchData, additionalClassname, isLoading, truncate, ...rest
}) => {
  const { label = "Sortera..." } = options.find((o) => o.value === value) || {};

  const menu = (
    <Menu {...rest}>
      {options.map((o) => (
        <Menu.Item key={o.value}>
          <Button onClick={() => onChange(o.value)} additionalClass={s.dropdown__item}>
            {o.label}
          </Button>
        </Menu.Item>
      ))}
    </Menu>
  );
  return (
    <Dropdown overlay={menu} trigger={["click"]} disabled={!hasSearchData || isLoading}>
      <Button className={cn(s.dropdown__button, additionalClassname)} title={label}>
        <div className={cn(s.dropdown__text, { [s["dropdown__text--truncated"]]: truncate })}>{label}</div>
        <Icon type="down" className={s.icon} />
      </Button>
    </Dropdown>
  );
};

export default Filter;
