import React from "react";
import { Link } from "react-router-dom";
import { Button } from "../..";
import { modalNames } from "../../../helpers/constants";
import s from "./Footer.module.sass";

const Footer = ({ openModal }) => (
  <div className={s["footer"]}>
    <div className={s["footer__content"]}>
      <img src="/images/mise-logo.svg" className={s.logo} alt="Marknadsinformations logotyp" />
      <div className={s["links"]}>
        <Button
          onClick={() => openModal(modalNames.COOKIES, { className: "cookies" })}
          className={s["link__item"]}
          asLink
        >
            Användning av cookies
        </Button>
        <Link to="/tos" className={s["link__item"]}>Användarvillkor</Link>
        <Link to="/privacy" className={s["link__item"]}>Personuppgiftspolicy</Link>
      </div>
    </div>
    <hr className={s.separator} />
    <span className={s["copyright"]}>Copyright © 2020 Marknadsinformation i Sverige AB. Alla rättigheter förbehålls.</span>
  </div>
);

export default Footer;
