import React from "react";
import s from "./CompanyList.module.sass";
import { Results } from "../../..";

const CompanyList = ({
  data, isLoading, from, to, itemCount, displaySearchList, searchListIsVisible,
}) => (
  <div>
    <Results
      results={data}
      loading={isLoading}
      show={searchListIsVisible}
      displaySearchList={displaySearchList}
      header="Företag"
      noShadow
      noBorder
      relative
      showMore={false}
    />
    {data.length ? (
      <div className={s.pagePosition}>
        Visar företag
        {" "}
        {from}
        {" - "}
        {to}
        {" av "}
        {itemCount}
      </div>
    ) : ""}
  </div>
);

export default CompanyList;
