import React, { Component } from "react";
import MediaQuery from "react-responsive";
import InfiniteScroll from "react-infinite-scroller";
import { SingleList } from "../../components/MyLists/modules";
import s from "./SingleListContainer.module.sass";

class SingleListContainer extends Component {
  componentDidMount() {
    const { singleListData, location, history } = this.props;
    const singleListId = singleListData.data.listId;

    if (!location.state) {
      history.push("/mylists");
      return;
    }

    if (location.state.id !== singleListId) {
      this.props.requestSingleList(location.state.id, 0, 20, true);
    }
  }

  componentDidUpdate(prevProps) {
    if (!this.props.location.state) return;

    const { id: idNext } = this.props.location.state;
    const { id } = prevProps.location.state;

    if (id !== idNext) {
      this.props.requestSingleList(idNext, 0, 20, true);
    }
  }

  handlePageChange = () => {
    const {
      limit, page, data,
    } = this.props.singleListData;
    const from = page * limit;
    const to = from + limit;

    this.props.requestSingleList(data.listId, from, to, true);
  };


  render() {
    const { singleListData, singleListName, ...rest } = this.props;

    const {
      itemCount,
      to,
      isLoading,
    } = singleListData;

    return (
      <article className={s.article}>
        <InfiniteScroll
          pageStart={0}
          initialLoad={false}
          useWindow={false}
          loadMore={this.handlePageChange}
          hasMore={!!singleListData.data.antalTräff && !isLoading && itemCount > to}
        >
          <MediaQuery query="(max-width: 768px)">
            {(matches) => (
              <SingleList
                list={singleListData}
                isLoading={isLoading}
                name={singleListName}
                isMobile={matches}
                additionalListActionsClassnames={s.listActions}
                {...rest}
              />
            )}
          </MediaQuery>
        </InfiniteScroll>
      </article>
    );
  }
}

export default SingleListContainer;
