import React from "react";
import PropTypes from "prop-types";
import cn from "classnames";
import s from "./Card.module.sass";

const Card = ({
  header, children, className, containerClassName, noPadding, headerStart,
}) => {
  const containerClassnames = cn(
    s.container,
    { [s.noPadding]: noPadding },
    containerClassName,
  );

  const headerClassnames = cn(
    s.header,
    { [s.headerStart]: headerStart },
  );

  return (
    <article className={className}>
      {header && <div className={headerClassnames}>{header}</div>}
      <div className={containerClassnames}>{children}</div>
    </article>
  );
};

Card.propTypes = {
  header: PropTypes.string,
  className: PropTypes.string,
  containerClassName: PropTypes.string,
  noPadding: PropTypes.bool,
  headerStart: PropTypes.bool,
};

export default Card;
