import React from "react";
import s from "./Terms.module.sass";

const Terms = () => (
  <>
    <h1 className={s.header}>Användarvillkor</h1>
    <p>
      <strong>1. Inledning</strong>
    </p>
    <p>
      Följande villkor har upprättats av Marknadsinformation i Sverige AB med organisationsnummer 556672-0750 nedan kallad MISE. Motpart i detta avtal benämns nedan som ”Kund” och avser det företag där person har registrerat sig som användare.
      Villkoren reglerar hur företagsinformation kan nyttjas och inkluderar regler kring leverans och filöverföring, åtkomst, förvaring och radering.
      Villkoren gäller visning av företagsinformation och inläsning av externa, eller egna register.
    </p>
    <br />
    <p>
      <strong>2. Användande, åtkomst och rättigheter</strong>
    </p>
    <p>
      I syfte att skydda personuppgifter krävs registrering av användare för att få utökad möjlighet att se dold information. Informationen får inte överföras till externa system förutom i det fall en affärsförbindelse upprättas vilket medför att information förs in i exempelvis kundreskontran hos berörd användare/Kund.
      MISE skall ses som ägare av samtlig information, därmed tillfaller ingen äganderätt Kund. Vid funktioner såsom att användare själva kan registrera egna kontaktuppgifter på ett kundkort ansvarar MISE för uppdatering samt radering av uppgifterna per automatik 12 månader efter att uppgifterna fördes in. Detta i syfte att agera utifrån GDPR och regler kring hantering av personuppgifter.
      Informationen får inte spridas vidare till tredje part. Byte, vidareförsäljning eller bruk av annan part än Kund beivras.
      Inloggningsuppgifter skall förvaras på ett säkert sätt med förhindrad åtkomst från obehörig intern personal eller extern tredje part.
      Användare äger ingen rätt till ersättning vid eventuell felaktig presentation av data oavsett det är grundinformation om företagen eller namngivna beslutsfattare med tillhörande kontaktuppgifter såsom namn på beslutfattare, direkttelefonnummer och e-postadress. Uppgifterna uppdateras kontinuerligt men kan ändå innehålla felaktigheter delvis på grund av att informationen lämnas muntligen genom telefonintervjuer samt att en viss fördröjning ofta sker i det fall en kontaktperson avslutar sin tjänst i företaget.
    </p>
    <br />
    <p>
      <strong>3. Nyttjanderätt</strong>
    </p>
    <p>
      Generell företagsinformation visas kostnadsfritt i systemet. Likaså är funktioner såsom sorteringsmöjligheter av målgrupper fria. Användaren kan visa dolda kontaktuppgifter och i vissa fall övrig unik företagsinformation. En köpt uppgift visas under en period av 12 månader vilket innebär att redan köpt information visas vid varje tillfälle en användare tar del av företagsinformationen på ett kundkort. Informationen kan raderas vid tidigare skede av leverantören, exempelvis om kontaktperson på företag begär opt-out hos oss som källa eller i det fall personen har slutat sin tjänst på företaget. I det fall en ny person ersätter tidigare visas dennes namn istället.
      E-post och direkttelefonnummer kan endast nyttjas vid kontakt 1-1 och inte vid massutskick i extern programvara för exempelvis e-postmarknadsföring. Med 1-1 menas att användaren har rätt att använda kontaktuppgifter såsom e-postadress där användaren skickar information till en mottagare i taget.
      Källa till informationen är Marknadsinformation i Sverige AB. Viss företagsinformation, såsom nyckeltal och grundinformation om företagen, härstammar från underleverantör Syna AB.
    </p>
    <br />
    <p>
      <strong>4. Betalning</strong>
    </p>
    <p>
      Fakturering sker via e-faktura, om inget annat överenskommits, med tjugo (20) dagars betalningstid, moms tillkommer på samtliga belopp. MISE har rätt till 8 % dröjsmålsränta vid försenad betalning samt en extra fast avgift om 50 kr per betalningspåminnelse.
      Förköpt visning av företagsinformation skall nyttjas inom tolv månader från tidpunkt för köp.
    </p>
    <br />
    <p>
      <strong>5. Köp av kreditupplysning och bokslut</strong>
    </p>
    <p>
      Vid köp av kreditupplysning eller bokslutsinformation är leverantören kreditupplysningsföretaget Syna AB vilken också fakturerar för tjänsten. Affärsuppgörelse sker mellan Kund och Syna och ligger därmed utanför MISES ansvar.
    </p>
    <br />
    <p>
      <strong>6. Avtalsbrott</strong>
    </p>
    <p>
      Om Kund bryter mot dessa villkor äger MISE rätt att med omedelbar verkan stänga av användare från tjänsten.
      Väsentligt brott som innebär att leverans, eller delar av informationen hamnar hos, eller nyttjas av, tredje part, eller där nyttjanderätt enligt ovan inte följs, ger MISE rätt att fakturera Kund ett engångsbelopp om SEK 100 000. MISE äger även rätt till ersättning för kostnader uppkomna med anledning av Kunds missbruk av informationen eller övriga brott mot dessa villkor.
    </p>
    <br />
    <p>
      <strong>7. Tvist om tolkning</strong>
    </p>
    <p>
      Eventuell tvist om tolkning av detta avtal skall i första hand lösas genom möte mellan parterna och i andra hand i svensk rätt förlagd i Stockholm.
    </p>
  </>
);

export default Terms;
