import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { actionCreators as userActionCreators } from "../../store/User";
import VerifyPage from "./VerifyPage";

const mapStateToProps = (state) => ({
  email: state.user.clientInfo.email,
  emailVerified: state.user.clientInfo.email_verified,
  isLoading: state.user.isLoading,
});

const mapActionCreators = {
  ...userActionCreators,
};

const withRedux = connect(mapStateToProps, mapActionCreators);

const withReduxAndRouter = (C) => withRouter(withRedux(C));

export default withReduxAndRouter(VerifyPage);
