import React, { useEffect } from "react";
import { Tooltip } from "antd";
import _isNull from "lodash/isNull";
import cn from "classnames";
import { List } from "../../..";
import formatNumber from "../../../../helpers/formatNumber";
import { ArrowIndicator } from "../../../shared";
import s from "./Economy.module.sass";

const Economy = ({
  omsättning,
  omsättning2,
  resultat,
  resultat2,
  kassalikviditet,
  kassalikviditet2,
  soliditet,
  soliditet2,
  antalAnställda,
  antalAnställda2,
  omsättningPerAnställd,
  omsättningPerAnställd2,
  tillväxtfaktor,

  onTabChange,
  extractData,
  selectedTab,
  hidden,
  withGrowth,
}) => {
  const getArrowPosition = (val1, val2) => ((val1 === val2 || _isNull(val2)) ? "normal" : val1 > val2 ? "up" : "down");
  const displayValue = ((value, type, unit = "") => (_isNull(value) ? (
    <Tooltip title="Ingen data tillgänglig" placement="top">
      <div>Ingen data tillgänglig</div>
    </Tooltip>
  ) : `${formatNumber(value, type)} ${unit}`));

  const info = [
    {
      label: "Antal Anställda",
      value: (
        <span className={s["economy__value--container"]}>
          {displayValue(antalAnställda, "space")}
          <ArrowIndicator position={getArrowPosition(antalAnställda, antalAnställda2)} />
        </span>
      ),
      trending: antalAnställda > antalAnställda2,
    },
    {
      label: "Omsättning",
      value: (
        <span className={s["economy__value--container"]}>
          {displayValue(omsättning, "space", "tkr")}
          <ArrowIndicator position={getArrowPosition(omsättning, omsättning2)} />
        </span>
      ),
      trending: omsättning > omsättning2,
    },
    {
      label: "Resultat",
      value: (
        <span className={s["economy__value--container"]}>
          {displayValue(resultat, "space", "tkr")}
          <ArrowIndicator position={getArrowPosition(resultat, resultat2)} />
        </span>
      ),
      trending: resultat > resultat2,
    },
    {
      label: "Likviditet",
      value: (
        <span className={s["economy__value--container"]}>
          {displayValue(kassalikviditet, "percent", "%")}
          <ArrowIndicator position={getArrowPosition(kassalikviditet, kassalikviditet2)} />
        </span>
      ),
      trending: kassalikviditet > kassalikviditet2,
    },
    {
      label: "Omsättning per anställd",
      value: (
        <span className={s["economy__value--container"]}>
          {displayValue(omsättningPerAnställd, "space", "tkr")}
          <ArrowIndicator position={getArrowPosition(omsättningPerAnställd, omsättningPerAnställd2)} />
        </span>
      ),
      trending: omsättningPerAnställd > omsättningPerAnställd2,
    },
    {
      label: "Soliditet",
      value: (
        <span className={s["economy__value--container"]}>
          {displayValue(soliditet, "percent", "%")}
          <ArrowIndicator position={getArrowPosition(soliditet, soliditet2)} />
        </span>
      ),
      trending: soliditet > soliditet2,
    },
    ...(withGrowth && tillväxtfaktor !== null
      ? [
        {
          label: "Tillväxtfaktor",
          value: (
            <span className={s["economy__value--container"]}>
              {tillväxtfaktor < 2 && tillväxtfaktor > -2
                ? Math.round(tillväxtfaktor * 10) / 10
                : Math.round(tillväxtfaktor)}
              <ArrowIndicator position={getArrowPosition(tillväxtfaktor, 0)} />
            </span>
          ),
          trending: tillväxtfaktor > 0,
        },
      ]
      : []),
  ];

  const data = info.map((e, i) => ({
    ...e,
    onClick: onTabChange,
    selected: i === selectedTab,
  }));

  useEffect(() => {
    extractData(data);
  }, [data]);

  return (
    <div className={cn(s["economy__container"], { [s["economy__container--hidden"]]: hidden })}>
      <List data={data} columnStyles={s["economy__column--clickable"]} flex />
    </div>
  );
};

export default React.memo(Economy);
