const TOGGLE_MENU = "TOGGLE_MENU";
const TOGGLE_MODAL = "TOGGLE_MODAL";
const TOGGLE_CREATE_NEW_LIST = "TOGGLE_CREATE_NEW_LIST";

const initialState = { menuIsOpen: false, modalIsOpen: false, createNewListIsOpen: false };

export const actionCreators = {
  toggleMenu: () => ({ type: TOGGLE_MENU }),
  toggleModal: () => ({ type: TOGGLE_MODAL }),
  toggleCreateNewList: () => ({ type: TOGGLE_CREATE_NEW_LIST }),
};

export const reducer = (state = initialState, action) => {
  if (action.type === TOGGLE_MENU) {
    return { ...state, menuIsOpen: !state.menuIsOpen };
  }

  if (action.type === TOGGLE_MODAL) {
    return { ...state, modalIsOpen: !state.modalIsOpen };
  }

  if (action.type === TOGGLE_CREATE_NEW_LIST) {
    return { ...state, createNewListIsOpen: !state.createNewListIsOpen };
  }

  return state;
};
