import React from "react";
import { Link } from "react-router-dom";
import cn from "classnames";
import { ListValue, ListLabel, ListDetails } from ".";
import { alertMessages } from "../../../../helpers/constants";

const ListItem = ({
  icon,
  label,
  value,
  details,
  href,
  hrefProps,
  classNamesColumn,
  classNamesLabel,
  classNamesValue,
  classNamesInfo,
  classNamesLink,
  withArrow,
  onClick,
  HoverIcon,
  foreign,
  s,
  index,
}) => {
  if (href) {
    return (
      <li
        className={classNamesColumn}
        onClick={onClick}
        data-index={index}
        tabIndex="0"
        title={foreign ? alertMessages.FOREIGN_COMPANY : label}
      >
        <Link to={{ pathname: href, state: hrefProps }} className={classNamesLink}>
          {icon && <div className={s["list__icon"]}>{icon}</div>}
          <div className={classNamesInfo}>
            <ListLabel label={label} classNames={classNamesLabel} s={s} />
            <ListDetails details={details} s={s} />
          </div>
          <ListValue value={value} classNames={classNamesValue} withArrow={withArrow} s={s} />
        </Link>
      </li>
    );
  }

  return (
    <li
      className={cn(classNamesColumn, classNamesLink)}
      onClick={onClick}
      data-index={index}
      title={foreign ? alertMessages.FOREIGN_COMPANY : label}
    >
      {icon && <div className={s["list__icon"]}>{icon}</div>}
      <div className={classNamesInfo}>
        <ListLabel label={label} classNames={classNamesLabel} s={s} />
        <ListDetails details={details} s={s} />
      </div>
      <ListValue value={value} classNames={classNamesValue} withArrow={withArrow} HoverIcon={HoverIcon} s={s} />
    </li>
  );
};

export default ListItem;
