import React from "react";
import { MdRemove, MdAdd } from "react-icons/md";
import { NoListData, ListPlaceholder } from "..";
import { Card } from "../../../../layouts";
import {
  Header,
  Map,
  Contact,
  Growth,
  Details,
  Executives,
  ChartsContainer,
  Credit,
  GrowthExpanded,
  Featured,
} from "../../../Company/modules";
import permalink from "../../../../helpers/permalink";
import s from "../../../Company/Company.module.sass";

const ShowList = ({
  companyData,
  singleListData,
  unlockedFields,
  selectedCompanyId,
  deleteSingleListItem,
  addSingleListItem,
  listData,
  openModal,
  authenticated,
  history,
  requestSubsidiariesSearch,
  requestSectorSearch,
}) => {
  const company = companyData.data;
  const { listId } = singleListData.data;
  const companyInList = !!singleListData.data.träfflista.find(({ miseId }) => company.miseId === miseId);

  const onCustomSearch = ({ branschKod, orgnr }) => {
    if (branschKod) requestSectorSearch({ branschKod });
    if (orgnr) requestSubsidiariesSearch({ orgnr });

    history.push(`/company/${company.orgnr}/${permalink(company.namn)}`);
  };

  if (company.miseId !== selectedCompanyId) {
    if (!listData.length) return <NoListData openModal={openModal} />;
    if (!companyData.isLoading) return <ListPlaceholder />;
  }

  return (
    <>
      <Card className={s["card__header"]} noPadding headerStart>
        <Header
          {...company}
          buttonProps={
            companyInList
              ? {
                text: "Ta bort från lista",
                danger: true,
                Icon: MdRemove,
                onClick: () => deleteSingleListItem(listId, company.miseId),
              }
              : {
                text: "Lägg till i lista",
                onClick: () => addSingleListItem(listId, company.miseId, company.namn),
                success: true,
                Icon: MdAdd,
              }
          }
          s={s}
          hideBack
        />
      </Card>
      <Card header="Plats" className={s["card__map"]} containerClassName={s["card__map--container"]} noPadding headerStart>
        <Map s={s} {...company} />
        <Contact s={s} {...company} unlockedFields={unlockedFields} />
      </Card>
      <Card header="Tillväxtfaktor" className={s["card__contact"]} noPadding headerStart>
        <Growth s={s} {...company} />
      </Card>
      <Card header="Importera" className={s["card__featured"]} noPadding headerStart>
        <Featured s={s} {...company} />
      </Card>
      <Card header="Bolagsdetaljer" className={s["card__details"]} noPadding headerStart>
        <Details s={s} {...company} requestSectorSearch={onCustomSearch} requestSubsidiariesSearch={onCustomSearch} />
      </Card>
      <Card header="Beslutsfattare" className={s["card__executives"]} noPadding>
        <Executives s={s} {...company} authenticated={authenticated} unlockedFields={unlockedFields} />
      </Card>
      <Card header="Ekonomisk basdata & Branschjämförelser" className={s["card__economy"]} noPadding>
        <ChartsContainer s={s} company={company} />
      </Card>
      <Card header="Tillväxtfaktor" className={s["card__growth"]}>
        <GrowthExpanded s={s} {...company} />
      </Card>
      <Card header="Kreditupplysning" className={s["card__credit"]}>
        <Credit s={s} {...company} />
      </Card>
    </>
  );
};

export default ShowList;
