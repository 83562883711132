import React from "react";
import { Link } from "react-router-dom";

const Logotype = ({ s }) => (
  <Link to="/" className={s["logotype"]}>
    <span className={s["logotype__image"]}>SökaFöretag</span>
  </Link>
);

export default Logotype;
