import React from "react";
import s from "./CompanyFooter.module.sass";

const CompanyFooter = () => (
  <p className={s["company-footer"]}>
    sökaföretag.se tillhandahålls av
    {" "}
    <a
      href="https://www.marknadsinformation.se"
      rel="noopener noreferrer"
      target="_blank"
      className={s["company__link"]}
    >
      Marknadsinformation i Sverige AB
    </a>
  </p>
);

export default CompanyFooter;
