import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { Auth0 } from "../../Auth";

class AuthProvider extends Component {
  componentDidMount() {
    if (this.props.authenticated) {
      this.props.didSessionExpire();
    }
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.authenticated && this.props.authenticated) {
      this.props.setAuth0ClientInfo(Auth0.getClientInfo());
      this.props.getUser();
    }
  }

  render() {
    const { authenticated, redirectToVerify, children } = this.props;

    if (authenticated && redirectToVerify) {
      return <Redirect to="/verify" />;
    }

    return children;
  }
}

export default AuthProvider;
