import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { actionCreators } from "../../store/Search";
import CompanySearchList from "./CompanySearchList";

const mapStateToProps = (state) => ({
  searchData: state.searchData,
  query: state.searchData.query,
});

const mapActionCreators = {
  ...actionCreators,
};

const withRedux = connect(
  mapStateToProps,
  mapActionCreators,
);

const withReduxAndRouter = (C) => withRouter(withRedux(C));

export default withReduxAndRouter(CompanySearchList);
