import isPromise from "is-promise";

const errorMiddleware = () => (next) => (action) => {
  if (!isPromise(action.payload && action.payload.promise)) {
    return next(action);
  }

  return next(action).catch((error) => {
    console.error(`${action.type} unhandled rejection caught at middleware with reason: ${JSON.stringify(error.message)}.`);
    return error;
  });
};

export default errorMiddleware;
