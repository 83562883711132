import React from "react";
import cn from "classnames";
import { FaArrowLeft } from "react-icons/fa";
import { Tooltip } from "antd";
import "antd/lib/tooltip/style/index.css";
import s from "./ListActions.module.sass";

const ListActions = ({
  title,
  onBack,
  children,
  style,
  Icon = FaArrowLeft,
  additionalClassnames,
  additionalTitleClassname,
}) => (
  <div className={cn(s["list-actions"], additionalClassnames)} style={style}>
    {onBack && <Icon onClick={onBack} className={s["list-arrow"]} />}
    {title && (
      <Tooltip title={title} placement="bottom">
        <h3 className={cn(s.title, additionalTitleClassname)}>{title}</h3>
      </Tooltip>
    )}
    {children}
  </div>
);

export default ListActions;
