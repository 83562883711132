import React from "react";
import MediaQuery from "react-responsive";
import s from "./PageHeader.module.sass";
import { Search, Results } from "..";
import { Logotype, NavMenu, SearchIcon } from "./modules/NavMenu";
import MobileSidebar from "./modules/MobileSidebar";
import { OutsideAlerter } from "../shared";

const PageHeader = ({
  searchData: {
    query, data, isLoading, isSorting, searchListIsVisible,
  },
  handleInputChange,
  handleFocusChange,
  toggleMenu,
  ui,
  isFrontPage,
  isSearchPage,
  isCompanyPage,
  displaySearchList,
  auth,
  signinUser,
  signupUser,
  signoutUser,
  clientInfo,
  fullAccessValidTo,
  fullAccess,
  potBalance,
  history,
}) => (
  <header className={s["page-header"]}>
    <MediaQuery query="(max-width: 768px)">
      <MobileSidebar
        toggleMenu={toggleMenu}
        open={ui.menuIsOpen}
        signinUser={signinUser}
        signoutUser={signoutUser}
        signupUser={signupUser}
        clientInfo={clientInfo}
        potBalance={potBalance}
        fullAccessValidTo={fullAccessValidTo}
        fullAccess={fullAccess}
        {...auth}
      />
    </MediaQuery>
    <Logotype s={s} />
    <MediaQuery query="(max-width: 768px)">
      <SearchIcon s={s} />
    </MediaQuery>
    <MediaQuery query="(min-width: 769px)">
      {!isFrontPage && (
        <div className={s["search"]}>
          <Search
            name="search"
            placeholder="Sök företag, org.nr etc."
            value={query}
            onChange={handleInputChange}
            onFocus={handleFocusChange}
            bgClassName={s["search-bar__bg"]}
            className={s["search-bar__input"]}
            wrapperClassName={s["search-bar"]}
            iconClassName={s["search-bar__icon"]}
            isLoading={isLoading}
            isSorting={isSorting}
            loaderColor="#095587"
            autoComplete="off"
          />
          {!isSearchPage && !isCompanyPage && (
            <OutsideAlerter className={s.resultsWrapper} onClick={() => displaySearchList(false)} active={searchListIsVisible}>
              <Results
                results={data}
                show={searchListIsVisible}
                displaySearchList={displaySearchList}
                className={s.results}
              />
            </OutsideAlerter>
          )}
        </div>
      )}
      <NavMenu
        s={s}
        signinUser={signinUser}
        signoutUser={signoutUser}
        signupUser={signupUser}
        clientInfo={clientInfo}
        potBalance={potBalance}
        history={history}
        fullAccessValidTo={fullAccessValidTo}
        fullAccess={fullAccess}
        {...auth}
      />
    </MediaQuery>
  </header>
);

export default PageHeader;
