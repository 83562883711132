import React from "react";
import { DetailsPrefilled, DetailsMissing } from "./modules";
import s from "./InvoiceDetails.module.sass";

const InvoiceDetails = ({
  canSubmit = false, openModal, companyInfo, companyName,
}) => (
  <div className={s.wrapper}>
    <h3 className={s.title}>Faktureringsuppgifter</h3>
    <div className={s.companyName}>{companyName}</div>

    <div className={s.content}>
      <DetailsPrefilled show={canSubmit} openModal={openModal} companyInfo={companyInfo} />
      <DetailsMissing show={!canSubmit} openModal={openModal} />
    </div>

  </div>
);

export default InvoiceDetails;
