import React from "react";
import s from "./Spinner.module.sass";

const Spinner = () => (
  <div className={s.spinner}>
    <div />
    <div />
    <div />
  </div>
);

export default Spinner;
