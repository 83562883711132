import React from "react";
import findBiggestGain from "../../../../helpers/findBiggestGain";
import s from "./Featured.module.sass";

const mockData = [
  { key: "antalAnställda", title: "Antal anställda", data: [13, 10] },
  { key: "omsättning", title: "Omsättning", data: [20403, 16541] },
  { key: "resultat", title: "Resultat", data: [491, 2137] },
  { key: "likviditet", title: "Likviditet", data: [116.2, 163.7] },
  { key: "omsättningPerAnställd", title: "Omsättning per anställd", data: [1569, 1654] },
  { key: "soliditet", title: "Soliditet", data: [16.5, 41.2] },
];

const Featured = ({
  antalAnställda,
  antalAnställda2,
  omsättning,
  omsättning2,
  resultat,
  resultat2,
  kassalikviditet,
  kassalikviditet2,
  omsättningPerAnställd,
  omsättningPerAnställd2,
  soliditet,
  soliditet2,
}) => {
  const formattedData = [
    { key: "antalAnställda", title: "Antal anställda", data: [antalAnställda, antalAnställda2] },
    { key: "omsättning", title: "Omsättning", data: [omsättning, omsättning2] },
    { key: "resultat", title: "Resultat", data: [resultat, resultat2] },
    { key: "likviditet", title: "Likviditet", data: [kassalikviditet, kassalikviditet2] },
    { key: "omsättningPerAnställd", title: "Omsättning per anställd", data: [omsättningPerAnställd, omsättningPerAnställd2] },
    { key: "soliditet", title: "Soliditet", data: [soliditet, soliditet2] },
  ];

  const { title, gain } = findBiggestGain(formattedData) || { title: "Loading...", gain: 0 };
  const result = gain.toLocaleString("en", { style: "percent" });

  /* Featured is list selection import */
  return (
    <div className={s.container}>
      <div className={s.feature}>
        Vill du importera ett företagsurval till dina listor?
      </div>
      <a href="https://www.marknadsinformation.se/kontakt" target="_blank" rel="noopener noreferrer" className={s["contact__link"]}>
        kontakta oss
      </a>
    </div>
  );

  // return (
  //   <div className={s.container}>
  //     <div className={s.feature}>
  //       {gain ? (
  //         <>
  //           <div className={s.title}>{title}</div>
  //           <div className={s.value}>
  //             <div>Up by</div>
  //             <div className={s.result}>{result}</div>
  //           </div>
  //         </>
  //       ) : <div>No gains</div>}
  //     </div>
  //     <div className={s.closingWords}>since last year</div>
  //   </div>
  // );
};

export default Featured;
