import React from "react";
import { Radio } from "antd";
import s from "./AmountRadio.module.sass";
import "antd/lib/radio/style/index.css";

const renderAmountRadio = ({
  input,
  id,
  label,
  type,
  className,
  meta: { touched, error, warning },
}) => (
  <label className={[s["amount-radio__label"], className].join(" ")} htmlFor={id}>
    <Radio {...input} placeholder={label} className={s["amount-radio__input"]} type={type} id={id} />
    {label}
    {touched
      && ((error && <span>{error}</span>)
        || (warning && <span>{warning}</span>))}
  </label>
);

export default renderAmountRadio;
