import React from "react";
import cn from "classnames";

const ChartTitle = ({
  label, value, trending, s,
}) => (
  <div className={s["chart-title__container"]}>
    <div className={s["chart-title__label"]}>{label}</div>
    <div className={cn(s["chart-title__value"], { [s["chart-title__declining"]]: !trending })}>{value}</div>
  </div>
);

export default ChartTitle;
