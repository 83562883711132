import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { actionCreators as ui } from "../../store/Ui";
import { actionCreators as search } from "../../store/Search";
import { actionCreators as auth } from "../../store/Auth";
import { actionCreators as modalActionCreators } from "../../store/Modal";
import { actionCreators as user } from "../../store/User";
import PageHeaderContainer from "./PageHeaderContainer";

const mapStateToProps = (state) => ({
  ui: state.ui,
  searchData: state.searchData,
  auth: state.auth,
  potBalance: state.user.potBalance,
  clientInfo: state.user.clientInfo,
  authenticated: state.auth.authenticated,
  fullAccess: state.user.fullAccess,
  fullAccessValidTo: state.user.fullAccessValidTo,
  shouldOpenDescription: state.user.showCompanyInfoDialog,
});

const mapActionCreators = {
  ...ui,
  ...search,
  ...auth,
  ...user,
  ...modalActionCreators,
};

const withRedux = connect(
  mapStateToProps,
  mapActionCreators,
);

const withReduxAndRouter = (C) => withRouter(withRedux(C));

export default withReduxAndRouter(PageHeaderContainer);
