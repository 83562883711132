import React, { PureComponent } from "react";
import {
  LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, Legend,
} from "recharts";
import formatNumber from "../../../../helpers/formatNumber";

export default class LineChartGraph extends PureComponent {
  state = {
    margin: {
      top: 20,
      right: 20,
      left: 20,
      bottom: 20,
    },
  };

  formatTooltip = (value) => {
    if (this.props.unit === "%") {
      return `${formatNumber(value, "percent")} ${this.props.unit || ""}`;
    }
    return `${formatNumber(value, "space")} ${this.props.unit || ""}`;
  }

  formatYAxis = (value) => {
    if (this.props.unit === "%") {
      return formatNumber(value, "percent");
    }
    if (this.props.unit === "TKR") {
      return `${formatNumber(value / 1000)} `;
    }
    return formatNumber(value, "space");
  }

  render() {
    const {
      data, unit, name, s,
    } = this.props;
    const { margin } = this.state;

    return (
      <ResponsiveContainer className={s["chart__container"]}>
        <LineChart data={data} margin={margin}>
          <CartesianGrid strokeDasharray="3 3" vertical={false} />
          <XAxis
            dataKey="date"
            padding={{ left: 40, right: 40 }}
            tickMargin={20}
            height={60}
            axisLine={false}
            tickLine={false}
          />
          <YAxis
            orientation="right"
            unit={unit === "TKR" ? "mkr" : unit}
            width={unit === "TKR" ? 85 : 60}
            tickFormatter={this.formatYAxis}
            padding={{ top: 40 }}
            axisLine={false}
            tickLine={false}
          />
          <Tooltip formatter={this.formatTooltip} />
          <Legend iconSize={30} iconType="circle" />
          <Line type="linear" dataKey="average" name={name} strokeWidth={3} stroke="#23a843" />
          <Line type="linear" dataKey="branch" name="Branschsnitt" strokeWidth={3} stroke="#095587" />
        </LineChart>
      </ResponsiveContainer>
    );
  }
}
