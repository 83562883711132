import React, { Component } from "react";
import { Helmet } from "react-helmet";
import { Home, Feature, Footer } from "../../components";
import { PriceListContainer } from "..";
import s from "./HomePage.module.sass";

class HomePage extends Component {
  componentDidMount() {
    this.props.displaySearchList(false);

    if (this.props.query === "") {
      this.props.clearSearchResults();
    }
  }

  handleInputChange = ({ target }) => {
    const { sort, to } = this.props.searchData;
    this.props.requestCompanySearch(target.value, sort, 0, to);
  };

  handleFocusChange = () => {
    if (this.props.searchData.hasSearchData) {
      this.props.displaySearchList(true);
    }
  };

  render() {
    const { openModal } = this.props;

    return (
      <>
        <main className={s.wrapper}>
          <Home {...this.props} handleInputChange={this.handleInputChange} handleFocusChange={this.handleFocusChange} />
        </main>
        <Feature />
        <PriceListContainer isHome />
        <Footer openModal={openModal} />
        <Helmet>
          <title>SökaFöretag</title>
        </Helmet>
      </>
    );
  }
}

export default HomePage;
