import React from "react";
import { Link } from "react-router-dom";
import { MdSearch } from "react-icons/md";

const SearchIcon = ({ s }) => (
  <Link to="/search" className={s["media-search-link"]}>
    <MdSearch color="white" className={s["media-search-icon"]} />
  </Link>
);

export default SearchIcon;
