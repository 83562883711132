import React from "react";
import s from "./Content.module.sass";

const Content = (props) => (
  <section className={s.container}>
    <div className={s.grid} style={props.style}>{props.children}</div>
  </section>
);

export default Content;
