import React from "react";
import { Field, FormSection } from "redux-form";
import horizontalFormInput from "./horizontalFormInput";
import s from "../AddDescriptionModal.module.sass";
import { Button } from "../../../shared";
import { Tooltip } from 'antd';

const CompanyDescription = () => (
  <FormSection name="companyInfo" className={s['form-field-holder']}>
    <Field
      className={s['input']}
      name="shortDescription"
      component={horizontalFormInput}
      placeholder="Verksamhetsområde (max två ord)"
      label="Exempel: CRM leverantör, Djurtransport, Reparatör hushållsmaskiner etc."
    />
    <Field
      className={s['text-area']}
      name="longDescription"
      component={horizontalFormInput}
      placeholder="Företagsbeskrivning"
      label="Beskriv mer utförligt er tjänst/produkt och vad som utmärker ert företag. Max 160 tecken"
      renderAs="textarea"
    />
    <Tooltip placement="bottom" overlayClassName={s['tooltip-overlay']} title={<><p>Vi kommer framöver att utveckla en funktion där användaren kan söka efter specifika tjänster/varor.<br />Välj därför det sökord som bäst speglar er verksamhet. När användaren söker på er verksamhet presenteras er företagsbeskrivning.<br />Förhoppningsvis kommer det att ge er nya kunder!</p><p>För mer information, ring 08-514 905 90</p></>}>
      <Button className={s['hover-tooltip']}>Vad används informationen till?</Button>
    </Tooltip>
  </FormSection>
)

export default CompanyDescription;
