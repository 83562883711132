import { SubmissionError } from "redux-form";
import moment from "moment";
import axios from "./api/axios";
import { Auth0, actionCreators as authActionCreators } from "./Auth";
import message from "../components/shared/Message";

import { actionCreators as modalActionCreators } from "./Modal";
import { alertMessages } from "../helpers/constants";

const GET_USER = "GET_USER";
const GET_USER_PENDING = "GET_USER_PENDING";
const GET_USER_REJECTED = "GET_USER_REJECTED";
const GET_USER_FULFILLED = "GET_USER_FULFILLED";

const UPDATE_USER = "UPDATE_USER";
const UPDATE_USER_PENDING = "UPDATE_USER_PENDING";
const UPDATE_USER_FULFILLED = "UPDATE_USER_FULFILLED";
const UPDATE_USER_REJECTED = "UPDATE_USER_REJECTED";

const CHECK_FOR_NEW_BALANCE = "CHECK_FOR_NEW_BALANCE";
const CHECK_FOR_NEW_BALANCE_PENDING = "CHECK_FOR_NEW_BALANCE_PENDING";
const CHECK_FOR_NEW_BALANCE_REJECTED = "CHECK_FOR_NEW_BALANCE_REJECTED";
const CHECK_FOR_NEW_BALANCE_FULFILLED = "CHECK_FOR_NEW_BALANCE_FULFILLED";

const UPDATE_POT_BALANCE = "UPDATE_POT_BALANCE";
const UPDATE_POT_BALANCE_PENDING = "UPDATE_POT_BALANCE_PENDING";
const UPDATE_POT_BALANCE_FULFILLED = "UPDATE_POT_BALANCE_FULFILLED";
const UPDATE_POT_BALANCE_REJECTED = "UPDATE_POT_BALANCE_REJECTED";

const SET_USER_AUTH0_DATA = "SET_USER_AUTH0_DATA";
const CLEAR_USER_DATA = "CLEAR_USER_DATA";
const START_USER_LOADING = "START_USER_LOADING";
const STOP_USER_LOADING = "STOP_USER_LOADING";
const SET_CHECK_NEW_BALANCE_LOADING = "SET_CHECK_NEW_BALANCE_LOADING";

const initialState = { isLoading: false, isFetchingNewBalance: false, clientInfo: {} };

export const actionCreators = {
  getUser: () => async (dispatch, getState) => {
    const { diamonds_user_id } = getState().user.clientInfo["https://sökaföretag.se/app_metadata"] || {};
    const url = `/user?diamondsUserId=${diamonds_user_id}`;

    dispatch({
      type: GET_USER,
      payload: {
        promise: axios.get(url),
      },
    });
  },
  updateUser: (userData) => async (dispatch, getState) => {
    const { diamonds_user_id } = getState().user.clientInfo["https://sökaföretag.se/app_metadata"] || {};
    const url = "/user";
    const body = { diamondsUserId: diamonds_user_id, ...userData };

    const { response } = await dispatch({
      type: UPDATE_USER,
      payload: {
        promise: axios.put(url, body),
      },
    });

    if (response && response.status !== 200) {
      message.error(response.data || alertMessages.NO_MESSAGE_FALLBACK, 3);
    } else {
      message.success(alertMessages.COMPANY_INFO_UPDATED, 3);
      dispatch(modalActionCreators.closeModal());
    }
  },
  updatePotBalance: (data) => async (dispatch) => {
    const url = "/user/potbalance";
    const body = { ...data };

    const { response } = await dispatch({
      type: UPDATE_POT_BALANCE,
      payload: {
        promise: axios.put(url, body),
      },
    });

    if (response && response.status !== 200) {
      let errorMessage;
      if (response.status === 402) {
        errorMessage = "Det här kontot är ej godkänt för fakturering, vänligen kontakta oss";
      } else {
        errorMessage = "Någonting gick fel vid köp av pott. Vänligen kontakta support ifall problemet kvarstår.";
      }

      message.error(`${alertMessages.POT_REFILL_FAIL} ${errorMessage}`, 5);
      throw new SubmissionError({ _error: errorMessage });
    } else {
      message.success(alertMessages.POT_REFILL_SUCCESS, 5);
    }
  },
  checkForNewPotBalance: () => async (dispatch, getState) => {
    const { diamonds_user_id } = getState().user.clientInfo["https://sökaföretag.se/app_metadata"] || {};
    const { lastPurchaseTimestamp } = getState().user;
    const url = `/user?diamondsUserId=${diamonds_user_id}`;

    const { value } = await dispatch({
      type: CHECK_FOR_NEW_BALANCE,
      payload: {
        promise: axios.get(url),
      },
    });

    const newTimestamp = moment(value.data.lastPurchaseTimestamp);
    const isOlder = moment(newTimestamp).isAfter(lastPurchaseTimestamp);

    if (isOlder) {
      message.success(`${alertMessages.POT_REFILL_SUCCESS} Aktuellt pottvärde: ${value.data.potBalance}`, 5);
      dispatch(actionCreators.setCheckNewBalanceLoading(false));
    } else {
      setTimeout(() => {
        dispatch(actionCreators.checkForNewPotBalance());
      }, 5000);
    }
  },
  setAuth0ClientInfo: (clientInfo) => (dispatch) => {
    dispatch({
      type: SET_USER_AUTH0_DATA,
      payload: clientInfo,
    });
  },
  clearUserData: () => (dispatch) => {
    dispatch({ type: CLEAR_USER_DATA });
  },
  refreshSession: () => async (dispatch) => {
    try {
      dispatch({ type: START_USER_LOADING });

      await Auth0.refreshSession();
      dispatch(actionCreators.setAuth0ClientInfo(Auth0.getClientInfo()));
    } catch (e) {
      console.error("Error refreshing session: ", e);
    } finally {
      dispatch({ type: STOP_USER_LOADING });
    }
  },
  didSessionExpire: () => async (dispatch) => {
    try {
      const token = Auth0.getAccessToken();
      const result = Auth0.validateAccessToken(token);

      if (result === 13) {
        await message.error(alertMessages.SESSION_EXPIRED);
        throw alertMessages.SESSION_EXPIRED;
      }
    } catch (e) {
      dispatch(authActionCreators.signoutUser());
      dispatch(actionCreators.clearUserData());
      dispatch(authActionCreators.signinUser());
    }
  },
  setCheckNewBalanceLoading: (flag) => ({ type: SET_CHECK_NEW_BALANCE_LOADING, payload: flag }),
};

export const reducer = (state = initialState, action) => {
  if (action.type === GET_USER_PENDING) {
    return {
      ...state,
      isLoading: true,
    };
  }

  if (action.type === GET_USER_REJECTED) {
    return {
      ...state,
      errorMessage: action.payload.message,
      errorCode: action.payload.response.status,
      isLoading: false,
    };
  }
  if (action.type === GET_USER_FULFILLED) {
    return {
      ...state,
      ...action.payload.data,
      isLoading: false,
    };
  }

  if (action.type === UPDATE_USER_PENDING) {
    return {
      ...state,
      isLoading: true,
    };
  }

  if (action.type === UPDATE_USER_REJECTED) {
    return {
      ...state,
      errorMessage: action.payload.message,
      errorCode: action.payload.response.status,
      isLoading: false,
    };
  }
  if (action.type === UPDATE_USER_FULFILLED) {
    return {
      ...state,
      ...action.payload.data,
      isLoading: false,
    };
  }

  if (action.type === CHECK_FOR_NEW_BALANCE_PENDING) {
    return {
      ...state,
      isFetchingNewBalance: true,
    };
  }

  if (action.type === CHECK_FOR_NEW_BALANCE_REJECTED) {
    return {
      ...state,
      isFetchingNewBalance: false,
      errorMessage: action.payload.message,
      errorCode: action.payload.response.status,
    };
  }
  if (action.type === CHECK_FOR_NEW_BALANCE_FULFILLED) {
    return {
      ...state,
      ...action.payload.data,
    };
  }

  if (action.type === SET_CHECK_NEW_BALANCE_LOADING) {
    return {
      ...state,
      isFetchingNewBalance: action.payload,
    };
  }

  if (action.type === UPDATE_POT_BALANCE_PENDING) {
    return {
      ...state,
      isLoading: true,
    };
  }

  if (action.type === UPDATE_POT_BALANCE_REJECTED) {
    return {
      ...state,
      errorMessage: action.payload.message,
      errorCode: action.payload.response.status,
      isLoading: false,
    };
  }
  if (action.type === UPDATE_POT_BALANCE_FULFILLED) {
    return {
      ...state,
      ...action.payload.data,
      isLoading: false,
    };
  }

  if (action.type === SET_USER_AUTH0_DATA) {
    return {
      ...state,
      clientInfo: action.payload,
    };
  }
  if (action.type === CLEAR_USER_DATA) {
    return initialState;
  }
  if (action.type === START_USER_LOADING) {
    return { ...state, isLoading: true };
  }
  if (action.type === STOP_USER_LOADING) {
    return { ...state, isLoading: false };
  }

  return state;
};
