import React from "react";
import LoadingOverlay from "react-loading-overlay";
import { MdClose } from "react-icons/md";
import s from "./AddDescriptionModal.module.sass";
import Form from "./modules/Form";

class AddDescriptionModal extends React.Component {
  componentDidMount() {
    this.props.requestCompanyInfo();
  }

  render() {
    const { isLoading, closeModal, ...rest } = this.props;

    const Header = () => (
      <div className={s.header}>
        <span className={s["header__title"]}>Berätta lite mer om ert företag!</span>
        <MdClose onClick={closeModal} className={s["header__icon"]} />
      </div>
    );

    return (
      <LoadingOverlay active={isLoading} className={s["overlay-wrapper"]} spinner>
        <Header />
        <Form {...rest} closeModal={closeModal} />
      </LoadingOverlay>
    );
  }
}

export default AddDescriptionModal;
