import React, { Component } from "react";
import LoadingOverlay from "react-loading-overlay";
import { Helmet } from "react-helmet";
import _isUndefined from "lodash/isUndefined";
import { Company } from "../../components";
import permalink from "../../helpers/permalink";
import decodeURLParams from "../../helpers/decodeURLParams";
import s from "./CompanyPage.module.sass";

class CompanyPage extends Component {
  componentDidMount() {
    const { orgnr, cfarnr } = this.props.match.params;
    const { authenticated, location, companyData } = this.props;
    const orgnrFromState = companyData.data.orgnr;

    if (authenticated) {
      this.props.requestMyLists();
    }

    if ((location.state && location.state.fromSector) || (orgnr === orgnrFromState && !_isUndefined(orgnrFromState))) {
      return;
    }
    this.props.requestCompany(orgnr, cfarnr);

    const { query } = decodeURLParams(this.props.location.search);
    if (query) {
      this.props.requestCompanySearch(query);
    }
  }

  componentDidUpdate(prevProps) {
    const { orgnr: orgnrNext, cfarnr: cfarnrNext, namn: namnNext } = this.props.match.params;
    const { orgnr, cfarnr } = prevProps.match.params;
    const { shouldUpdateName = true } = prevProps;
    const companyName = this.props.companyData.data.namn;
    const companyCfarnr = this.props.companyData.data.cfarnr;

    if (!namnNext && companyName && orgnrNext !== "000000-0000" && shouldUpdateName) {
      this.props.history.push(`/company/${orgnrNext}/${permalink(companyName)}/${cfarnrNext || companyCfarnr || ""}`);
    }

    if (orgnr !== orgnrNext || cfarnr !== cfarnrNext) {
      this.props.requestCompany(orgnrNext, cfarnrNext);
      window.scrollTo({ top: 0, behavior: "smooth" });
    }

    if (
      prevProps.location.state
      && prevProps.location.state.fromSector
      && this.props.searchData.customSearchData.type === "sector"
      && this.props.searchData.customSearchData.data.antalTräff
    ) {
      const sectorCompany = this.props.searchData.customSearchData.data.träfflista[0];
      if (!prevProps.location.state.isMobile) {
        this.props.selectCompany(sectorCompany.miseId);
        this.props.history.push(`/company/${sectorCompany.orgnr}/${permalink(sectorCompany.namn)}`);
      }
    }
  }

  handleSortChange = (sort) => {
    const { to, query, sort: oldSort } = this.props.searchData;
    if (sort !== oldSort) {
      this.props.requestCompanySearch(query, sort, 0, to);
    }
  };

  handleInputChange = ({ target: { value: query } }) => {
    const { sort } = this.props.searchData;
    this.props.requestCompanySearch(query, sort);
  };

  handlePageChange = () => {
    const {
      limit, query, sort, page,
    } = this.props.searchData;
    const from = page * limit;
    const to = from + limit;
    this.props.requestCompanySearch(query, sort, from, to, true);
  };

  render() {
    const {
      companyData,
      searchData,
      singleListData,
      displaySearchList,
      authenticated,
      location,
      ...rest
    } = this.props;

    const searchProps = {
      searchData,
      displaySearchList,
      handleInputChange: this.handleInputChange,
      handleSortChange: this.handleSortChange,
      handlePageChange: this.handlePageChange,
    };

    return (
      <main className={s.wrapper}>
        <LoadingOverlay active={companyData.isLoading} className={s["overlay-wrapper"]} spinner>
          <Company
            company={companyData.data}
            unlockedFields={companyData.unlockedFields.data}
            searchProps={searchProps}
            singleListData={singleListData}
            authenticated={authenticated}
            pathname={location.pathname}
            {...location.state}
            {...rest}
          />
        </LoadingOverlay>
        <Helmet>
          <title>{`SökaFöretag - ${companyData.data.namn || "företagssida"}`}</title>
        </Helmet>
      </main>
    );
  }
}

export default CompanyPage;
