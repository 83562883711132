import React from "react";
import { Field } from "redux-form";
import { StripeCardComponent } from "../../..";
import { AmountRadio } from "..";
import s from "./PaymentCard.module.sass";

const PaymentCard = ({
  setEmail,
  setZipcode,
  payment: { email, zipcode },
}) => (
  <div className={s.wrapper}>
    <h3 className={s.title}>Kortbetalning</h3>
    <div className={s.content}>
      <StripeCardComponent
        email={email}
        setEmail={setEmail}
        zipcode={zipcode}
        setZipcode={setZipcode}
      />
    </div>
  </div>
);

export default PaymentCard;
