import React, { useState } from "react";
import PropTypes from "prop-types";
import { MdLock } from "react-icons/md";
import uuid4 from "uuid/v4";
import Button from "../Button";
import { modalNames } from "../../../helpers/constants";
import s from "./HiddenField.module.sass";

const HiddenField = ({
  children,
  name = "field",
  fieldName,
  data: { befattning, execName } = {},
  miseId,
  authenticated,
  unlocked,
  Icon = MdLock,
  openModal,
  unlockField,
  isMobile,
  activeButtonId,
}) => {
  const [buttonId] = useState(uuid4());

  if (!authenticated) {
    return (
      <Button primary big className={s["hiddenField__button"]} onClick={() => openModal(modalNames.LOGIN_SEE_CONTACTS)}>
        <Icon className={s["hiddenField--icon"]} />
        <span className={s["hiddenField--label"]}>
          Visa
          {" "}
          {name}
        </span>
      </Button>
    );
  }

  const unlockHandler = () => (isMobile
    ? openModal(modalNames.CONFIRM_PURCHASE, {
      miseId, fieldName, befattning, execName,
    })
    : unlockField(miseId, fieldName, befattning, buttonId));

  if (!unlocked) {
    return (
      <Button
        loading={activeButtonId === buttonId}
        onClick={unlockHandler}
        className={s["hiddenField__button"]}
        primary
        big
      >
        <Icon className={s["hiddenField--icon"]} />
        <span className={s["hiddenField--label"]}>
          Visa
          {" "}
          {name}
        </span>
      </Button>
    );
  }

  return <div>{children}</div>;
};

HiddenField.propTypes = {
  name: PropTypes.string,
  fieldName: PropTypes.string,
  data: PropTypes.object,
  authenticated: PropTypes.bool.isRequired,
  miseId: PropTypes.number.isRequired,
  unlocked: PropTypes.bool.isRequired,
  isMobile: PropTypes.bool,
  openModal: PropTypes.func.isRequired,
  unlockField: PropTypes.func.isRequired,
  unlockedFields: PropTypes.object,
  Icon: PropTypes.oneOfType([PropTypes.element, PropTypes.func]),
};

export default HiddenField;
