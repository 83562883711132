import { connect } from "react-redux";
import HomePage from "./HomePage";
import { actionCreators as searchActionCreators } from "../../store/Search";
import { actionCreators as authActionCreators } from "../../store/Auth";
import { actionCreators as modalActionCreators } from "../../store/Modal";

const mapStateToProps = (state) => ({
  searchData: state.searchData,
  query: state.searchData.query,
});

const mapActionCreators = {
  ...searchActionCreators,
  ...authActionCreators,
  ...modalActionCreators,
};

const withRedux = connect(
  mapStateToProps,
  mapActionCreators,
);

export default withRedux(HomePage);
