import { connect } from "react-redux";
import { actionCreators as myListsActionCreators } from "../../../store/MyLists";
import { actionCreators as modalActionCreators } from "../../../store/Modal";
import CreateListModal from "./CreateListModal";

const mapStateToProps = (state) => ({
  isLoading: state.myListsData.isLoading || state.singleListData.isLoading,
});

const mapActionCreators = {
  ...myListsActionCreators,
  ...modalActionCreators,
};

const withRedux = connect(
  mapStateToProps,
  mapActionCreators,
);

export default withRedux(CreateListModal);
