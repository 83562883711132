import React from "react";
import { MdPerson, MdClose } from "react-icons/md";
import { Button } from "../../shared";
import { modalNames } from "../../../helpers/constants";
import s from "./LoginModal.module.sass";

const LoginModal = ({
  signinUser, signupUser, type, closeModal,
}) => {
  const Header = () => (
    <div className={s["modal-header"]}>
      <h2 className={s["modal-title"]}>
        Logga in för att
        {" "}
        {type === modalNames.LOGIN_SEE_CONTACTS
          ? "se namn och kontaktuppgifter till beslutsfattare"
          : type === modalNames.LOGIN_ADD_TO_LIST
            ? "lägga till detta kort till din egna skräddarsydda lista"
            : null}
      </h2>
      <MdClose onClick={closeModal} className={s["header__icon"]} />
    </div>
  );

  const Content = () => (
    <>
      <p className={s["modal-text"]}>
      Skapa ett gratiskonto för att ta del av
        {" "}
        <strong className={s["modal-strong"]}>5 pottvärden per dag helt utan kostnad.</strong>
      </p>
      <p>
        Pottvärdern kan användas till att se namn och kontaktuppgifter till beslutsfattare.
        Uppgradera enkelt ditt konto när som helst om du skulle behöva visa fler kontaktuppgifter per dag.
      </p>
    </>
  );

  const Buttons = () => (
    <>
      <Button onClick={signupUser} Icon={MdPerson} big additionalClass={s["light-blue"]}>
        Skapa konto
      </Button>
      <Button onClick={signinUser} additionalClass={s["white"]}>
        Logga in
      </Button>
    </>
  );

  return (
    <div className={s["modal-wrapper"]}>
      <Header />
      <Content />
      <Buttons />
    </div>
  );
};

export default LoginModal;
