import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { compose } from "recompose";
import { actionCreators as myListsActionCreators } from "../../store/MyLists";
import { actionCreators as uiActionCreators } from "../../store/Ui";
import { actionCreators as companyActionCreators } from "../../store/Company";
import { actionCreators as modalActionCreators } from "../../store/Modal";
import SingleListContainer from "./SingleListContainer";

const mapStateToProps = (state) => ({
  singleListData: state.singleListData,
  singleListName: (state.myListsData.data.find((list) => list.id === state.singleListData.data.listId) || {}).name,
  selectedCompanyId: state.singleListData.selectedCompanyId,
});

const mapActionCreators = {
  ...myListsActionCreators,
  ...uiActionCreators,
  ...companyActionCreators,
  ...modalActionCreators,
};

export default compose(
  connect(
    mapStateToProps,
    mapActionCreators,
  ),
  withRouter,
)(SingleListContainer);
