import React from "react";
import { MdFormatListBulleted } from "react-icons/md";
import s from "./ListPlaceholder.module.sass";

const ListPlaceholder = () => (
  <div className={s.container}>
    <MdFormatListBulleted className={s.icon} />
    <h2 className={s.title}>Välj ett företag för att visa det här</h2>
    <ul className={s.list}>
      <li className={s["list-item"]}>
        <span>1.</span>
        <span>Sök efter företag i sökrutan.</span>
      </li>
      <li className={s["list-item"]}>
        <span>2.</span>
        <span>Spara intressanta företag till &quot;mina listor&quot;</span>
      </li>
      <li className={s["list-item"]}>
        <span>3.</span>
        <span>Öppna din lista och få snabbåtkomst till dina företag</span>
      </li>
    </ul>
    <p>
      Kontakta oss om du vill importera en prospektlista.
      <br />
      <a
        href="https://urval.xn--fretagskontakt-vpb.se/"
        target="_blank"
        rel="noopener noreferrer"
        className={s.link}
      >
        Välj din egen målgrupp.
      </a>
    </p>
  </div>
);

export default ListPlaceholder;
