import React from "react";
import s from "./Feature.module.sass";
import { ReactComponent as DecisionmakersSvg } from "../../assets/images/decisionmakers.svg";
import { ReactComponent as GraphSvg } from "../../assets/images/graph.svg";
import { ReactComponent as GrowthSvg } from "../../assets/images/growth.svg";
import { ReactComponent as ListSvg } from "../../assets/images/mylist.svg";

const Feature = () => (
  <div className={s.container}>
    <h1 className={s["feature-header"]}>Funktioner som gör försäljningen enklare</h1>
    <div className={s["feature"]}>
      <div className={s["feature-item"]}>
        <h2 className={s["feature-item__text-header"]}>Hitta nya kunder snabbt</h2>
        <p>
          Vem vill du nå? Sök enkelt bland
          {" "}
          <strong>alla Sveriges bolag</strong>
          . Med
          {" "}
          <strong>branschens bästa täckning</strong>
          {" "}
          av beslutsfattare når ert budskap rätt person.
        </p>
      </div>
      <DecisionmakersSvg className={`${s["feature-item"]} ${s["feature-image"]} ${s["feature-item--centered"]} ${s["feature-item--shadowed"]}`} />
      <div className={s["feature-item"]}>
        <h2 className={s["feature-item__text-header"]}>Se grafer över nyckeltalsförändringar</h2>
        <p>Med hjälp av grafer får du snabbt en överblick över bolagens förändring över år. Studera viktiga nyckeltal såsom omsättning, resultat, antal anställda och tillväxt.</p>
      </div>
      <GraphSvg className={`${s["feature-item"]} ${s["feature-image"]} ${s["feature-item--third-area"]} ${s["feature-item--centered"]} ${s["feature-item--shadowed"]}`} />
      <div className={s["feature-item"]}>
        <h2 className={s["feature-item__text-header"]}>Hitta enkelt expansiva bolag med god tillväxt</h2>
        <p>Hitta expansiva bolag med hjälp av SökaFöretags helt unika nyckeltal för tillväxt. Algoritmen hjälper dig att snabbt få en insikt i bolagets status.</p>
      </div>
      <GrowthSvg className={`${s["feature-item"]} ${s["feature-image"]} ${s["feature-item--shadowed"]}`} />
      <div className={s["feature-item"]}>
        <h2 className={s["feature-item__text-header"]}>Mina listor</h2>
        <p>Skapa enkelt listor med de mest intressanta företagen du vill kontakta.</p>
      </div>
      <ListSvg className={`${s["feature-item"]} ${s["feature-image"]} ${s["feature-item--seventh-area"]} ${s["feature-item--centered"]} ${s["feature-item--shadowed"]}`} />
    </div>
  </div>
);

export default Feature;
