import React from "react";
import cn from "classnames";
import { Spinner } from "../../../shared";
import s from "./PotBalance.module.sass";

const PotBalance = ({ potBalance = 0, isLoading, isFetchingNewBalance }) => (
  <div className={cn(s["pot-specification__current-pot"], { [s.loading]: isLoading || isFetchingNewBalance })}>
    <p className={s["current-pot__header"]}>Aktuellt pottvärde</p>
    {(isLoading || isFetchingNewBalance) ? (
      <Spinner />
    ) : (
      <h1 className={s["current-pot__value"]}>
        {potBalance}
        {" "}
      poster
      </h1>
    )}
  </div>
);

export default PotBalance;
