import React, { Component } from "react";
import { PageHeader } from "../../components";
import { modalNames } from "../../helpers/constants";
import s from "./PageHeaderContainer.module.sass";

class PageHeaderContainer extends Component {

  constructor(props) {
    super(props)
    this.state = {hasShownDescriptionModal:false}
  }

  handleInputChange = ({ target }) => {
    const { sort, to } = this.props.searchData;
    this.props.requestCompanySearch(target.value, sort, 0, to);
  };

  handleFocusChange = () => {
    const isCompanyPage = this.props.location.pathname.startsWith("/company");
    if (this.props.searchData.hasSearchData && !isCompanyPage) {
      this.props.displaySearchList(true);
    }
  };

  componentDidUpdate = (prevProps) => {    
    if (this.props.shouldOpenDescription && !this.state.hasShownDescriptionModal) {
      this.props.openModal(modalNames.ADD_DESCRIPTION);
      this.setState({hasShownDescriptionModal:true})
    }
  };

  render() {
    const { pathname } = this.props.location;
    const isFrontPage = pathname === "/";
    const isSearchPage = pathname === "/search";
    const isCompanyPage = pathname.startsWith("/company");

    return (
      <PageHeader
        handleInputChange={this.handleInputChange}
        handleFocusChange={this.handleFocusChange}
        isFrontPage={isFrontPage}
        isSearchPage={isSearchPage}
        isCompanyPage={isCompanyPage}
        signupUser={this.props.signupUser}
        fullAccessValidTo={this.props.fullAccessValidTo}
        fullAccess={this.props.fullAccess}
        {...this.props}
      />
    );
  }
}

export default PageHeaderContainer;
