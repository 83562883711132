import React from "react";
import PropTypes from "prop-types";
import cn from "classnames";
import { ListItem } from "./modules";
import s from "./List.module.sass";

const List = ({
  data,
  withArrow,
  withPointer,
  flex,
  paddedBorder,
  searchResults,
  labelStyles,
  valueStyles,
  columnStyles,
  additionalClassnames,
  listItemFallback = { label: "Sökningen gav inga träffar!" },
  ...rest
}) => {
  if (data.length === 0 && listItemFallback) {
    data.push(listItemFallback);
    withArrow = false;
  }
  const classNamesContainer = cn(
    s["list__container"],
    { [s["list__no-overflow"]]: searchResults },
    additionalClassnames,
  );

  return (
    <ul className={classNamesContainer} {...rest} aria-expanded="true">
      {data.map(({
        label, value, details, href, hrefProps, onClick, icon, selected, HoverIcon, foreign,
      }, i) => {
        const classNamesLabel = cn(
          s["list__label--container"],
          { [s["list__label--big"]]: details },
          { [s["list__label--results"]]: searchResults },
          { [labelStyles]: labelStyles },
        );
        const classNamesValue = cn(
          s["list__value"],
          { [valueStyles]: valueStyles },
          { [s["list__value--selected"]]: selected },
          { [s["no-pointer-events"]]: onClick && !selected },
          { [s["list__value--flex"]]: flex },
        );
        const classNamesColumn = cn(
          s["list__column"],
          { [columnStyles]: columnStyles },
          { [s["list__column--padded-border"]]: paddedBorder },
          { [s["list__column--selected"]]: selected },
          { [s["list__column--pointer"]]: withPointer },
          { [s["list__column--foreign"]]: foreign },
        );
        const classNamesInfo = cn(
          s["list__content"],
          { [s["no-pointer-events"]]: onClick },
        );
        const classNamesLink = cn(
          s["list__link--container"],
          { [s["list__link--container--results"]]: searchResults },
          { [s["list__link--container--foreign"]]: foreign },
        );
        const listItemProps = {
          s,
          label,
          value,
          details,
          href,
          hrefProps,
          classNamesLabel,
          classNamesValue,
          classNamesColumn,
          classNamesInfo,
          classNamesLink,
          withArrow,
          icon,
          HoverIcon,
          onClick,
          foreign,
          index: i,
        };
        return <ListItem key={i} {...listItemProps} />;
      })}
    </ul>
  );
};

List.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
      value: PropTypes.oneOfType([PropTypes.element, PropTypes.string, PropTypes.number]),
      icon: PropTypes.oneOfType([PropTypes.element, PropTypes.func]),
      HoverIcon: PropTypes.oneOfType([PropTypes.element, PropTypes.func]),
      details: PropTypes.oneOfType([PropTypes.element, PropTypes.object, PropTypes.string]),
      href: PropTypes.string,
      selected: PropTypes.bool,
      hrefProps: PropTypes.object,
    }),
  ),
  labelStyles: PropTypes.string,
  valueStyles: PropTypes.string,
  columnStyles: PropTypes.string,
  additionalClassnames: PropTypes.string,
  onClick: PropTypes.func,
  paddedBorder: PropTypes.bool,
  withArrow: PropTypes.bool,
  listItemFallback: PropTypes.object,
};

List.defaultProps = {
  data: [],
};

export default List;
