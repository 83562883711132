import React, { Component } from "react";
import { Helmet } from "react-helmet";
import MediaQuery from "react-responsive";
import Tree from "antd/lib/tree";
import SelectionTree from "../../components/SelectionTree/SelectionTree";
import s from "./SectorPage.module.sass";
import treeData from "./treeDataNoDuplicates.json";

const { TreeNode } = Tree;

class SectorPage extends Component {
  state = {
    expandedKeys: [],
    autoExpandParent: true,
    checkedKeys: [],
    selectedKeys: [],
  };

  componentDidMount() {
    this.props.displaySearchList(false);
  }

  onExpand = (expandedKeys) => {
    this.setState({
      expandedKeys,
      autoExpandParent: false,
    });
  };

  onSelect = (selectedKeys, selectedItem, isMobile) => {
    const hasChildren = selectedItem.node.props.eventKey.length > 0;
    const sectorTitle = selectedItem.node.props.title;

    this.setState({ selectedKeys });

    let branschKod = selectedItem.node.props.eventKey;

    if (hasChildren) {
      branschKod += "*";
    }

    if (!isMobile) {
      this.props.setLoading(true);
    }

    this.props.requestSectorSearch({ branschKod, sectorTitle });
    this.props.history.push({
      pathname: "/company/000000-0000",
      state: { fromSector: true, isMobile, globalBranschKod: branschKod },
    });
  };

  renderTreeNodes = (data) => data.map((item) => {
    if (item.children) {
      return (
        <TreeNode title={item.title} key={item.key} dataRef={item}>
          {this.renderTreeNodes(item.children)}
        </TreeNode>
      );
    }
    return <TreeNode key={item.key} {...item} />;
  });

  render() {
    return (
      <div className={s["sector-page"]}>
        <MediaQuery query="(max-width: 768px)">
          {(isMobile) => (
            <SelectionTree
              onExpand={this.onExpand}
              expandedKeys={this.state.expandedKeys}
              autoExpandParent={this.autoExpandParent}
              onSelect={(key, item) => this.onSelect(key, item, isMobile)}
              selectedKeys={this.state.selectedKeys}
              renderTreeNodes={this.renderTreeNodes}
              treeData={treeData}
            />
          )}
        </MediaQuery>
        <Helmet>
          <title>SökaFöretag - Bransch</title>
        </Helmet>
      </div>
    );
  }
}

export default SectorPage;
