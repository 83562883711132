import { debounce } from "lodash";
import axios from "./api/axios";

const FETCH_SEARCH = "FETCH_SEARCH";
const FETCH_SEARCH_PENDING = "FETCH_SEARCH_PENDING";
const FETCH_SEARCH_REJECTED = "FETCH_SEARCH_REJECTED";
const FETCH_SEARCH_FULFILLED = "FETCH_SEARCH_FULFILLED";

const FETCH_CUSTOM_SEARCH = "FETCH_CUSTOM_SEARCH";
const FETCH_CUSTOM_SEARCH_PENDING = "FETCH_CUSTOM_SEARCH_PENDING";
const FETCH_CUSTOM_SEARCH_REJECTED = "FETCH_CUSTOM_SEARCH_REJECTED";
const FETCH_CUSTOM_SEARCH_FULFILLED = "FETCH_CUSTOM_SEARCH_FULFILLED";

const SET_SEARCH_QUERY = "SET_SEARCH_QUERY";
const SET_SEARCH_LIST_VISIBILIY = "SET_SEARCH_LIST_VISIBILIY";
const SET_CUSTOM_SEARCH_LIST_VISIBILIY = "SET_CUSTOM_SEARCH_LIST_VISIBILIY";
const CLEAR_SEARCH_RESULTS = "CLEAR_SEARCH_RESULTS";

let source = axios.CancelToken.source();

const initialState = {
  data: [],
  query: "",
  isLoading: false,
  isSorting: false,
  searchListIsVisible: false,
  hasSearchData: false,
  show: false,
  infinite: false,
  sort: "",
  page: 1,
  from: 1,
  to: 20,
  limit: 20,
  searchId: 1,

  customSearchData: {
    data: { träfflista: [] },
    type: "",
    sectorTitle: "",
    isLoading: false,
    isVisible: false,
    infinite: false,
    sort: "",
    page: 1,
    from: 1,
    to: 20,
    limit: 20,
  },
};

const fetchCompanies = async ({
  dispatch, url, sort, from, to, infinite, displayCustomSearchList,
}) => {
  source.cancel();
  source = axios.CancelToken.source();

  await dispatch({
    type: FETCH_SEARCH,
    payload: {
      promise: axios.get(url, { cancelToken: source.token }),
      data: {
        sort, from, to, infinite,
      },
    },
  });

  dispatch(displayCustomSearchList(false));
};
const debouncedRequestCompanySearch = debounce(fetchCompanies, 500);

export const actionCreators = {
  clearSearchQuery: (dispatch) => {
    dispatch({
      type: SET_SEARCH_QUERY,
      payload: {
        query: "",
      },
    });
  },

  displaySearchList: (isSearchVisible) => (dispatch) => {
    dispatch({
      type: SET_SEARCH_LIST_VISIBILIY,
      payload: {
        searchListIsVisible: isSearchVisible,
      },
    });
  },

  displayCustomSearchList: (isSearchVisible) => (dispatch) => {
    dispatch({
      type: SET_CUSTOM_SEARCH_LIST_VISIBILIY,
      payload: isSearchVisible,
    });
  },

  setSearchQuery: (query) => (dispatch) => {
    dispatch({
      type: SET_SEARCH_QUERY,
      payload: { query },
    });
  },

  clearSearchResults: () => ({ type: CLEAR_SEARCH_RESULTS }),

  requestCompanySearch: (
    searchString,
    sort = "anstallda",
    from = 1,
    to = 20,
    infinite = false,
  ) => async (dispatch, getState) => {
    const url = `/search/list?searchString=${searchString}&from=${from}&to=${to}&sort=${sort}&searchId=${
      getState().searchData.searchId
    }`;

    const isCompanyPage = getState().router.location.pathname.startsWith("/company");

    if (!infinite) {
      dispatch({
        type: SET_SEARCH_QUERY,
        payload: { query: searchString },
      });
    }

    if (searchString === "" && !isCompanyPage) {
      source.cancel();
      dispatch(actionCreators.displaySearchList(false));
    }

    if (getState().searchData.query.length > 2) {
      debouncedRequestCompanySearch({
        dispatch, url, sort, infinite, displayCustomSearchList: actionCreators.displayCustomSearchList,
      });
    }
  },
  requestSubsidiariesSearch: ({
    orgnr,
    sort = "anstallda",
    from = 1,
    to = 20,
    infinite = false,
    purge = true,
  }) => async (dispatch) => {
    const url = `/search/subsidiaries?orgNo=${orgnr}&from=${from}&to=${to}&sort=${sort}`;

    dispatch({
      type: FETCH_CUSTOM_SEARCH,
      payload: {
        promise: axios.get(url),
        data: {
          sort, from, to, purge, infinite, type: "subsidiaries",
        },
      },
    });
  },

  requestSectorSearch: ({
    branschKod,
    sort = "anstallda",
    sectorTitle,
    from = 1,
    to = 20,
    infinite = false,
    purge = true,
  }) => async (dispatch) => {
    const url = `/search/sector?searchString=%27${branschKod}%27&from=${from}&to=${to}&sort=${sort}`;

    dispatch({
      type: FETCH_CUSTOM_SEARCH,
      payload: {
        promise: axios.get(url),
        data: {
          sort,
          from,
          to,
          infinite,
          purge,
          type: "sector",
          sectorTitle,
        },
      },
    });
  },
};

export const reducer = (state = initialState, action) => {
  if (action.type === SET_SEARCH_QUERY) {
    return {
      ...state,
      query: action.payload.query,
    };
  }
  if (action.type === SET_SEARCH_LIST_VISIBILIY) {
    return {
      ...state,
      searchListIsVisible: action.payload.searchListIsVisible,
    };
  }
  if (action.type === FETCH_SEARCH_PENDING) {
    return {
      ...state,
      isLoading: true,
      isSorting: state.sort !== action.payload.sort,
      sort: action.payload.sort,
      infinite: action.payload.infinite,
      searchId: state.searchId + 1,
    };
  }

  if (action.type === FETCH_SEARCH_REJECTED) {
    if (axios.isCancel(action.payload)) {
      return {
        ...state,
        isLoading: false,
        isSorting: false,
        errorMessage: "Request has been canceled",
        errorCode: 499,
      };
    }

    return {
      ...state,
      isLoading: false,
      isSorting: false,
      errorMessage: action.payload.message,
      errorCode: action.payload.response.status,
    };
  }

  if (action.type === FETCH_SEARCH_FULFILLED) {
    if (
      action.payload.data.searchId !== state.searchId - 1
      && action.payload.data.searchId !== 0
    ) {
      return { ...state, isLoading: false };
    }
    return {
      ...state,
      from: action.payload.data.intervallFrån,
      to: action.payload.data.intervallTill >= 20 ? action.payload.data.intervallTill : 20,
      data: state.infinite
        ? [...state.data, ...action.payload.data.träfflista]
        : action.payload.data.träfflista,
      page: state.infinite ? state.page + 1 : 1,
      isLoading: false,
      isSorting: false,
      hasSearchData: true,
      itemCount: action.payload.data.antalTräff,
      searchListIsVisible: true,
    };
  }

  if (action.type === FETCH_CUSTOM_SEARCH_PENDING) {
    return {
      ...state,
      customSearchData: {
        ...state.customSearchData,
        isLoading: true,
        isVisible: true,
        sort: action.payload.sort,
        sectorTitle: action.payload.sectorTitle,
        type: action.payload.type,
        infinite: action.payload.infinite,
        data: action.payload.purge
          ? initialState.customSearchData.data
          : state.customSearchData.data,
      },
    };
  }

  if (action.type === FETCH_CUSTOM_SEARCH_REJECTED) {
    return {
      ...state,
      customSearchData: {
        ...state.customSearchData,
        isLoading: false,
        isVisible: false,
        errorMessage: action.payload.message,
        errorCode: action.payload.response.status,
      },
    };
  }

  if (action.type === FETCH_CUSTOM_SEARCH_FULFILLED) {
    return {
      ...state,
      customSearchData: {
        ...state.customSearchData,
        isLoading: false,
        isVisible: true,
        from: action.payload.data.intervallFrån,
        to: action.payload.data.intervallTill >= 20 ? action.payload.data.intervallTill : 20,
        data: state.customSearchData.infinite
          ? {
            ...state.customSearchData.data,
            ...action.payload.data,
            träfflista: [
              ...state.customSearchData.data.träfflista,
              ...action.payload.data.träfflista,
            ],
          }
          : action.payload.data,
        page: state.customSearchData.infinite
          ? state.customSearchData.page + 1
          : 1,
        itemCount: action.payload.data.antalTräff,
      },
    };
  }

  if (action.type === SET_CUSTOM_SEARCH_LIST_VISIBILIY) {
    return {
      ...state,
      customSearchData: {
        ...state.customSearchData,
        isVisible: action.payload,
      },
    };
  }

  if (action.type === CLEAR_SEARCH_RESULTS) {
    return initialState;
  }

  return state;
};
