import React from "react";
import InfiniteScroll from "react-infinite-scroller";
import { SingleList } from "../../../MyLists/modules";
import filterOptions from "../../../../containers/CompanySearchList/filterOptions";
import { Filter } from "../../..";
import s from "./BranchList.module.sass";

const BranchList = ({
  customSearchData,
  orgnr,
  namn,
  globalBranschKod,
  branschKod = globalBranschKod,
  bransch,
  displayCustomSearchList,
  requestSubsidiariesSearch,
  requestSectorSearch,
  requestCompany,
  selectCompany,
  selectedCompanyId,
  isMobile,
  fromSector,
  history,
}) => {
  const singleListProps = {
    requestCompany,
    selectedCompanyId,
    selectCompany,
    isMobile,
  };
  const {
    type,
    isVisible,
    sort,
    data,
    page,
    itemCount,
    to,
    limit,
    isLoading,
    sectorTitle,
    infinite,
  } = customSearchData;

  const headerTitle = (type) => {
    if (type === "sector") return `Bransch: ${sectorTitle || bransch}`;
    if (type === "subsidiaries") return `Dotterbolag: ${namn}`;
  };
  const dropdown = () => (
    <Filter
      value={sort}
      onChange={(sort) => {
        if (type === "sector") {
          requestSectorSearch({
            branschKod, sort, sectorTitle, to, from: 0,
          });
        }
        if (type === "subsidiaries") {
          requestSubsidiariesSearch({
            orgnr, sort, to, from: 0,
          });
        }
      }}
      options={filterOptions}
      hasSearchData={!!data.antalTräff}
      truncate
    />
  );
  const handlePageChange = () => {
    const from = page * limit;
    const to = from + limit;

    if (type === "sector") {
      requestSectorSearch({
        branschKod,
        sort,
        sectorTitle,
        from,
        to,
        infinite: true,
        purge: false,
      });
    }
    if (type === "subsidiaries") {
      requestSubsidiariesSearch({
        orgnr,
        sort,
        from,
        to,
        infinite: true,
        purge: false,
      });
    }
  };

  const onBack = () => {
    displayCustomSearchList(false);

    if (fromSector) {
      history.push("/sectors");
    }
  };

  if (!isVisible) return null;

  return (
    <div className={s.container}>
      <InfiniteScroll
        pageStart={0}
        initialLoad={false}
        useWindow={false}
        loadMore={handlePageChange}
        hasMore={!!data.antalTräff && !isLoading && itemCount > to}
      >
        <SingleList
          list={data}
          isLoading={isLoading}
          name={headerTitle(type)}
          onBack={onBack}
          onSelect={isMobile ? () => displayCustomSearchList(false) : null}
          ListActionChildren={dropdown}
          noDataMessage="Sökningen gav inga träffar!"
          disableLoading={infinite}
          additionalTitleClassname={s.headerTitle}
          additionalListActionsClassnames={s.listActions}
          {...singleListProps}
        />
      </InfiniteScroll>
    </div>
  );
};

export default BranchList;
