import React from "react";
import { Button } from "../../../shared";
import s from "./PriceInfo.module.sass";

const PriceInfo = ({ showModal }) => (
  <div className={s.container}>
    <Button onClick={showModal} type="button" className={s.button}>
    Vad räcker potten till?
    </Button>
  </div>
);

export default PriceInfo;
