import React, { PureComponent } from "react";
import {
  AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, Legend,
} from "recharts";

export default class StackedAreaChart extends PureComponent {
  state = {
    opacity: {
      average: 0.4,
      branch: 0.4,
    },
    margin: {
      top: 20,
      right: 20,
      left: 20,
      bottom: 20,
    },
  };

  handleMouseEnter = (o) => {
    const { dataKey } = o;
    const { opacity } = this.state;

    this.setState({
      opacity: { ...opacity, [dataKey]: 0.7 },
    });
  }

  handleMouseLeave = (o) => {
    const { dataKey } = o;
    const { opacity } = this.state;

    this.setState({
      opacity: { ...opacity, [dataKey]: 0.4 },
    });
  }

  render() {
    const {
      data, unit, name, s,
    } = this.props;
    const { opacity, margin } = this.state;

    return (
      <ResponsiveContainer className={s["chart__container"]}>
        <AreaChart data={data} margin={margin}>
          <CartesianGrid strokeDasharray="3 3" vertical={false} />
          <XAxis dataKey="date" padding={{ left: 40, right: 40 }} tickMargin={20} height={60} axisLine={false} tickLine={false} />
          <YAxis orientation="right" unit={unit} padding={{ top: 40 }} axisLine={false} tickLine={false} />
          <Tooltip />
          <Legend iconSize={30} iconType="circle" onMouseEnter={this.handleMouseEnter} onMouseLeave={this.handleMouseLeave} />
          {data[0] && data[0].average && <Area type="linear" dataKey="average" name={name} stackId="2" stroke="#23a843" fill="#23a843" fillOpacity={opacity.average} />}
          <Area type="linear" dataKey="branch" name="Branschsnitt" stackId="1" stroke="#095587" fill="#095587" fillOpacity={opacity.branch} />
        </AreaChart>
      </ResponsiveContainer>
    );
  }
}
