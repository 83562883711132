import React, { useState } from "react";
import {
  CardNumberElement, CardExpiryElement, CardCvcElement,
} from "@stripe/react-stripe-js";
import {
  MdMailOutline, MdCreditCard, MdToday, MdLockOutline, MdMarkunreadMailbox,
} from "react-icons/md";
import cn from "classnames";
import poweredByStripeIcon from "../../assets/icons/powered_by_stripe@2x.png";
import s from "./StripeCardComponent.module.sass";

const cardElementOptions = {
  style: {
    base: {
      color: "#32325d",
      lineHeight: "2rem",
      fontFamily: "Avenir,-apple-system,BlinkMacSystemFont,'Segoe UI','PingFang SC','Hiragino Sans GB','Microsoft YaHei','Helvetica Neue',Helvetica,Arial,sans-serif,'Apple Color Emoji','Segoe UI Emoji','Segoe UI Symbol'",
      fontSize: "16px",
      "::placeholder": {
        color: "#aab7c4",
        fontWeight: "bold",
      },
    },
    invalid: {
      color: "#e63946",
      iconColor: "#fa755a",
    },
  },
};

const PoweredByStripeBanner = () => (
  <a href="https://stripe.com" target="_blank" rel="noopener noreferrer" className={s.stripeBanner}>
    <img src={poweredByStripeIcon} className={s.stripeBannerIcon} alt="powered by Stripe" />
  </a>
);

const StripeCardComponent = ({
  email = "",
  setEmail,
  zipcode = "",
  setZipcode,
}) => {
  const [error, setError] = useState(null);

  const handleChange = (event) => {
    if (event.error) {
      setError(event.error.message);
    } else {
      setError(null);
    }
  };

  return (
    <>
      <div className={cn(s.elementContainer, s.email)}>
        <input
          id="email"
          type="email"
          placeholder="kalle.kula@foretaget.se"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          className={s.inputBase}
        />
        <MdMailOutline className={s.icon} />
      </div>
      <div className={s.wrapper}>
        <div className={cn(s.elementContainer, s.card)}>
          <CardNumberElement options={cardElementOptions} onChange={handleChange} />
          <MdCreditCard className={s.icon} />
        </div>
        <div className={cn(s.elementContainer, s.expiry)}>
          <CardExpiryElement options={cardElementOptions} onChange={handleChange} />
          <MdToday className={s.icon} />
        </div>
        <div className={cn(s.elementContainer, s.cvc)}>
          <CardCvcElement options={cardElementOptions} onChange={handleChange} />
          <MdLockOutline className={s.icon} />
        </div>
        <div className={cn(s.elementContainer, s.zip)}>
          <input
            id="zipcode"
            type="text"
            placeholder="Postnummer"
            maxLength={5}
            value={zipcode}
            onChange={(e) => setZipcode(e.target.value)}
            className={s.inputBase}
          />
          <MdMarkunreadMailbox className={s.icon} />
        </div>
      </div>
      <PoweredByStripeBanner />
      {error && <div className={s.error}>{error}</div>}
    </>
  );
};

export default StripeCardComponent;
