import React from "react";
import { CSSTransition } from "react-transition-group";
import s from "./Home.module.sass";
import { Search, Results } from "..";
import { OutsideAlerter } from "../shared";

const Home = ({
  searchData: {
    query, data, isLoading, searchListIsVisible,
  },
  handleInputChange,
  handleFocusChange,
  displaySearchList,
}) => (
  <>
    <div className={s.home}>
      <div className={s["home__search-holder"]}>
        <h1 className={s["header"]}>SökaFöretag</h1>
        <div className={s.search}>
          <Search
            name="search"
            placeholder="Sök företag, org.nr etc."
            value={query}
            onChange={handleInputChange}
            onFocus={handleFocusChange}
            className={s["search-bar__input"]}
            wrapperClassName={s["search-bar"]}
            bgClassName={s["search-bar__bg"]}
            iconClassName={s["search-bar__icon"]}
            autoComplete="off"
            isLoading={isLoading}
            loaderColor="#07446c"
          />
          <CSSTransition
            in={searchListIsVisible}
            classNames={{
              enter: s["slide-enter"],
              enterActive: s["slide-enter-active"],
              exit: s["slide-exit"],
              exitActive: s["slide-exit-active"],
            }}
            timeout={{ enter: 200, exit: 200 }}
            unmountOnExit
          >
            <OutsideAlerter className={s.resultsWrapper} onClick={() => displaySearchList(false)} active={searchListIsVisible}>
              <Results
                results={data}
                className={s.results}
                show={searchListIsVisible || !data.length}
                compact
              />
            </OutsideAlerter>
          </CSSTransition>
        </div>
        <p className={s["search-info"]}>Sök bland Sveriges alla 1,2 miljoner företag</p>
      </div>
      <div className={s["fk-div"]}>
        <h2 className={s["fk-div__header"]}>Köp och importera listor</h2>
        <p className={s["fk-div__info"]}>
          Importera dina prospekt från vår tjänst Företagskontakt
          <br />
          och visa företagskortet direkt i Sökaföretag
        </p>
        <a
          href="http://www.marknadsinformation.se/kontakt"
          target="_blank"
          rel="noopener noreferrer"
          className={s["fk-div__link"]}
        >
          Kontakta oss
        </a>
      </div>
    </div>
  </>
);

export default Home;
