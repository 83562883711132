import _isNil from "lodash/isNil";
import axios from "./api/axios";
import { alertMessages, modalNames } from "../helpers/constants";
import { actionCreators as userActionCreators } from "./User";
import { actionCreators as modalActionCreators } from "./Modal";
import message from "../components/shared/Message/message";

const FETCH_COMPANY = "FETCH_COMPANY";
const FETCH_COMPANY_PENDING = "FETCH_COMPANY_PENDING";
const FETCH_COMPANY_REJECTED = "FETCH_COMPANY_REJECTED";
const FETCH_COMPANY_FULFILLED = "FETCH_COMPANY_FULFILLED";

const FETCH_COMPANYINFO = "FETCH_COMPANYINFO";
const FETCH_COMPANYINFO_PENDING = "FETCH_COMPANYINFO_PENDING";
const FETCH_COMPANYINFO_REJECTED = "FETCH_COMPANYINFO_REJECTED";
const FETCH_COMPANYINFO_FULFILLED = "FETCH_COMPANYINFO_FULFILLED";

const UPDATE_COMPANYINFO = "  UPDATE_COMPANYINFO";
const UPDATE_COMPANYINFO_PENDING = "  UPDATE_COMPANY_PINFOENDING";
const UPDATE_COMPANYINFO_FULFILLED = "  UPDATE_COMPANYINFO_FULFILLED";
const UPDATE_COMPANYINFO_REJECTED = " UPDATE_COMPANYINFO_REJECTED";

const UNLOCK_FIELD = "UNLOCK_FIELD";
const UNLOCK_FIELD_PENDING = "UNLOCK_FIELD_PENDING";
const UNLOCK_FIELD_REJECTED = "UNLOCK_FIELD_REJECTED";
const UNLOCK_FIELD_FULFILLED = "UNLOCK_FIELD_FULFILLED";

const RESET_COMPANY_DATA = "RESET_COMPANY_DATA";
const SET_LOADING = "SET_LOADING";

let source = axios.CancelToken.source();

const initialState = {
  data: {},
  companyInfo: {},
  unlockedFields: { data: {} },
  activeButtonId: null,
  isLoading: false,
};

export const actionCreators = {
  requestCompany: (orgnr, cfarnr) => async (dispatch, getState) => {
    if (source) source.cancel();
    source = axios.CancelToken.source();
    cfarnr = (!_isNil(cfarnr) && cfarnr !== "undefined") ? cfarnr : "";

    const url = `/company/orgno/${orgnr}/${cfarnr}`;
    const options = { cancelToken: source.token };

    dispatch({
      type: FETCH_COMPANY,
      payload: {
        promise: axios.get(url, options),
      },
    });
  },

  unlockField: (miseId, fieldName, title, buttonId) => async (dispatch, getState) => {
    const url = `/company/${miseId}/unlock`;
    const body = { fieldName, title };

    const { response } = await dispatch({
      type: UNLOCK_FIELD,
      payload: {
        promise: axios.post(url, body),
        data: { miseId, buttonId },
      },
    });

    if (response && response.status !== 200) {
      if (response.status === 402) {
        dispatch(modalActionCreators.openModal(modalNames.REFILL_POT));
      } else {
        message.error(response.data || alertMessages.NO_MESSAGE_FALLBACK);
      }
    } else {
      message.success(alertMessages.FIELD_UNLOCKED, 3);

      dispatch(modalActionCreators.closeModal());
      dispatch(userActionCreators.getUser());
    }
  },

  requestCompanyInfo: () => async (dispatch) => {
    const url = "/companyInfo";

    dispatch({
      type: FETCH_COMPANYINFO,
      payload: {
        promise: axios.get(url),
      },
    });
  },


  updateCompanyInfo: (companyInfo) => async (dispatch, getState) => {
    const url = "/companyInfo";
    const body = { ...companyInfo };

    const { response } = await dispatch({
      type: UPDATE_COMPANYINFO,
      payload: {
        promise: axios.put(url, body),
      },
    });

    if (response && response.status !== 200) {
      message.error(response.data || alertMessages.NO_MESSAGE_FALLBACK, 3);
    } else {
      message.success(alertMessages.COMPANY_INFO_UPDATED, 3);
      dispatch(modalActionCreators.closeModal());
    }
  },

  resetCompanyData: () => ({ type: RESET_COMPANY_DATA }),
  setLoading: (loading) => ({ type: SET_LOADING, payload: loading }),
};

export const reducer = (state = initialState, action) => {
  if (action.type === FETCH_COMPANY_PENDING) {
    return {
      ...state,
      isLoading: true,
    };
  }

  if (action.type === FETCH_COMPANY_REJECTED) {
    if (axios.isCancel(action.payload)) {
      return {
        ...state,
        errorMessage: "Request has been canceled",
        errorCode: 499,
      };
    }

    return {
      ...state,
      isLoading: false,
      errorMessage: action.payload.message,
      errorCode: action.payload.response.status,
    };
  }

  if (action.type === FETCH_COMPANY_FULFILLED) {
    return {
      ...state,
      data: action.payload.data,
      isLoading: false,
    };
  }
  if (action.type === FETCH_COMPANYINFO_PENDING) {
    return {
      ...state,
      isLoading: true,
    };
  }

  if (action.type === FETCH_COMPANYINFO_REJECTED) {
    if (axios.isCancel(action.payload)) {
      return {
        ...state,
        errorMessage: "Request has been canceled",
        errorCode: 499,
      };
    }

    return {
      ...state,
      isLoading: false,
      errorMessage: action.payload.message,
      errorCode: action.payload.response.status,
    };
  }

  if (action.type === FETCH_COMPANYINFO_FULFILLED) {
    return {
      ...state,
      companyInfo: action.payload.data,
      isLoading: false,
    };
  }

  if (action.type === UNLOCK_FIELD_PENDING) {
    return {
      ...state,
      activeButtonId: action.payload.buttonId,
      unlockedFields: {
        ...state.unlockedFields,
        activeCompanyId: action.payload.miseId,
        isLoading: true,
      },
    };
  }

  if (action.type === UPDATE_COMPANYINFO_PENDING) {
    return {
      ...state,
      isLoading: true,
    };
  }

  if (action.type === UPDATE_COMPANYINFO_REJECTED) {
    return {
      ...state,
      errorMessage: action.payload.message,
      errorCode: action.payload.response.status,
      isLoading: false,
    };
  }
  if (action.type === UPDATE_COMPANYINFO_FULFILLED) {
    return {
      ...state,
      companyInfo: {
        ...state.companyInfo,
        ...action.payload.data,
      },
      isLoading: false,
    };
  }


  if (action.type === UNLOCK_FIELD_REJECTED) {
    return {
      ...state,
      activeButtonId: null,
      unlockedFields: {
        ...state.unlockedFields,
        isLoading: false,
        activeCompanyId: null,
        errorMessage: action.payload.message,
        errorCode: action.payload.response.status,
      },
    };
  }

  if (action.type === UNLOCK_FIELD_FULFILLED) {
    const miseId = state.unlockedFields.activeCompanyId;
    return {
      ...state,
      activeButtonId: null,
      unlockedFields: {
        ...state.unlockedFields,
        isLoading: false,
        data: {
          ...state.unlockedFields.data,
          [miseId]: [...(state.unlockedFields.data[miseId] || []), action.payload.data],
        },
      },
    };
  }

  if (action.type === RESET_COMPANY_DATA) {
    return {
      ...state,
      data: {},
    };
  }

  if (action.type === SET_LOADING) {
    return {
      ...state,
      isLoading: action.payload,
    };
  }

  return state;
};
