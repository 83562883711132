import React from "react";
import { MdAdd } from "react-icons/md";
import { Button } from "../../../shared";
import { modalNames } from "../../../../helpers/constants";
import s from "./NoListData.module.sass";

const NoListData = ({ openModal }) => (
  <div className={s["noListData-wrapper"]}>
    <h2 className={s.header}>Här var det tomt!</h2>
    <p className={s.text}>Tryck på knappar nedan för att skapa din första lista.</p>
    {" "}
    <Button success shadow big Icon={MdAdd} onClick={() => openModal(modalNames.CREATE_LIST)}>
      Skapa ny lista
    </Button>
    <div className={s["decision-border"]}>
      <h2 className={s["decision-word"]}>ELLER</h2>
    </div>
    <a
      href="https://urval.xn--fretagskontakt-vpb.se/"
      target="_blank"
      rel="noopener noreferrer"
      className={s.link}
    >
        Välj din egen målgrupp
    </a>
  </div>
);

export default NoListData;
