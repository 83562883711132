import React from "react";
import cn from "classnames";
import { Link } from "react-router-dom";
import s from "./Button.module.sass";

const Button = ({
  big,
  primary,
  success,
  danger,
  white,
  shadow,
  additionalClass,
  children,
  Icon,
  href,
  onClick,
  disabled,
  asLink,
  loading,
  ...rest
}) => {
  const className = cn(
    s["button--normalize"],
    s["button__default"],
    { [s["button--big"]]: big },
    { [s["button__primary"]]: primary },
    { [s["button__success"]]: success },
    { [s["button__danger"]]: danger },
    { [s["button__white"]]: white },
    { [s["button__shadow"]]: shadow },
    { [s["button__asLink"]]: asLink },
    { [s["button__disabled"]]: disabled || loading },
    additionalClass,
  );

  return (
    <>
      {href ? (
        <Link className={className} to={href} onClick={onClick} disabled={disabled || loading}>
          {Icon && <Icon className={children ? s["button__icon"] : s["button__link"]} />}
          {children}
        </Link>
      ) : (
        <button type="button" className={className} onClick={onClick} disabled={disabled || loading} {...rest}>
          {Icon && <Icon className={children ? s["button__icon"] : s["button__link"]} />}
          {children}
        </button>
      )}
    </>
  );
};

export default Button;
