import React, { Component, createRef } from "react";
import * as d3 from "d3";
import s from "./BarChart.module.sass";

class BarChart extends Component {
  ref = createRef();

  componentDidMount() {
    this.drawChart();
  }

  drawChart = () => {
    const {
      data = [[]],
      margin = {
        top: 0, left: 0, right: 0, bottom: 0,
      },
    } = this.props;

    const {
      dataset, fill, opacity, legend,
    } = data[0];

    const svg = d3
      .select(this.ref.current)
      .attr("class", s.wrapper)
      .append("svg");

    const { width, height } = this.ref.current.getBoundingClientRect();
    const innerWidth = width - margin.left - margin.right;
    const innerHeight = height - margin.top - margin.bottom - 40;

    const y = d3
      .scaleBand()
      .rangeRound([0, innerHeight])
      .paddingInner(0.1);

    const x = d3
      .scaleLinear()
      .range([0, innerWidth]);

    svg
      .attr("width", width)
      .attr("height", height);

    const g = svg.append("g").attr("transform", `translate(${margin.left}, ${margin.top})`);

    x.domain(d3.extent(dataset, (d) => d.value)).nice();
    y.domain(dataset.map((d) => d.y));

    const xAxis = d3.axisBottom().scale(x);

    g
      .selectAll("rect")
      .data(dataset)
      .enter()
      .append("rect")
      .attr("class", (d) => `bar bar--${d.value < 0 ? "negative" : "positive"}`)
      .attr("x", (d) => x(Math.min(0, d.value)))
      .attr("y", (d) => y(d.y))
      .attr("width", (d) => Math.abs(x(d.value) - x(0)))
      .attr("height", 20)
      .attr("opacity", (d, i) => opacity[i])
      .attr("fill", (d, i) => fill[i]);

    g
      .append("g")
      .attr("transform", `translate(0, ${innerHeight})`)
      .call(xAxis);

    g
      .append("g")
      .attr("class", "y axis")
      .attr("transform", `translate(${x(0)}, 0)`)
      .call(d3.axisLeft(y))
      .selectAll(".tick")
      .attr("visibility", "hidden");

    legend.map((legend, i) => {
      g
        .append("text")
        .attr("fill", fill[i])
        .attr("x", (++i * innerWidth) / 4)
        .attr("y", innerHeight + margin.bottom + margin.top)
        .text(legend);
    });
  };

  render() {
    return <div ref={this.ref} />;
  }
}

export default BarChart;
