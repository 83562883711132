import { connect } from "react-redux";
import { actionCreators as companyActionCreators } from "../../../store/Company";
import { actionCreators as modalActionCreators } from "../../../store/Modal";
import ConfirmPurchaseModal from "./ConfirmPurchaseModal";

const mapStateToProps = (state) => ({
  isLoading: state.companyData.unlockedFields.isLoading,
  ...state.modal.data,
});

const mapActionCreators = {
  ...modalActionCreators,
  ...companyActionCreators,
};

const withRedux = connect(
  mapStateToProps,
  mapActionCreators,
);

export default withRedux(ConfirmPurchaseModal);
