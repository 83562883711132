import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { actionCreators as searchActionCreators } from "../../store/Search";
import { actionCreators as companyActionCreators } from "../../store/Company";
import SectorPage from "./SectorPage";

const mapActionCreators = {
  ...searchActionCreators,
  ...companyActionCreators,
};

const withRedux = connect(
  null,
  mapActionCreators,
);

const withReduxAndRouter = (C) => withRouter(withRedux(C));

export default withReduxAndRouter(SectorPage);
