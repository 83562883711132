import React, { Component } from "react";
import MediaQuery from "react-responsive";
import cn from "classnames";
import {
  Header, CompanyList, Pagination, SearchResults,
} from "./modules";
import s from "./SearchList.module.sass";

const SearchList = ({
  data,
  isLoading,
  from,
  to,
  sort,
  searchData,
  filterOptions,
  onFilterChange,
  handleInputChange,
  onPageChange,
  itemCount,
  limit,
  hasSearchData,
  displaySearchList,
}) => (
  <div className={cn(s["searchList__container"])}>
    <MediaQuery query="(max-width: 768px)">
      {(matches) => {
        if (matches) {
          return (
            <>
              <SearchResults
                searchData={searchData}
                displaySearchList={displaySearchList}
                handleInputChange={handleInputChange}
                handleSortChange={onFilterChange}
                showMore={false}
                searchPage
                {...searchData}
              />
              <Pagination
                itemCount={itemCount}
                limit={limit}
                onPageChange={onPageChange}
                pageRange={1}
                hidden={!data.length}
              />
            </>
          );
        }
        return (
          <>
            <Header
              sort={sort}
              options={filterOptions}
              onChange={onFilterChange}
              itemCount={itemCount}
              hasSearchData={hasSearchData}
            />
            <CompanyList
              data={data}
              isLoading={isLoading}
              from={from}
              to={to}
              itemCount={itemCount}
              displaySearchList={displaySearchList}
              searchListIsVisible={searchData.searchListIsVisible}
            />
            <Pagination
              itemCount={itemCount}
              limit={limit}
              onPageChange={onPageChange}
              hidden={!data.length}
            />
          </>
        );
      }}
    </MediaQuery>
  </div>
);


export default SearchList;
