export default [
  { value: "namn", label: "Namn", code: "NA" },
  { value: "omsattning", label: "Omsättning", code: "OM" },
  { value: "storstVinst", label: "Resultat", code: "RE" },
  { value: "anstallda", label: "Antal anställda", code: "AA" },
  { value: "omsattningPerAnstalld", label: "Omsättning per anställd", code: "OMA" },
  { value: "soliditet", label: "Soliditet", code: "SO" },
  { value: "likviditet", label: "Likviditet", code: "LI" },
  { value: "tillvaxtfaktor", label: "Tillväxtfaktor", code: "TI" },
];
