import { connect } from "react-redux";
import { compose } from "recompose";
import { reduxForm } from "redux-form";
import { actionCreators as companyActionCreators } from "../../../store/Company";
import { actionCreators as modalActionCreators } from "../../../store/Modal";
import AddDescriptionModal from "./AddDescriptionModal";
import { validate, warn } from "./modules/validateForm";

const mapStateToProps = (state) => ({
  isLoading: state.companyData.isLoading,
  initialValues: {
    companyInfo: {
      shortDescription: state.companyData.companyInfo.shortDescription,
      longDescription: state.companyData.companyInfo.longDescription,
    },
  },
});

const mapActionCreators = {
  ...companyActionCreators,
  ...modalActionCreators,
};

const withRedux = connect(
  mapStateToProps,
  mapActionCreators,
);

const withReduxForm = reduxForm({
  form: "updateCompanyInfo",
  destroyOnUnmount: false,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
  validate,
  warn,
});

export default compose(
  withRedux,
  withReduxForm,
)(AddDescriptionModal);
