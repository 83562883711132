import React from "react";
import Tree from "antd/lib/tree";
import "antd/lib/tree/style/index.css";
import s from "./SelectionTree.module.sass";

const SelectionTree = ({
  onExpand, expandedKeys, autoExpandParent, onSelect, selectedKeys, renderTreeNodes, treeData,
}) => (
  <div className={s["selection-wrapper"]}>
    <h1>Sök branscher</h1>
    <div className={s["selection-tree"]}>
      <Tree
        onExpand={onExpand}
        expandedKeys={expandedKeys}
        autoExpandParent={autoExpandParent}
        onSelect={onSelect}
        selectedKeys={selectedKeys}
      >
        {renderTreeNodes(treeData)}
      </Tree>
    </div>
  </div>
);

export default SelectionTree;
