import React from "react";
import s from "./Branch.module.sass";
import { HorizontalBarChart, LineChart } from "../../..";
import transformDateUntil from "../../../../helpers/transformDateUntil";

const Branch = ({
  antalAnställda,
  antalAnställda2,
  antalAnställda3,
  antalAnställda4,
  antalAnställda5,
  branschAntAnst,
  branschAntAnst2,
  branschAntAnst3,
  branschAntAnst4,
  branschAntAnst5,

  omsättning,
  omsättning2,
  omsättning3,
  omsättning4,
  omsättning5,
  branschomsättning,
  branschomsättning2,
  branschomsättning3,
  branschomsättning4,
  branschomsättning5,

  resultat,
  resultat2,
  resultat3,
  resultat4,
  resultat5,
  branschResultat,
  branschResultat2,
  branschResultat3,
  branschResultat4,
  branschResultat5,

  kassalikviditet,
  kassalikviditet2,
  kassalikviditet3,
  kassalikviditet4,
  kassalikviditet5,
  branschKassalikviditet,
  branschKassalikviditet2,
  branschKassalikviditet3,
  branschKassalikviditet4,
  branschKassalikviditet5,

  omsättningPerAnställd,
  omsättningPerAnställd2,
  omsättningPerAnställd3,
  omsättningPerAnställd4,
  omsättningPerAnställd5,
  branschomsättningPerAnst,
  branschomsättningPerAnst2,
  branschomsättningPerAnst3,
  branschomsättningPerAnst4,
  branschomsättningPerAnst5,

  soliditet,
  soliditet2,
  soliditet3,
  soliditet4,
  soliditet5,
  branschSoliditet,
  branschSoliditet2,
  branschSoliditet3,
  branschSoliditet4,
  branschSoliditet5,

  tillväxtfaktor,
  branschTillväxtfaktor,

  synaPeriodTill,
  synaPeriodTill2,
  synaPeriodTill3,
  synaPeriodTill4,
  synaPeriodTill5,

  namn,
  selectedTab = 0,
  withGrowth,
}) => {
  const [date, date2, date3, date4, date5] = transformDateUntil({
    synaPeriodTill, synaPeriodTill2, synaPeriodTill3, synaPeriodTill4, synaPeriodTill5,
  });

  /* Antal Anställda */
  const AntalData = [
    { average: antalAnställda5, branch: Math.round(branschAntAnst5), date: date5 },
    { average: antalAnställda4, branch: Math.round(branschAntAnst4), date: date4 },
    { average: antalAnställda3, branch: Math.round(branschAntAnst3), date: date3 },
    { average: antalAnställda2, branch: Math.round(branschAntAnst2), date: date2 },
    { average: antalAnställda, branch: Math.round(branschAntAnst), date },
  ];

  /* Omsattning */
  const OmsattningData = [
    { average: omsättning5, branch: branschomsättning5, date: date5 },
    { average: omsättning4, branch: branschomsättning4, date: date4 },
    { average: omsättning3, branch: branschomsättning3, date: date3 },
    { average: omsättning2, branch: branschomsättning2, date: date2 },
    { average: omsättning, branch: branschomsättning, date },
  ];

  /* Resultat */
  const ResultatData = [
    { average: resultat5, branch: branschResultat5, date: date5 },
    { average: resultat4, branch: branschResultat4, date: date4 },
    { average: resultat3, branch: branschResultat3, date: date3 },
    { average: resultat2, branch: branschResultat2, date: date2 },
    { average: resultat, branch: branschResultat, date },
  ];

  /* Kassa Likviditet */
  const KassaLikviditetData = [
    { average: kassalikviditet5, branch: branschKassalikviditet5, date: date5 },
    { average: kassalikviditet4, branch: branschKassalikviditet4, date: date4 },
    { average: kassalikviditet3, branch: branschKassalikviditet3, date: date3 },
    { average: kassalikviditet2, branch: branschKassalikviditet2, date: date2 },
    { average: kassalikviditet, branch: branschKassalikviditet, date },
  ];

  /* Omsattning per Anställd */
  const OmsattningAntalData = [
    { average: omsättningPerAnställd5, branch: branschomsättningPerAnst5, date: date5 },
    { average: omsättningPerAnställd4, branch: branschomsättningPerAnst4, date: date4 },
    { average: omsättningPerAnställd3, branch: branschomsättningPerAnst3, date: date3 },
    { average: omsättningPerAnställd2, branch: branschomsättningPerAnst2, date: date2 },
    { average: omsättningPerAnställd, branch: branschomsättningPerAnst, date },
  ];

  /* Soliditet */
  const SoliditetData = [
    { average: soliditet5, branch: branschSoliditet5, date: date5 },
    { average: soliditet4, branch: branschSoliditet4, date: date4 },
    { average: soliditet3, branch: branschSoliditet3, date: date3 },
    { average: soliditet2, branch: branschSoliditet2, date: date2 },
    { average: soliditet, branch: branschSoliditet, date },
  ];

  /* Tillvaxt */
  const TillvaxtData = [
    { average: tillväxtfaktor, branch: branschTillväxtfaktor, date },
  ];

  const charts = [
    {
      tabName: "Antal anställda",
      tabContent: (props) => <LineChart data={AntalData} {...props} />,
      tabData: AntalData,
    },
    {
      tabName: "Omsättning",
      tabContent: (props) => <LineChart data={OmsattningData} unit="TKR" {...props} />,
      tabData: OmsattningData,
    },
    {
      tabName: "Resultat",
      tabContent: (props) => <LineChart data={ResultatData} unit="TKR" {...props} />,
      tabData: ResultatData,
    },
    {
      tabName: "Likviditet",
      tabContent: (props) => <LineChart data={KassaLikviditetData} unit="%" {...props} />,
      tabData: KassaLikviditetData,
    },
    {
      tabName: "Omsättning per anställd",
      tabContent: (props) => <LineChart data={OmsattningAntalData} unit="TKR" {...props} />,
      tabData: OmsattningAntalData,
    },
    {
      tabName: "Soliditet",
      tabContent: (props) => <LineChart data={SoliditetData} unit="%" {...props} />,
      tabData: SoliditetData,
    },
    ...withGrowth ? [{
      tabName: "Tillväxt",
      tabContent: (props) => <HorizontalBarChart data={TillvaxtData} {...props} />,
      tabData: TillvaxtData,
    }] : [],
  ];

  return (
    <div className={s["branch__container"]}>
      {charts
        .filter((_, i) => i === selectedTab)
        .map(({ tabName, tabContent: TabContent, tabData }) => {
          const noData = tabData.every(({ average, branch }) => average === null && branch === null);
          return <TabContent key={tabName} s={s} name={namn} noData={noData} />;
        })}
    </div>
  );
};

export default React.memo(Branch);
