import _isNull from "lodash/isNull";

const formatNumber = (num = 0, type) => {
  if (_isNull(num)) num = 0;

  if (type === "space") {
    return num.toLocaleString("en").replace(/,/g, " ");
  }
  if (type === "percent") {
    return num.toLocaleString("de");
  }
  return num;
};

export default formatNumber;
