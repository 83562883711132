import React from "react";
import submitForm from "./submitForm";
import CompanyDescription from "./CompanyDescription";
import s from "../AddDescriptionModal.module.sass";
import { Button } from "../../../shared";

const Form = ({ handleSubmit, closeModal, ...rest }) => {
  const Buttons = () => (
    <div className={s["header__button--wrapper"]}>
      <Button additionalClass={s["header__button"]} onClick={closeModal} shadow white>
        Hoppa över
      </Button>
      <Button type="submit" additionalClass={s["header__button"]} primary shadow>
        Spara
      </Button>
    </div>
  );

  return (
    <form onSubmit={handleSubmit(submitForm)}>
      <CompanyDescription {...rest} />
      <Buttons />
    </form>
  );
};

export default Form;
