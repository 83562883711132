import React from "react";
import cn from "classnames";
import PropTypes from "prop-types";
import s from "./Ribbon.module.sass";

const Ribbon = ({ text, position, styles }) => {
  const classNames = cn(s.ribbon, { [s[`ribbon-${position}`]]: position });

  return (
    <span className={classNames} {...styles}>
      <span>{text}</span>
    </span>
  );
};

Ribbon.defaultProps = {
  position: "top-right",
};

Ribbon.propTypes = {
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  position: PropTypes.oneOf(["top-left", "top-right", "bottom-left", "bottom-right"]).isRequired,
  styles: PropTypes.object,
};

export default Ribbon;
